import React, {useEffect, useState} from "react"

import {
    Col,
    Row,
    Button, Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav
} from "reactstrap"

// import Switch from "react-switch"
import Switch from "../../../components/Common/Switch/Switch";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";

import { useOvermind } from '../../../overmind'


//import { registrationFormScreens } from "./Configuration"

import classnames from "classnames"
import TabDefault from "../../../components/Common/Tabs/TabDefault";
import {clearPonctuation} from "../../../renders/utils";

const PreviousScreenButton = (props) => {
    const {currentScreen, screens} = props
    return (
        <Button color="primary" className={'m-2'}
            type="button"
            submitaction='previous'
            disabled={currentScreen==0}
            onClick={() => props.submit('previous')}
        >
            Anterior
        </Button>
    )
}

const NextScreenButton = (props) => {
    const {currentScreen, screens} = props
    return (
        <Button color={"primary"}
            type="button"
            submitaction='next'
            onClick={() => props.submit('next')}
        >
            Próximo
        </Button>
    )
}

const SubmitButtons = (props) => {

    const {currentScreen, screens} = props
    return (
        <Col md={12} className="flex-row-reverse" style={{display:'flex', alignItems:'center'}}>
            <NextScreenButton
                currentScreen={currentScreen}
                screens={screens}
                submit={props.submit}
            />
            <PreviousScreenButton
                currentScreen={currentScreen}
                screens={screens}
                submit={props.submit}
            />
        </Col>
    )
}

function RegistrationFormSection(props) {

    const {formId, formState, screens} = props

    const {
        state   : {
            [formId]:{currentScreen, model, readOnly, formValidationResult}
        },
        actions : {
            submitForm, setFormState, setCurrentScreen, setFormModel, setTotalScreens, setTotalGroups
        }
    } = useOvermind('forms')

    useEffect(() => {
        setTotalScreens({formId, total:screens.length})
    }, [])

	const customFormatter = (fieldProps, fieldValue) => {
    	if (fieldProps.type=='text') {
    		return clearPonctuation(fieldValue, fieldProps.allowNumbers).toUpperCase()
		}
    	return fieldValue
	}

	const getScreenError = (screenId) => {

		if (!formValidationResult?.errors || !formValidationResult?.errors[props.tabId])
			return

		if (!props.tabId)
			return

		const currentTabErrors = formValidationResult?.errors[props.tabId]
		return (currentTabErrors && currentTabErrors[screenId]) || null
	}

    let screen = screens[currentScreen]

    if ((screen?.available && !screen.available())) {
		for (const findScreen of screens) {
			if (findScreen?.available && !findScreen.available()) {
				continue
			}

			screen = findScreen
			break;
		}
    	// alert(screen.id)
	}

    if (!screen)
    	return null

	const selectedScreen = screens.indexOf(screen) || 0

    return (
        <>
            <TabDefault
                tabs={screens}
                activeTab={screen.id}
				getInvalidTab={getScreenError}
                onChange={(active, index) => {
                    setCurrentScreen({formId, value:index})
                }}
            />
            <Row>
                <Col md={12} >
                    <Card className="mb-0">
                        <CardBody className="navbar-default-content ps-4 bg-fiduc-background" style={{minHeight:'350px'}}>
                            <FormWizard
								screenId={screen.id}
								selectedScreen={selectedScreen}
                                screens={screens}
                                formId={formId}
								customFormatter={customFormatter}
								OnInputValueChange={props.OnInputValueChange}
								tabId={props.tabId}
                            />
                        </CardBody>
                    </Card>
                </Col>

            </Row>
        </>
    )
}

export default RegistrationFormSection;
