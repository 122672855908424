import React, {useEffect, useState} from "react"
import {Col, Row, Spinner} from "reactstrap";

function DefaultPageHeader( {children, title=null, actions=null, ...props} ) {

	return <>
		<Row className="xborder border-warning mt-2 align-items-center justify-content-center">
			<Col md={4} className="mt-3 d-block d-sm-block d-md-block d-lg-block d-xl-block ">
				<div className="text-nowrap">
					{typeof(title)=='string' ? <h4 className="page-header-title font-family-title font-size-em3 font-weight-600">{title}</h4> : <title />}
				</div>
			</Col>
			<Col md={8} className="d-flex justify-content-end mt-2">
				{children}
			</Col>
		</Row>
	</>
}

export default DefaultPageHeader;
