const fallbackCopyTextToClipboard = (text, success, error) => {
	var textArea = document.createElement("textarea");
	textArea.value = text;
	document.body.appendChild(textArea);
	textArea.focus();
	textArea.select();

	try {
		if (document.execCommand("copy")) {
			success()
		}
	} catch (err) {
		error()
	}

	document.body.removeChild(textArea);
}


export const copyRichTextToClipboard = (text, success, error) => {
	function listener(e) {
		e.clipboardData.setData("text/html", text);
		e.clipboardData.setData("text/plain", text);
		e.preventDefault();
	}
	document.addEventListener("copy", listener);
	if (document.execCommand("copy")) {
		success()
	} else {
		error()
	}
	document.removeEventListener("copy", listener);
}

export const copyTextToClipboard = (text, success, error) => {
	if (!navigator.clipboard) {
		fallbackCopyTextToClipboard(text, success, error);
		return;
	}
	navigator.clipboard.writeText(text).then(
		success,
		error
	);
}

export const selectTextRange = (obj, start, stop) => {
	let endNode, startNode = endNode = obj.firstChild

	startNode.nodeValue = startNode.nodeValue.trim();

	const range = document.createRange();
	range.setStart(startNode, start);
	//range.setEnd(endNode, stop + 1);

	const sel = window.getSelection();
	sel.removeAllRanges();
	sel.addRange(range);
}
