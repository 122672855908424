import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../../components/Common/CustomForm/Utils/Validations";
import Button from "../../../../components/Common/Button/Button"
import {yesNoOptions} from "../../../../constants/formLists";
import {UserCan} from "../../../../helpers/gestora/auth";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";


function MailSigView(props) {

    const {
    	formId,
		viewId,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

	const readOnly = !UserCan('mailsig.edit')
    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const mailsig = useOvermind('mailsig')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	//const [data, setData] = useState(null)

    useEffect(() => {
    	setError(false)
    	setSaving(false)
    	setSaved(false)

		if (!viewId) {
			setLoading(true)
			setModelValueByPath({formId, path:'edit', value:{}})
			incFormValidatedCount({formId})
			setTimeout(() => setLoading(false), 1)

		} else {
			setLoading(true)
			// resetForm({formId})
			mailsig.actions.loadById({formId, id:viewId})
				.then(record => {

					//setData(record)

					setModelValueByPath({formId, path:'edit', value:record})
					incFormValidatedCount({formId})

				})
				.catch(e => {
					setError(e.response?.data || e.toString())
				})
				.finally(() => {
					setLoading(false)
				})
		}



    }, [viewId])

	const handleConfirm = async (e) => {

		const values = _.cloneDeep(model)

		//console.log(values)

		//const createDate = `${dateBrToISO(values.changeStatus.date.trim())} ${values.changeStatus.time.trim()}`
		//alert(createDate)

		const data = {
			...values.edit,
			active:1,
            //cnpj : onlyNumbers(values.edit.cnpj), //saving as string
		}

    	setSaving(true)
    	mailsig.actions.save({
				formId,
				id:viewId,
				data,
			})
			// .then(sleep(1000))
			.then(() => {
				setSaved(true)
				onConfirm()
				setTimeout(() => {
					setSaving(false)
				}, 2000)
			})
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				if (errCode=='duplicate_code') {
					alert('Este e-mail já está cadastrado')
					setSaving(false)
				} else {
					setError(`Ocorreu um erro ao salvar: ${e.toString()}`)
					setSaving(false)
					setSaved(false)
				}

			})

	}

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'titleSig',
					caption:'Assinatura de e-mail',
					type:'title',
					row:true,
					col:12
				},
				{
					id:'edit.name',
					caption:'Nome',
					type:'text',
					required:true,
					row:true,
					readOnly,
					col:12
				},
				{
					id:'edit.email',
					caption:'E-mail',
					type:'text',
					required:true,
					row:true,
					readOnly,
					col:12
				},
				{
					id:'edit.phone',
					caption:'Telefone',
					type:'phone',
					required:true,
					readOnly,
					col:12
				},
			],
		},
	], [viewId]);

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(statusScreens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:statusScreens}]

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

	const handleCancel = () => {
    	onCancel()
	}


	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
        <>
			<SweetAlert

				style={{
					overflow:'auto',
					// marginTop:'0px',
					width:'50%',
					maxHeight:'100%',
					// minHeight:'300px',
					justifyContent:'flex-start',

				}}
				openAnim={{ name: 'opacityIn', duration: 500 }}
				//closeAnim={{ name: 'slideOut', duration: 500 }}

				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error}>
						<Row className="pr-4 ps-4">
							<Col md={12} className="pt-2 ">
								<Row >
								<Col md={12} className="w-100 d-block align-items-start">
									<FormWizard
										validationInRealTime={true}
										screenId={formId}
										screens={statusScreens}
										formId={formId}
										// OnInputValueChange={handleInputValueChange}
										tabId={formId}
									/>
								</Col>
								</Row>
								<Row >
									<Col md={12} className="d-flex align-items-center justify-content-center">
										<Button
											autoFocus
											className="btn btn-outline-danger"
											onClick={handleCancel}
											style={{margin:'1em'}}
										>
											Fechar
										</Button>
										<UserCanAll permission="mailsig.edit">
										<Button
											loading={saving}
											disabled={!!formValidationResult?.first}
											colorIcon={true}
											className="btn btn-primary "
											// disabled={currentScreen==0}
											style={{margin:'1em'}}
											onClick={handleConfirm}
											>
											Salvar
										</Button>
										</UserCanAll>

									</Col>
								</Row>
							</Col>
						</Row>
					</LoadingSection>
				)}

			</SweetAlert>

        </>
    )
}

export default MailSigView;
