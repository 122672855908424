import PropTypes from 'prop-types'
import React, { useState } from "react"

import { connect } from "react-redux"
import { Row, Col } from "reactstrap"
import ReactDrawer from 'react-drawer';
import 'react-drawer/lib/react-drawer.css';
import { Link, matchPath, useLocation, useHistory } from "react-router-dom"

// Reactstrap
// import { Dropdown, DropdownToggle, DropdownMenu } from "reactstrap"

// Import menuDropdown
// import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown"
// import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown"
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu"
import RightSidebar from "../CommonForBoth/RightSidebar"
// import megamenuImg from "../../assets/images/megamenu-img.png"

// import images
// import github from "../../assets/images/brands/github.png"
// import bitbucket from "../../assets/images/brands/bitbucket.png"
// import dribbble from "../../assets/images/brands/dribbble.png"
// import dropbox from "../../assets/images/brands/dropbox.png"
// import mail_chimp from "../../assets/images/brands/mail_chimp.png"
// import slack from "../../assets/images/brands/slack.png"

// import logo from "../../assets/images/logo-fiduc-inverted.svg"
// import logoLightSvg from "../../assets/images/logo-fiduc-mini.svg"

import logo from "../../assets/images/logos/logo-light.svg"
import iconLightSvg from "../../assets/images/logos/icon-light.svg"

//i18n
import { withTranslation } from "react-i18next"

import { useOvermind} from "../../overmind";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions"

// import SearchBar from "../../pages/SearchBar/SearchBar";
// import ContainerSwap from "../../pages/SearchBar/ContainerSwap";
import lStorage from "../../helpers/gestora/lStorage";

const Header = (props) => {
  // const [search, setsearch] = useState(false)
  // const [megaMenu, setmegaMenu] = useState(false)
  // const [socialDrp, setsocialDrp] = useState(false)

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent)

  const [position, setPosition] = useState();
  const [open, setOpen] = useState(false);

  const location = useLocation()
  const history = useHistory()
  const app = useOvermind('app')

  const toggleTopDrawer = () => {
    setPosition('right');
    setOpen(!open)
  }

  const onDrawerClose = () => {
    setOpen(false);
  }

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen()
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen()
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        )
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen()
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen()
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen()
      }
    }
  }

  function tToggle() {
    var body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
	const type = document.getElementsByTagName("body")[0].className.match(/sidebar-enable/)

	lStorage.set('sidebarType', type ? 'condensed' : 'default')


  }

  // const isInResultPage = !!matchPath(
	// location.pathname,
	// '/clients/list'
  // );

  // const handleSearchChange = (searchString) => {
	//   console.log({isInResultPage, searchString})
  //
	//   app.actions.setSearchParams({searchString})
  //
	//   if (!isInResultPage) {
	// 	  history.push({
	// 		  pathname:"/clients/list",
	// 		  state : {searchString}
	// 	  })
	//   }
  //
  // }


  // let searchTimeoutId=0
  // const handleSearchTyped= (event) => {
  // 	clearTimeout(searchTimeoutId)
  // 	searchTimeoutId = setTimeout(() => handleSearchChange(event.target.value), 500);
  // }

  return (
    <React.Fragment>
      <header id="page-topbar" className="bg-fiduc-background" >
        <div className="navbar-header">
          <div className="d-flex w-100" >

            <div className="navbar-brand-box d-lg-none d-md-block">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm d-flex justify-content-center align-items-center pt-3">
                  <img src={logo} alt="" height="32" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm d-flex justify-content-center align-items-center pt-3">
                  <img src={iconLightSvg} alt="" height="36" />
                </span>
              </Link>
            </div>

            <button
              type="button"
              onClick={() => {
                tToggle()
              }}
              className="btn btn-sm px-3 font-size-em4 header-item "
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars" />
            </button>
			  <Col >
				  {typeof(props.headerComponent)=='object' ? props.headerComponent : <props.headerComponent /> }
			  </Col>

            {/*<form className="app-search d-block">*/}

            {/*  <div className="position-relative">*/}
            {/*    <input*/}
			{/*		id='app-search-string'*/}
            {/*      type="text"*/}
            {/*      className="form-control"*/}
            {/*      placeholder="Buscar..."*/}
			{/*	  onChange={handleSearchTyped}*/}
            {/*    />*/}
            {/*    <span className="bx bx-search-alt" />*/}
            {/*  </div>*/}
            {/*</form>*/}

          </div>

          <div className="d-flex">


            <div className="dropdown d-none d-sm-inline-block ms-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen()
                }}
                className="btn header-item noti-icon "
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen" />
              </button>
            </div>

            <ProfileMenu />

          </div>
        </div>
      </header>
      <ReactDrawer
        open={open}
        position={position}
        onClose={onDrawerClose}
      >
        {/*<RightSidebar onClose={onDrawerClose} />*/}
      </ReactDrawer>
    </React.Fragment>
  )
}

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func
}

const mapStatetoProps = state => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { layoutType, showRightSidebar, leftMenu, leftSideBarType }
}

export default
	connect(mapStatetoProps, {
	  showRightSidebarAction,
	  toggleLeftmenu,
	  changeSidebarType,
	})(withTranslation()(Header))
