const _ = require('lodash')
import api from "../../helpers/gestora/api"
import * as Auth  from "../../helpers/gestora/auth"

import * as RegisteredLists from "../../constants/remoteLists"

export const loadList = (context, {listId} ) => {

	const { state } = context

    // state.login.authenticating = true
    // return api
    //     .post('auth/login/2', {
    //         document : document?.replace(/[^\d]+/g,'') || '',
    //         password
    //     })
    //     ///.then(sleeper(2000))
    //     .then( res => {
    //         Auth.Login(res?.data)
    //         console.log('Login successfully: ', res?.data)
    //         return res
    //     })
    //     .finally(() => {
    //         state.login.authenticating = false
    //     })

}

const setListLoaded = ( { state }, {listId, options}  ) => {
	state.lists[listId].loaded = true
	state.lists[listId].options = options
}


