import api, {sleep} from "../../helpers/gestora/api";


export const loadById = async ( { state, actions }, {formId, id} ) => {

	return api
		.get(`mailsig/${id}`)
		.then(async res => {
			return {
				...res.data,
				active : res.data.active===1 ? "1" : "0"
			}
		})
}

export const save = async ( { state, actions }, {formId, id, data} ) => {

	const request = id
		? api.put(`mailsig/${id}`, data)
		: api.post(`mailsig/`, data)

	return request
}

export const deleteById = async ( { state, actions }, id ) => {
	return api.delete(`mailsig/${id}`)
}

export const generateConfirmCode = async ( { state, actions }, email ) => {
	return api.get(`mailsig/generateConfirmCode/${email}`)
}

export const confirmCode = async ( { state, actions }, {email, code} ) => {
	return api.get(`mailsig/confirmCode/${email}/${code}`)
}

export const saveByCode = async ( { state, actions }, {email, code, data} ) => {
	return api.post(`mailsig/saveByCode/${email}/${code}`, data)
}

