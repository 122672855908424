import React, {useEffect, useMemo, useState} from "react"

import {
	Col,
	Row,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import { useOvermind } from "../../../../../overmind";

// import FormTitleDefault from "../../../../components/Common/CustomForm/SubTitles/TitleDefault";
import Field from "../../../../../components/Common/CustomForm/Field";
import {dateBrToISO, dateIsoToBr} from "../../../../../renders/utils";
import LoadingSection from "../../../../../components/Common/LoadingSection/LoadingSection";
import DefaultTable from "../../../../../components/Common/Tables/DefaultTable/DefaultTable";
import {deviceTypesMapped, deviceTypesOptions} from "../../../../../constants/formLists";

import {
	parseUserAgent,
} from 'react-device-detect'
import UserCanAll from "../../../../../components/Common/UserCan/UserCanAll";
import Button from "../../../../../components/Common/Button/Button";
import ModalForm from "../../../../../components/Common/Modals/ModalForm";
import {UserCan} from "../../../../../helpers/gestora/auth";


function ClientDevices(props) {

	const formId = 'ClientDevicesForm'
	const client = useOvermind('clients')
	const security = useOvermind('security')
	const forms = useOvermind('forms')

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [submitting, setSubmitting] = useState(false)
	const [dataList, setDataList] = useState(null)
	const [showModal, setShowModal] = useState(false)
	const [refresh, setRefresh] = useState(0)

	const fields = useMemo(() => {

		return [
			{
				id:'title',
				type:'title',
				caption:"Encerrar sessões",
				subtitle : "Informe os tipos de dispositivos que deseja desconectar"
			},
			{
				id:'deviceTypes',
				caption:'Tipos de dispositivos',
				type:'select2multi',
				required:true,
				options:deviceTypesOptions,
				col:12
			},
		]
	}, [])

	const handleRemoveSessions = () => {
		// console.log(blockedDevices)
		setLoading(true)
		forms.actions
			.setFormModel({formId, model:{deviceTypes:['mobile']}})
			.then(() => forms.actions.incFormValidatedCount({formId}))
			.then(() => setShowModal(true))
			.finally(() => setLoading(false))
	}

	const handleSubmit = (values) => {
		if (!confirm(`Deseja realmente encerrar essas sessões?`)) {
			setShowModal(false)
			return
		}

		const deviceTypes = values.deviceTypes
		console.log(deviceTypes)
		setSubmitting(true)

		security.actions
			.deleteSessions({
				clientId:client?.state?.currentRegistrationForm?.form?.client?.id,
				deviceTypes,
			})
			.then(({temporaryPassword, remainingBlockedDevices}) => {
				setShowModal(false)
				setRefresh(refresh+1)
				// setLoading(true)
				// setTimeout(() => setLoading(false))
			})
			.catch(error => {
				alert(`Ocorreu um erro ao salvar os dados`)
			})
			.finally(() => setSubmitting(false))
	}


	useEffect(() => {

		setLoading(true)
		// const url = `renew/list/${client.state.currentRegistrationForm.form.client.id}`

		security.actions
			.listClientDevices({
				clientId : client.state.currentRegistrationForm.form.client.id
			})
			//.then(sleep(2000))
			.then(data => {
				setDataList(data)
			})
			.catch(e => {
				setError('Não foi possível carregar as informações de dispositivos conectados')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [refresh])

    return (<>
		<ModalForm
			formId={formId}
			data={null}
			isVisible={showModal}
			fields={fields}
			saving={submitting}
			confirmBtnCaption="Encerrar sessões"
			modalStyle={{
				minHeight : '18rem',
				maxWidth : '50%',
			}}
			onCancel={() => setShowModal(false)}
			onConfirm={handleSubmit}
		/>

		<LoadingSection loading={loading} error={error} noData={dataList?.length===0 ? "Não há dispositivos conectados." : null}>
			<Col md={8} className={"debug"}>
				<Field
					type="title"
					formId={formId}
					caption="Dispositivos conectados"
					formGroupClassName={"mb-0 pb-0"}
				/>
			</Col>
			<Col md={4} className="debug text-align-right justify-content-end align-items-end d-flex">
				<UserCanAll permission={"clientDevice.edit"}>
					<Button
						className="btn btn-danger m-1 btn-sm"
						onClick={handleRemoveSessions}
					>
						<i className="fas fa-power-off m-1" />
						Encerrar sessões
					</Button>
				</UserCanAll>

			</Col>

			<DefaultTable
				className="pt-0 mt-0"
				columns={[
					{id:'created_at', caption:'Data Login', formatter:(row) => dateIsoToBr(row.createdAt, null, 'DD/MM/YYYY [às] HH:mm:ss')},
					{id:'used_at', caption:'Último Acesso', formatter:(row) => dateIsoToBr(row.usedAt, null, 'DD/MM/YYYY [às] HH:mm:ss')},
					// {id:'userAgent', caption:'Informações', formatter:(row) => row.userAgent},
					{id:'os', caption:'Sistema Operacional', formatter:function A(row) {
						const agent = parseUserAgent(row.userAgent)
							console.log({agent})

						return `${agent?.os?.name} ${agent?.os?.version} `
					}},
					{id:'Navegador/Modelo', caption:'Navegador / Modelo', formatter:function A(row) {
						const agent = parseUserAgent(row.userAgent)
							// console.log({agent})

						return agent?.device?.model
							? `${agent?.device?.model} ${agent?.device?.type} ${agent?.device?.vendor}`
							: `${agent?.browser?.name} ${agent?.browser?.version}`
					}},
					{id:'deviceType', caption:'Tipo Dispositivo', formatter:row => deviceTypesMapped[row.deviceType]?.caption},
				]}
				data={dataList}
			/>

		</LoadingSection>
	</>)

}

export default ClientDevices;
