import api, {sleep} from "../../helpers/gestora/api";
import {fiducUrl} from "../../helpers/gestora/configuration";


export const loadById = async ( { state, actions }, {formId, id} ) => {

	const stateFormModel = state.forms[formId].model
	//await actions.forms.resetForm({formId})
	//await actions.forms.resetForm({formId})

	return api
		.get(`admin/fundtypes/${id}`)
		//.then(sleep(1000))
		.then(async res => {

			if (res?.data?.regulationFile) {
				await actions.forms.setUploadedFiles({formId, fieldName:'edit.regulationFile', fileResult:{
					remoteUrl : `${fiducUrl}/admin/fundtypes/downloadRegulationFile/${id}`
				}})
			}

			if (res?.data?.bladeFile) {
				await actions.forms.setUploadedFiles({formId, fieldName:'edit.bladeFile', fileResult:{
					remoteUrl : `${fiducUrl}/admin/fundtypes/downloadBladeFile/${id}`
				}})
			}
			return res.data
		})
}

export const save = async ( { state, actions }, {formId, ...params} ) => {

	const changedFiles = actions.forms.getChangedFiles({formId})

	params.data.bladeFile = changedFiles['edit.bladeFile']
		? changedFiles['edit.bladeFile'].base64String
		: null

	params.data.regulationFile = changedFiles['edit.regulationFile']
		? changedFiles['edit.regulationFile'].base64String
		: null

	return api
		.post(`admin/fundtypes/${params.id}`, params.data)
		//.then(sleep(1000))
		.then(async res => {
			return res.data
		})
}

