import api, {sleep} from "../../helpers/gestora/api";
import {reloadRemoteList} from "../../constants/remoteLists";


export const loadById = async ( { state, actions }, {formId, id} ) => {

	return api
		.get(`profiles/${id}`)
		.then(async res => {
			return {
				...res.data,
				active : res.data.active===1 ? "1" : "0"
			}
		})
}

export const save = async ( { state, actions }, {formId, id, data} ) => {

	const request = parseInt(id)
		? api.put(`profiles/${id}`, data)
		: api.post(`profiles/`, data)

	return await request
		.then(async res => {
			await reloadRemoteList('UserProfile')
			return res.data
		})
}

export const deleteById = async ( { state, actions }, id ) => {

	return api.delete(`profiles/${id}`)
		.then(async res => {
			await reloadRemoteList('UserProfile')
			return res.data
		})
}

export const loadApis = async ( { state, actions } ) => {

	return api.get(`apis`)
		.then(async res => {
			return res.data
		})
}

