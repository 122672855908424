import React, {useEffect, useState} from "react"

import {useOvermind, useOvermindForm} from "../../../../overmind";

// import FormTitleDefault from "../../components/Common/CustomForm/SubTitles/TitleDefault";
import {dateIsoToBr} from "../../../../renders/utils";
import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";
import ButtonPrimary from "../../../../components/Common/Button/ButtonPrimary";

const actionsList = {
	"create" : "Agendou alteração",
	"delete" : "Cancelou agendamento",
	"replace" : "Substituiu agendamento",
	"processed" : "Alteração Realizada",
}

function ChangeAssociateHistory({investmentId, onClose, ...props}) {

	const regForm = useOvermindForm('regForm')

	return (<>

		<div className="debug change-associate-history py-3 d-flex flex-column"
		style={{maxHeight:'90vh'}}>
		<div className="flex-grow-0">
			<h4 className="text-primary font-weight-800 font-family-title">Histórico de alterações de planejador</h4>
		</div>
		<div
			className="flex-grow-1 flex-fill overflow-auto px-2"
		>
			<DefaultTable
				className="pt-0 mt-0 font-size-rem-2 "
				// containerStyle={{maxHeight:'90vh'}}
				columns={[
					{id:'created_at', caption:'Data', formatter:(row) => dateIsoToBr(row.createdAt, null, 'DD/MM/YYYY [às] HH:mm:ss')},
					{id:'action', caption:'Ação', formatter:(row) => actionsList[row.action] || row.action},
					{id:'user', caption:'Operador', formatter:function A(row) { return row.user?.name || <strong>Sistema</strong>} },
					{id:'associate', caption:'Planejador', formatter:(row) => row.associate?.shortName || row.associate?.name},
				]}
				data={regForm?.state?.model?.client?.associateChangeHistory}
			/>
		</div>
        <div className="flex-grow-0 bg-white py-1 align-items-center justify-content-center" >
			<ButtonPrimary
	            onClick={() => onClose && onClose()}
				// onClick={() => {alert('save')}}
				caption={"Fechar"}
			/>
        </div>
		</div>

	</>)

}

export default ChangeAssociateHistory;
