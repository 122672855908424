import React, {useEffect, useState} from "react"
import MetaTags from 'react-meta-tags';
import {
	Button,
	Col,
	Container, Row, Spinner,
} from "reactstrap"

import { useOvermind } from '../../../overmind'

import TabPills from "../../../components/Common/Tabs/TabPills";

import CustomForm from "../../../components/Common/CustomForm/Form";
import InvestorData from "./Sections/InvestorData";
import SuitabilityData from "./Sections/SuitabilityData";
import KYCData from "./Sections/KycData";

//import KYC from "./KYC"

import CaptureDocuments from "./Sections/CaptureDocuments";
// import Pep from "./Sections/Pep";
// import Fatca from "./Sections/Fatca";
// import ProfessionalInformation from "./Sections/ProfessionalInformation";

import { useLocation, useHistory, useParams } from "react-router-dom"


import {docTypes} from "../../../constants/formLists";
import {fiducUrl} from "../../../helpers/gestora/configuration";
import {formatBytes} from "../../../components/Common/CustomForm/Inputs/Upload";

import ClientInfo from "./Components/ClientInfo";
import SaveModal from "./Modals/SaveModal";

const _ = require('lodash')

import {validateFieldStates, validateFormScreens} from "../../../components/Common/CustomForm/Utils/Validations";
import {registrationFormScreens} from "./Configuration/InvestorDataConfig";
import {captureDocumentsScreens} from "./Configuration/CaptureDocumentsConfig";
import {suitabilityScreens} from "./Configuration/SuitabilityDataConfig";
// import RegistrationFormStatus from "./Status/RegistrationFormStatus";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";
import StatusData from "./Sections/StatusData";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import SecurityData from "./Sections/SecurityData";

//import {incFormValidatedCount} from "../../../overmind/forms/actions";

//fas fa-puzzle-piece

const TabList = [
	{id:'investorData', caption:'Informações', icon:'fas fa-user', content:InvestorData, screens:registrationFormScreens},
//    {id:'investorProfile', caption:'Perfil de investimento', icon:'fas fa-home'},
	{id:'documents', caption:'Documentos', icon:'fas fa-folder-open', content:CaptureDocuments, screens:captureDocumentsScreens},
	{id:'suitability', caption:'Suitability', icon:'fas fa-clipboard-list', content:SuitabilityData, screens:suitabilityScreens},
//    {id:'pep', caption:'PEP', icon:'fas fa-home', component:Pep},
//    {id:'fatca', caption:'FATCA', icon:'fas fa-home', component:Fatca},
//    {id:'professionalInformation', caption:'Profissão', icon:'fas fa-home', component:ProfessionalInformation},
//    {id:'additionalDocs', caption:'Documentos Adicionais', icon:'fas fa-home'},
	//{id:'coOwner', caption:'COTITULAR', icon:'fas fa-home'},
//    {id:'signature', caption:'Assinatura', icon:'fas fa-home'},
	{id:'kyc', caption:'KYC', icon:'fas fa-search', content:KYCData},
	{id:'status', caption:'Status', icon:'fas fa-history', content:StatusData},
	{id:'security', caption:'Segurança', icon:'fas fa-lock', content:SecurityData},
]
export const defaultRegistrationFormModel = {
	client : {
		gender : 'MASCULINO',
		civilCapacity : '1',
		personColor : '6',
		country : '1',
		haveWorked : '0',
		haveRelation : '0',
		nacionality : '1',
		haveFatca : '0',
		welfare:'0',
		status:'1',
	},
	fatca : {
		hasRenouncedNationality : '0'
	},
	professionalInformation : {
		professionalType : '0'
	},
	mailing : {
		options : [
			"receiveQuotaholderExtract",
			"receiveReportIncome",
			"allowDistributorSendEmail"
		],
		mailType : '1',
	},
	bankInformation : {
		accounts : [
			{
				isBradescoAccount : '1',
				debitAuthorization : '0',
				haveCoHolders : '0',
			},
			{
				isBradescoAccount : '1',
				debitAuthorization : '0',
				haveCoHolders : '0',
			},
		]
	},
	hasAdditionalBankAccount : '0',

	funds : [],
	terms : [],

}



const SaveButton = (props) => {

    //const {currentScreen, screens} = props
    return (
        <div className="flex-row-reverse" style={{display:'flex', alignItems:'center'}}>
			<Button color={"primary"} className="m-2 p-3 pt-2 pb-2 mb-3"
				type="button"
	            onClick={() => props.submit('submit')}
				// onClick={() => {alert('save')}}
			>
				Salvar alterações
			</Button>
        </div>
    )
}



function RegistrationForm(props) {
	const formId = 'regForm'

	const client  = useOvermind('clients')
	const params = useParams();
  	const history = useHistory();

	const {
		state   : {
			[formId]:{Form, currentGroup, currentScreen, model, formResetCount, formValidatedCount, fieldState, formValidationResult, readOnly}
		},
		actions : {
			submitForm, incFormValidatedCount, setModelValueByPath,
			setFieldState, setFormValidationResult,
			setFormState, setFormModel, resetForm, setCurrentGroup,
			setCurrentStep, setCurrentScreen, setUploadedFiles
		}
	} = useOvermind('forms')

	const [loading, setLoading] = useState(false)
	const [saving, setSaving] = useState(false)
	const [riskProfileListId, setRiskProfileListId] = useState('ActiveRiskProfile')

	const innerProps = {
		formId
	}

	const submitMethod = (action) => {
		const errors = formValidationResult.errors?.investorData?.investordata

		const requiredFields = [
			'client.document',
			'client.name',
			'client.email',
			'client.birthDate',
			'client.genre',
			'client.phoneNumber',
			'client.welfare',
		]
		//console.log('investorDataErrors', errors)
		if (errors &&
			Object.keys(errors).filter((value,key) => {
				return requiredFields.includes(value)
			}).length>0
		) {
			alert(
`Para salvar a ficha cadastral, é necessário preencher corretamente os seguintes campos:
 
   - CPF/CNPJ
   - Nome do titular
   - E-mail
   - Data de Nascimento
   - Telefone
   `)

		} else {
			submitForm({formId, action})
		}

		// const button = document.getElementById(`_submit-btn-${formId}`)
		// button.setAttribute('submitaction', action)
		// button.click()
	}

	const submitRowStyle = {
		display: readOnly ? 'none' : 'block',
		zIndex:1,
		//display: 'flex',
		float:'right',
		alignItems: 'center',
		position: 'sticky',
		bottom: 0,
		backgroundColor: 'white',
		padding : 0,
		margin: 0,
	}


	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		for (const tab of TabList) {
			if (!tab.screens)
				continue

			validateFieldStates(tab.screens, cloneModel, setFieldStateFunc, setInputValue)
		}
	}, [model])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

	// useEffect(() => {
	// 	console.log('model set', model)
	// }, [model])

	const loadData = async (registrationFormId) => {
		setFormState({formId, key:'readOnly', value: true})
		//setSaving(false)
		setLoading(true)
		 client.actions
			.loadRegistrationForm(params.registrationFormId ? params.registrationFormId.split('#')[0] : params.registrationFormId)
			.then(async registrationForm => {
				//console.log('registrationForm', registrationForm)
				await client.actions.setCurrentRegistrationForm(registrationForm)


				/*

				INLINE BASE64
				* personPhoto

				/fiduc/clients/download/base64/files/1091/1
				* documentPhotoFront
				* documentPhotoBack
				* documentPhotoIncapacity
				* proofOfResidence

				fiduc/documentUploads/download/41742
				* rennouce
				* signature
				* */

				setFormModel({formId, model:registrationForm.form})
				setRiskProfileListId(
					registrationForm.form.client.riskProfileCategoryId
						?
					`RiskProfileCategory${registrationForm.form.client.riskProfileCategoryId}`
					: `ActiveRiskProfile`
				)

				if (1) {

					//PERSON PHOTO
					if (registrationForm.inlineFiles?.personPhoto) {
						setUploadedFiles({formId, fieldName:'client.personPhoto',
							fileResult:{
								canDownload : true,
								preview : `data:image/png;base64,${registrationForm.inlineFiles?.personPhoto}`,
								formattedSize: formatBytes(registrationForm.inlineFiles?.personPhoto?.length),
							}
						})
					}

					//OCR
					if (registrationForm.form.client.ocr.documentType=='RG') {
						setUploadedFiles({formId, fieldName:'client.ocr.rg.documentPhotoFront', fileResult:{
							remoteUrl : `${fiducUrl}/clients/download/base64/files/${registrationForm.form.client.id}/1`
						}})
						setUploadedFiles({formId, fieldName:'client.ocr.rg.documentPhotoBack', fileResult:{
							remoteUrl : `${fiducUrl}/clients/download/base64/files/${registrationForm.form.client.id}/2`
						}})
					} else if (registrationForm.form.client.ocr.documentType=='CNH') {
						setUploadedFiles({formId, fieldName:'client.ocr.cnh.documentPhotoFront', fileResult:{
							remoteUrl : `${fiducUrl}/clients/download/base64/files/${registrationForm.form.client.id}/1`
						}})
						// setUploadedFiles({formId, fieldName:'client.ocr.cnh.documentPhotoFront', fileResult:{
						// 	remoteUrl : `${fiducUrl}/clients/download/base64/files/${registrationForm.form.client.id}/1`
						// }})
					} else if (registrationForm.form.client.ocr.documentType=='RNE') {
						setUploadedFiles({formId, fieldName:'client.ocr.rne.documentPhotoFront', fileResult:{
							remoteUrl : `${fiducUrl}/clients/download/base64/files/${registrationForm.form.client.id}/1`
						}})
						setUploadedFiles({formId, fieldName:'client.ocr.rne.documentPhotoBack', fileResult:{
							remoteUrl : `${fiducUrl}/clients/download/base64/files/${registrationForm.form.client.id}/2`
						}})
					}

					//PROOF OF RESIDENCE
					setUploadedFiles({formId, fieldName:'doccapture.proofOfResidence', fileResult:{
						remoteUrl : `${fiducUrl}/clients/download/base64/files/${registrationForm.form.client.id}/4`
					}})


					//RESPONSIBLE
					const responsibleDocument = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.REPRESENTATIVE_ATTORNEY
					)
					if (responsibleDocument) {
						setUploadedFiles({formId, fieldName:'client.responsible.documentPhoto', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${responsibleDocument.id}`
						}})
					}

					//INCAPACITY
					const incapacityDocument = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.INCAPACITY
					)
					if (incapacityDocument) {
						setUploadedFiles({formId, fieldName:'client.responsible.incapacityPhoto', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${incapacityDocument.id}`
						}})
					}

					//RENOUNCE
					const renounceDocument = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.RENOUNCE
					)
					//console.log('registrationForm.documentUploads', registrationForm.documentUploads)
					if (renounceDocument) {
						setUploadedFiles({formId, fieldName:'fatca.renouncedDocument', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${renounceDocument.id}`
						}})
					}

					//FATCA_FORM_EUA
					const fatcaFormEua = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.FATCA_FORM_EUA
					)
					if (fatcaFormEua) {
						setUploadedFiles({formId, fieldName:'fatca.fatcaFormEua', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${fatcaFormEua.id}`
						}})
					}

					//FATCA_FORM_EUA
					const fatcaFormLossCitizenship = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.CERTIFICATE_LOST_NATIONALITY
					)
					if (fatcaFormLossCitizenship) {
						setUploadedFiles({formId, fieldName:'fatca.fatcaFormLossCitizenship', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${fatcaFormLossCitizenship.id}`
						}})
					}

					//fatcaFormResignedCitizenshipEua
					const fatcaFormResignedCitizenshipEua = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.DECLARATION_RESIGNED_CITIZENSHIP_EUA
					)
					if (fatcaFormResignedCitizenshipEua) {
						setUploadedFiles({formId, fieldName:'fatca.fatcaFormResignedCitizenshipEua', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${fatcaFormResignedCitizenshipEua.id}`
						}})
					}

					//fatcaFormResignedGreenCard
					const fatcaFormResignedGreenCard = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.DECLARATION_RESIGNED_GREENCARD
					)
					if (fatcaFormResignedGreenCard) {
						setUploadedFiles({formId, fieldName:'fatca.fatcaFormResignedGreenCard', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${fatcaFormResignedGreenCard.id}`
						}})
					}

					//SIGNATURE
					const signatureDocument = registrationForm.documentUploads?.find(
						(doc) => doc.docType == docTypes.SIGNATURE
					)

					if (signatureDocument) {
						setUploadedFiles({formId, fieldName:'investorDeclaration.signature', fileResult:{
							remoteUrl : `${fiducUrl}/documentUploads/download/${signatureDocument.id}`
						}})
					}

					//console.log('DOC', `${fiducUrl}/documentUploads/download/${responsibleDocument.id}`)



				}


				// setUploadedFiles({formId, fieldName:'client.personPhoto', fileResult:{
				// 	preview : `${fiducUrl}/fiduc/clients/download/base64/files/${registrationForm.form.client.id}/1`
				// }})

				//console.log(`clients/download/base64/files/${registrationForm.form.client.id}/${docTypes.ADDRESS}`)

				//console.log('registrationForm', registrationForm)
				incFormValidatedCount({formId})
			})
			.catch(e => {
				alert(e.toString())
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect( () => {
		if (client.state.saveCount) {
			//alert(client.state.registrationFormId)
			 //history.push('registrationform')
			if (!params.registrationFormId) {
				//alert('replace url')
				history.replace(`registrationForm/${model.client?.registrationFormId}`)
			} else {
				//alert('load data')
				loadData(params.registrationFormId)
					// .then(res => {
					// 	console.log('FORM LOADED ', res.data)
					// })
					// .catch(e => {
					// 	console.log('Error loading data', e)
					// })
			}
		}
	}, [client.state.saveCount])

	useEffect( () => {
		//alert('changed')
		setCurrentStep({formId, currentGroup:0, currentScreen:0})

		//setTimeout(() => setCurrentStep({formId, currentGroup:0, currentScreen:5}), 2000 )
		//resetForm({formId})


		if (params.registrationFormId) {
			loadData(params.registrationFormId)
		} else {
			//alert(`new`)
			setLoading(true)
			client.actions.resetState()
			resetForm({formId, newValues:defaultRegistrationFormModel})
			setFormState({formId, key:'readOnly', value: false})
			incFormValidatedCount({formId})
				.then(() => {
					setLoading(false)
				})

		}

		//console.log('FORM READY', model.client.name)

		return () => {
			//resetForm({formId, newValues:defaultRegistrationFormModel})
			//client.actions.setCurrentRegistrationForm('')
		}
	}, [formResetCount])

	const getTabError = (tabId) => {
		if (!formValidationResult?.errors || !tabId)
			return

		return formValidationResult?.errors[tabId] || null
	}

	const getTabEnabled = (tabId) => {
		return tabId=='status' || tabId=='kyc' || tabId=='suitability'   ? !!model.client.id : true
	}


	//console.log('REGFORM', {formId, model, currentScreen, currentGroup})
	return (
		<UserCanAll permission={"clients.consult"}>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title={
					params.registrationFormId
						? "Ficha cadastral"
						: "Nova ficha cadastral"}
				/>
			}
		>

			{saving &&
			<SaveModal
				formId={formId}
				onConfirm={async  () =>  {
					setSaving(false)

					//setFormState({formId, key:'readOnly', value:true})
				}}
				onCancel={() => {
					setSaving(false)
				}}
			/>}

			<Container fluid style={{minHeight:'100vh'}}>


				{/*<h4 className={"mb-4"}>Ficha Cadastral {params.registrationFormId}</h4>*/}
				{loading ? (
					<Row>
						<Col md={12} className="text-center">
							<Spinner
								style={{ width: "1em", height: "1em"  }}
								color="primary"
							/>
						</Col>
					</Row>
				) : (
					<>
					<Row>
						<Col md={12}
						style={{
							border:'solid 1px',
							borderColor:'var(--bs-fiduc-gray-light)',
							borderRadius:'4px',
						}}
						>
							<CustomForm
								className="needs-validation "
								formId={formId}
								model={model}
								validateOnLoad={false}
								onSubmit={(event, values) => {
									setSaving(true)
									//setCurrentStep({formId, currentGroup:currentGroup+1, currentScreen:0})
								}}
								onCustomSubmit={(e, values, action) => {
									console.log(values)
								}}
							>
								<ClientInfo formId={formId} riskProfileListId={riskProfileListId} />

								<TabPills
									tabs={TabList}
									getInvalidTab={getTabError}
									getEnabledTab={getTabEnabled}
									activeTab={TabList[currentGroup]?.id || undefined}
									hasContent={true}
									innerProps={innerProps}
									onChange={(active, tabIndex) => {
										if (tabIndex!==currentGroup) {
											setCurrentStep({formId, currentGroup:tabIndex, currentScreen:0})
										} else {
											setCurrentGroup({formId, value:tabIndex})
										}
									}}
								/>


							</CustomForm>
						</Col>
					</Row>
					<Row style={submitRowStyle} >
						{/*<SubmitButtons*/}
						{/*    currentScreen={currentScreen}*/}
						{/*    screens={screens}*/}
						{/*    submit={submitMethod}*/}
						{/*/>*/}
						<Col md={12} className="p-2 pb-0 pt-0">
						<SaveButton
							currentScreen={currentScreen}
							submit={submitMethod}
						/>
						</Col>

					</Row>
					</>
				)}

			</Container>
		</DefaultPage>
		</UserCanAll>
	)
}

export default RegistrationForm;
