import {
	dateTimestampToISO,
	onlyNumbers,
	booleanValue,
	dateIsoToBr,
	MoneyFormat,
	optionValue, dateTimestampToBr,
} from "../utils";

const _ = require('lodash')

export const clientApiToLocal = (
	{
		client,
		regForm,
		professionalInformation,
		mailing,
		pep,
		fatca,
		suitability,
		renewStatus,
		needFormFatcaEUA,
		haveRenews
	}
) => {

	//alert(professionalInformation?.carrerId)

	const [residentialAddress] = client.addresses?.filter(
	  (address) => address.typeAddress == 1
	)

    const [additionalAddress] = client.addresses?.filter(
      (address) => address.typeAddress == 2
    )

	const mailingOptions = []

	if (mailing?.receiveQuotaholderExtract) mailingOptions.push('receiveQuotaholderExtract')
	if (mailing?.receiveReportIncome) mailingOptions.push('receiveReportIncome')
	if (mailing?.allowDistributorSendEmail) mailingOptions.push('allowDistributorSendEmail')

	const fatcaOptions = []

	if (fatca?.hasOtherFiscalResidence) fatcaOptions.push('hasOtherFiscalResidence')
	if (fatca?.hasOtherNationality) fatcaOptions.push('hasOtherNationality')
	if (fatca?.hasOtherVisa) fatcaOptions.push('hasOtherVisa')

	const notFiscalResidentOptions = []
	if (fatca?.isEuaLearner) notFiscalResidentOptions.push('isEuaLearner')
	if (fatca?.isPolitical) notFiscalResidentOptions.push('isPolitical')
	if (fatca?.isResponsible) notFiscalResidentOptions.push('isResponsible')
	if (fatca?.isNotIrs) notFiscalResidentOptions.push('isNotIrs')
	if (fatca?.isResignedCln) notFiscalResidentOptions.push('isResignedCln')
	if (fatca?.isResignedCitizen) notFiscalResidentOptions.push('isResignedCitizen')
	if (fatca?.isWithoutGcNotRequested) notFiscalResidentOptions.push('isWithoutGcNotRequested')
	if (fatca?.isWithoutGc) notFiscalResidentOptions.push('isWithoutGc')
	if (fatca?.isWithoutFiscalResidence) notFiscalResidentOptions.push('isWithoutFiscalResidence')


	let fatcaAnswers = []
	let fatcaRenouncedNationalities = []

	if (fatca?.answers?.length>0) {

		let options=''
		if (fatca.answers[0].isWaitingNif) options = 'isWaitingNif'
		else if (fatca.answers[0].hasNif) options = 'hasNif'
		else if (fatca.answers[0].dontNeedNif) options = 'dontNeedNif'


		fatcaAnswers.push({
			"nif": fatca.answers[0].nif,
			"countryId": optionValue(fatca.answers[0].countryId),
			"options": options,
			"type": optionValue(fatca.answers[0].type)
		})

		fatcaRenouncedNationalities = fatca?.renouncedNationalities
			?.reduce((map, obj) => {
				map.push(optionValue(obj.countryId))
				return map
			}, [])
		//fatcaRenouncedNationalities = [1]
		//console.log('fatcaRenouncedNationalities', fatcaRenouncedNationalities)
		//fatcaRenouncedNationalities = optionValue(fatca?.renouncedNationalities[0].countryId)

	}

	const suitabilityAnswers = {answers:{}}
	if (suitability.checked) {
		for (const questionId in suitability.checked) {
			suitabilityAnswers.answers[`${questionId}`] =suitability.checked[questionId].answerId.toString()
		}
	}

	// console.log('suitabilityAnswers', suitabilityAnswers)



	const parseBankInfo = (bankInfo) => {
		const coHolders = bankInfo.coHolders || []

		return {
			"haveCoHolders": booleanValue(bankInfo.coHolders?.length>0),
			"bankId": bankInfo.bankId?.toString(),
			"isBradescoAccount": booleanValue(bankInfo.isBradescoAccount),
			"agency": bankInfo.agency,
			"accountNumber": bankInfo.accountNumber,
			"debitAuthorization": booleanValue(bankInfo.debitAuthorization),
			"digit": bankInfo.digit,
			"coHolders": coHolders
		}
	}

	const apiBankAccounts = regForm?.holderBankInformation?.accounts || []
	const mainBankInformation = apiBankAccounts[0] || null
	const additionalBankInformation = apiBankAccounts[1] || null

	const bankAccounts = []
	if (mainBankInformation)
		bankAccounts.push(parseBankInfo(mainBankInformation))


	if (additionalBankInformation)
		bankAccounts.push(parseBankInfo(additionalBankInformation))


	const bankInformation = {
		"id" : regForm?.holderBankInformation?.id,
		"registrationFormId" : regForm.id,
		accounts : bankAccounts
	}

	//console.log('bankAccounts2', bankAccounts)

	const hasAdditionalBankAccount = bankAccounts.length>1

	const funds = regForm?.funds?.reduce(function(map, obj) {
		map.push(obj.id)
		return map;
	}, [])

	const terms = regForm?.terms?.reduce(function(map, obj) {
		map.push(obj.id)
		return map;
	}, [])


	//alert(MoneyFormat(client.salary))

	//if (bankInformation.length>0)


	//alert(client.associate?.id)
	return _.cloneDeep({
		"registrationForm" : {
			"id" : regForm.id,
			"score167": regForm.score167 || null,
			isApprovedAdm :  regForm.isApprovedAdm,
			isApprovedRenew :  regForm.isApprovedRenew,
		},

		"investorDeclaration": {
			"local": regForm?.investorDeclaration?.local,
			"date": dateTimestampToBr(regForm?.investorDeclaration?.date),
		},

		"client": {
			"id": client.id,
			"registrationFormId" : regForm.id,
			"riskProfileCategoryId" : client.riskProfileCategory?.id,
			"associateId": client.associate?.id,
			"associateChange": client.associateChange,
			"associateChangeHistory": client.associateChangeHistory,
			"status": booleanValue(client.active==true),
			"needFormFatcaEUA": needFormFatcaEUA,
			"haveRenews": haveRenews,
			"document": onlyNumbers(client.document),
			"name": client.name,
			"email": client.email,
			"birthDate": dateTimestampToBr(client.birthDate),
			"gender": client.gender?.toUpperCase(),
			"phoneNumber": onlyNumbers(client.phoneNumber),
			"additionalPhone": onlyNumbers(client.additionalPhone),
			"motherName": client.motherName,
			"fatherName": client.fatherName,
			"maritalStatus": optionValue(client.maritalStatus),
			"personColor": optionValue(client.personColor),
			"spouseName": client.spouseName,
			"spouseCpf": onlyNumbers(client.spouseCpf),
			"naturality": client.naturality,
			"nacionality": optionValue(client.nacionality?.id),
			"country": optionValue(client.country?.id),
			"civilCapacity": client.civilCapacity?.toString(),
			"welfare": booleanValue(client.welfare),
			"responsibleAuthorization": booleanValue(client.responsibleAuthorization),
			"responsible": {
				"id": client.responsible?.id,
				"name": client.responsible?.name,
				"document": onlyNumbers(client.responsible?.document),
				"relationType": client.responsible?.relationType,
			},
			"salary": MoneyFormat(client.salary),
			"otherIncome": MoneyFormat(client.otherIncome),
			"otherIncomeDescription": client.otherIncomeDescription,
			"realEstatePatrimony": MoneyFormat(client.realEstatePatrimony),
			"movablePatrimony": MoneyFormat(client.movablePatrimony),
			"financialInvestmentsPatrimony": MoneyFormat(client.financialInvestmentsPatrimony),
			//"investorFinancialSituation": MoneyFormat(client.investorFinancialSituation),
			"patrimonyUpdateDate": dateIsoToBr(client.patrimonyUpdateDate),
			"haveWorked": booleanValue(client.haveWorked),
			"haveRelation": booleanValue(client.haveRelation),
			"haveFatca": booleanValue(client.haveFatca),
			"haveFatcaVersion1": booleanValue(client.haveFatcaVersion1),
			"ocr": {
				"documentType": client.documentType?.toUpperCase(),
				"identificationNumber": client.identificationNumber,
				"orgemission": client.orgemission,
				"placeofemission": client.placeofemission,
				"expeditiondate": dateTimestampToBr(client.expeditiondate),
				"validDate": dateTimestampToBr(client.validDate),
			},
			"socialName": client.socialName
		},

		//OTHER
		"coHolder": {
			"document": "",
			"name": "",
			"birthDate": "",
			"phoneNumber": "",
			"email": ""
		},

		//professionalInformation
		"professionalInformation": {
			"professionalType": optionValue(
				professionalInformation?.professionalType === null
				? professionalInformation?.professionalType
				: professionalInformation?.professionalType.toString()
			),
			"carrerId": optionValue(professionalInformation?.carrerId),
			"pepFunctionId": optionValue(pep?.functionId),
			"company": professionalInformation?.company?.toString(),
			"cnpj": onlyNumbers(professionalInformation?.cnpj),
			//"functionId": optionValue(professionalInformation?.functionId)
		},

		"address": {
			"cep": onlyNumbers(residentialAddress?.cep),
			"street": residentialAddress?.street,
			"number": residentialAddress?.number,
			"neighborhood": residentialAddress?.neighborhood,
			"city": residentialAddress?.city,
			"state": residentialAddress?.state?.toUpperCase(),
			"complement": residentialAddress?.complement
		},
		"mailing": {
			"mailType": optionValue(mailing?.mailType),
			"address": {
				"cep": onlyNumbers(additionalAddress?.cep),
				"street": additionalAddress?.street,
				"number": additionalAddress?.number,
				"neighborhood": additionalAddress?.neighborhood,
				"city": additionalAddress?.city,
				"state": additionalAddress?.state?.toUpperCase(),
				"complement": additionalAddress?.complement
			},
			"options": mailingOptions
		},

		"bankInformation": bankInformation,
		"hasAdditionalBankAccount": booleanValue(hasAdditionalBankAccount),

		"pep": {
			"functionId": optionValue(pep?.functionId),
			"initialDate": dateTimestampToBr(pep?.initialDate),
			"endDate": dateTimestampToBr(pep?.endDate),
			"company": pep?.company,
			"cnpj": onlyNumbers(pep?.cnpj),
			"nameOfRelation": pep?.nameOfRelation,
			"documentRelation": onlyNumbers(pep?.documentRelation),
			"functionRelationId": optionValue(pep?.functionRelationId),

			"relationTypeId": optionValue(pep?.relationTypeId),
			"parentId": optionValue(pep?.parentId),
			"relationId": optionValue(pep?.relationId),
		},

		"suitability": suitabilityAnswers,
		"fatca": {
			"options": fatcaOptions,
			"answers": fatcaAnswers,
			"hasRenouncedNationality": booleanValue(fatca?.hasRenouncedNationality),
			"renouncedNationalities": fatcaRenouncedNationalities || [],

			requestType : fatca?.requestType,
			hasOtherFiscalRes : booleanValue(fatca?.hasOtherFiscalRes),
			fiscalCountry : optionValue(fatca?.fiscalCountry),
			otherCountry : optionValue(fatca?.otherCountry),
			nifNumber : fatca?.nifNumber,

			bornEua : booleanValue(fatca?.bornEua),
			isNorthAmerican : booleanValue(fatca?.isNorthAmerican),
			isPermanentEua : booleanValue(fatca?.isPermanentEua),
			isFiscalEua : booleanValue(fatca?.isFiscalEua),
			refuseSsn : booleanValue(fatca?.refuseSsn),
			ssn : fatca?.ssn,
			notFiscalResidentOptions : notFiscalResidentOptions,
		},

		"doccapture": {
			"proofOfResidence": "[File Upload]"
		},
		funds,
		terms,
		renewStatus : {
			...renewStatus,
			...{
				expired_date:renewStatus.expired_date ? dateIsoToBr(renewStatus.expired_date) : null
			}
		},

	})
}
