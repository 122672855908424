import React, {useEffect, useState} from "react"

import KYCInfo from "../components/KYCInfo";
import {dateIsoToBr} from "../../../../../renders/utils";

function KYCBasicData(
{
	KYCData,
	...props
}) {


	const [data, setData] = useState(null)

	useEffect(() => {
		console.log('KYCData BASIC', KYCData)
		const info = KYCData[0]
		setData([
			{caption:'Nome', value:info?.basicData?.name},
			{caption:'Nome da mãe', value:info?.basicData?.motherName},
			{caption:'Nome do pai', value:info?.basicData?.fatherName},
			{caption:'Data de nascimento', value:dateIsoToBr(info?.basicData?.birthDate)},
			{caption:'Idade', value:info?.basicData?.age},
			{caption:'Gênero', value:info?.basicData?.gender},
			{caption:'Nacionalidade', value:info?.basicData?.birthCountry},
			{caption:'País', value:info?.basicData?.taxIdCountry},
			{caption:'Status', value:info?.basicData?.taxIdStatus},
			{caption:'Origem', value:info?.basicData?.taxIdOrigin},
			{caption:'Indicação de óbito?', value:info?.basicData?.hasObitIndication ? 'SIM' : 'NÂO'},
		])
	}, [KYCData])

	return (
		<KYCInfo data={data} />
	)
}

export default KYCBasicData;
