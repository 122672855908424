import React from "react"

export function getValidDomProperties(props) {
    const {
    	caption,
		type,
		inputRef,
		inline,
		formId,
		screenId,
		validation,
		vertical,
		OnInputValueChange,
		screenIndex,
		validate,
		listId,
		rComponent,
		customFormatter,
		modelValue,
		noEmptyOption,
		maxLength,
		tabId,
		requiredMethod,
		validationInRealTime,
		fieldError,
		loading,
		md,
		sm,
		xs,
		xl,
		lg,
		...rest
    } = props

	return rest
}

export const hideActiveModal = (cb) => {
	try {
		document.querySelector(`#root>div>div[tabindex="0"]`).click()
		if (cb)
			setTimeout(cb, 500)
		return true
	} catch(e) {
		return false
	}
}
