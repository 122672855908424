import React, {useEffect, useState} from "react"

import Field from "../../../../components/Common/CustomForm/Field";
import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";
import {useOvermind} from "../../../../overmind";
import {dateIsoToBr} from "../../../../renders/utils";
import Button from "../../../../components/Common/Button/Button";
import {Badge} from "reactstrap";
import DropDownSplit from "../../../../components/Common/DropDownSplit/DropDownSplit";
import DropDown from "../../../../components/Common/DropDown/DropDown";

const fundsByName = {
	'adesao_rf' : 'FIDUC RENDA FIXA',
	'adesao_mm' : 'FIDUC MULTIMERCADO',
	'adesao_rv' : 'FIDUC RENDA VARIAVEL',
	'adesao_internacional' :'FIDUC INTERNACIONAL',
	'desenquadramento' :'TERMO DE DESENQUADRAMENTO',
}

const ZapSignDocuments = ({
	formId,
	...props
}) => {

	const [openingTerm, setOpeningTerm] = useState({})

	const openTerm = (docToken, openKey) => {
		if (!openKey) openKey = docToken
		setOpeningTerm({
			...openingTerm,
			[openKey] : true
		})
		client.actions
			.openZasignDocument(docToken)
			.finally(() => {
				setOpeningTerm({
					...openingTerm,
					[openKey] : false
				})
			})
	}

	const getTermDescription = row => {
		const terms = row?.zapsignUploadTerms
		if (!terms)
			return "(Inválido)"

		return terms.map(item => {
			return (<React.Fragment key={item.id}>
					<Badge
						className={
							"m-1 px-2 p-1 font-weight-500 pt-2 pb-2 font-size-em-3 badge-soft-gray"
						}
						style={{fontSize:'9px'}}
						color={'secondary'}
						pill
					>
						{fundsByName[item.template_name]}
						{item.version && item.template_name!=='desenquadramento' ? ` (v${item.version})` : null}
					</Badge>
				</React.Fragment>
			)
		})

	}

	const [data, setData] = useState(null)
	const client  = useOvermind('clients')

	useEffect(() => {
		if (!client?.state?.currentRegistrationForm?.form?.client?.id)
			return

		client.actions.loadZapsignDocuments()
		 	.then(result => {
		 		const documents = result.filter(item =>
					!item.client_history_id &&
					item?.zapsignUploadTerms?.length
					&&
					!item?.zapsignUploadTerms.find(el => el.template_name==='renovacao_cadastral')
				)
		 		setData(documents)
				console.log('ZapsignDocuments', documents)
			})
			.catch((e) => setData(null))
	}, [client?.state?.currentRegistrationForm?.form?.client?.id])

	return (data && data.length>0) && <>
		<Field
			formId={formId}
			type="title"
			caption="Termos assinados na ZapSign"
			className="mb-0"
		/>
		<DefaultTable
				className="pt-0 m-0"
				responsive={false}
				columns={[
				{id:'template_name', caption:'Termo(s)', width:'60%', formatter:row => getTermDescription(row)},
				// {id:'version', caption:'Versão', width:'10%', formatter:row => row.version},
				{id:'created_at', caption:'Data', width:'20%', formatter:row => dateIsoToBr(row.created_at)},
				{id:'actions', width:'20%', caption:'Documento',
					formatter : function Actions(item) {
						const terms = item.zapsignUploadTerms

						if (terms.length>1) {
							const options = terms.map(el => {
								return {
									id:el.doc_token,
									caption:fundsByName[el.template_name],
									class:'primary', color:'black',
								}
							})

							return <DropDown
								options={options}
								placeHolder={'Abrir'}
								loading={openingTerm[item.doc_token]}
								onChange={option => openTerm(option.id, item.doc_token)}
							/>

						}

						return <>
							<Button
								className="btn btn-primary btn-block"
								type="button"
								loading={openingTerm[item.doc_token]}
								onClick={() => openTerm(item.doc_token)}
							>
								Abrir
							</Button>
						</>
					}
				},
			]}
			data={data}
		/>

	</>

}

export default ZapSignDocuments
