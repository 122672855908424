import React from 'react'

const BadgeRenew = (props) => {
	const status   = props?.data?.status

	if (status=='ok')
		return null

	if (status=='available')
		return  <span className="badge bg-warning text-uppercase badge-renew-status float-end p-2 mt-2">Disponível</span>

	if (status=='expired')
		return  <span className="badge bg-danger text-uppercase badge-renew-status float-end p-2 mt-2">Vencida</span>

	return null
}

export default BadgeRenew
