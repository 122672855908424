import React from "react"
import RegistrationFormSection from "../RegistrationFormSection";

import {suitabilityScreens} from "../Configuration/SuitabilityDataConfig";

function SuitabilityData(props) {
    return <RegistrationFormSection screens={suitabilityScreens} {...props} />
}

export default SuitabilityData;
