import React, {useEffect, useState} from "react"

import {
	Col,
	Row,
	Card,
	CardBody,
	CardTitle,
	CardText,
	TabPane,
	NavLink,
	NavItem,
	TabContent,
	Nav,
	Table,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import { useOvermind } from "../../../overmind";

// import FormTitleDefault from "../../components/Common/CustomForm/SubTitles/TitleDefault";
import Field from "../../../components/Common/CustomForm/Field";
import {dateIsoToBr} from "../../../renders/utils";
import LoadingSection from "../../../components/Common/LoadingSection/LoadingSection";
import DefaultTable from "../../../components/Common/Tables/DefaultTable/DefaultTable";
import {deviceTypesMapped, investmentStatusMapped} from "../../../constants/formLists";

const actionsList = {
	"change-status" : "Alteração de Status",
	"change-date" : "Alteração de data de pagamento",
}

const getMessageInfo = (row) => {
	if (row.type==='change-status')
		return <>Alterou status para {investmentStatusMapped[row.status]?.caption || row.status}</>
	if (row.type==='change-date')
		return <>Alterou data de pagamento para {dateIsoToBr(row.paymentAt)}</>
}

function InvestmentHistory({investmentId, ...props}) {

	const client = useOvermind('clients')
	const investment = useOvermind('investments')

	const [error, setError] = useState(false)
	const [loading, setLoading] = useState(false)
	const [history, setHistory] = useState(null)

	useEffect(() => {

		setLoading(true)
		// const url = `renew/list/${client.state.currentRegistrationForm.form.client.id}`

		investment.actions
			.listInvestmentHistory({investmentId})
			//.then(sleep(2000))
			.then(data => {
				setHistory(data)
			})
			.catch(e => {
				setError('Não foi possível carregar as informações')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}, [])

	return (<>

		<LoadingSection loading={loading} error={error}
			noData={history?.length===0 ? <div className="pt-4">Não há histórico registrado.</div> : null}
		>
			<Col className={"  "}>
			</Col>

			<DefaultTable
				className="pt-0 mt-0 font-size-rem-2"
				columns={[
					{id:'created_at', caption:'Data', formatter:(row) => dateIsoToBr(row.created_at, null, 'DD/MM/YYYY [às] HH:mm:ss')},
					// {id:'action', caption:'Ação', formatter:(row) => actionsList[row.type] || row.type},
					{id:'author', caption:'Operador', formatter:(row) => row.user?.name},
					{id:'deviceTypes', caption:'Ação', formatter:function A(row) {
						return getMessageInfo(row)
					}},
				]}
				data={history}
			/>

		</LoadingSection>
	</>)

}

export default InvestmentHistory;
