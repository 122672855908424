import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../overmind'
import { useLocation, useHistory } from "react-router-dom"

import LoadingSection from "../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../components/Common/CustomForm/Utils/Validations";
import Button from "../../../components/Common/Button/Button"
import {yesNoOptions} from "../../../constants/formLists";
import {onlyNumbers, dateBrToISO} from "../../../renders/utils";
import {AuthInfo, UserCan} from "../../../helpers/gestora/auth";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import CustomForm from "../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";

const statusTabs = [
	{id:'all', caption:"Dados pessoais"},
]

function MyAccountView(props) {

    const {
		onConfirm = () => {},
		onCancel = () => {},
	} = props

	const formId = "MyAccount"

	const readOnly = !UserCan('users.edit')
	const viewId = AuthInfo().id
	const history = useHistory()

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const users = useOvermind('users')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	//const [data, setData] = useState(null)

    useEffect(() => {
    	setError(false)
    	setSaving(false)
    	setSaved(false)

		setLoading(true)
		// resetForm({formId})
		users.actions.loadById({formId, id:viewId})
			.then(record => {

				//setData(record)

				setModelValueByPath({formId, path:'edit', value:record})
				incFormValidatedCount({formId})

			})
			.catch(e => {
				setError(e.response?.data || e.toString())
			})
			.finally(() => {
				setLoading(false)
			})



    }, [viewId])

	const handleConfirm = async (e) => {

		const values = _.cloneDeep(model)

		//console.log(values)

		//const createDate = `${dateBrToISO(values.changeStatus.date.trim())} ${values.changeStatus.time.trim()}`
		//alert(createDate)

		const data = {
			...values.edit,
            document : onlyNumbers(values.edit.document),
			birthDate : values.edit.birthDate ? dateBrToISO(values.edit.birthDate) : null,
			initialDate : values.edit.initialDate ? dateBrToISO(values.edit.initialDate) : null,
		}

    	setSaving(true)
    	users.actions.save({
				formId,
				id:viewId,
				data,
			})
			// .then(sleep(1000))
			.then(() => {
				setSaved(true)
				  history.push({
					  pathname:"/dashboard",
				  })
				onConfirm()
				setTimeout(() => {
					setSaving(false)
				}, 2000)
			})
			.catch((e) => {
				const errCode = e.response?.data?.error?.message
				if (errCode=='duplicate_document') {
					alert('Não foi possível salvar, este CPF já está cadastrado')
					setSaving(false)
				} else {
					setError(`Ocorreu um erro ao salvar: ${e.toString()}`)
					setSaving(false)
					setSaved(false)
				}

			})

	}

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : [
				// {
				// 	id:'title',
				// 	caption:'Dados pessoais',
				// 	type:'title',
				// 	required:true,
				// 	row:true,
				// 	readOnly,
				// 	col:8,
				// },
				{
					id:'edit.name',
					caption:'Nome Completo',
					type:'text',
					required:true,
					row:true,
					readOnly,
					col:12,
				},
				{
					id:'edit.birthDate',
					caption:'Data de Nascimento',
					type:'date',
					required:true,
					readOnly,
					col:12
				},
				{
					id:'edit.document',
					caption:'CPF',
					type:'cpf',
					required:true,
					readOnly,
					col:12
				},
				{
					id:'edit.email',
					caption:'E-mail',
					type:'text',
					required:true,
					readOnly,
					col:12
				},
				{
					id:'edit.phone',
					caption:'Telefone',
					type:'phone',
					required:true,
					readOnly,
					col:12
				},
			],
		},
	], [viewId]);

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(statusScreens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:statusScreens}]

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

	const handleCancel = () => {
    	history.goBack()
	}


	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
        <UserCanAll permission="users.consult">
			<DefaultPage
				headerComponent={
					<DefaultPageHeader title="Minha conta"  />
				}
			>
				<LoadingSection loading={loading} error={error}>
					<PageDatatable
						formId={formId}
						tabs={statusTabs}
						activeTab={'all'}
						TabActions={null}
					>
						<CustomForm
							className="needs-validation"
							formId={formId}
							model={model}
							validateOnLoad={false}
						>
						<Row className="pr-4 ps-4">
							<Col md={12} className="pt-2 ">
								<Row >
								<Col md={12} className="w-100 d-block align-items-start">
									<FormWizard
										screenId={formId}
										screens={statusScreens}
										formId={formId}
										// OnInputValueChange={handleInputValueChange}
										tabId={formId}
									/>
								</Col>
								</Row>
								<Row >
									<Col md={12} className="d-flex align-items-center justify-content-center">
										{/*<Button*/}
										{/*	autoFocus*/}
										{/*	className="btn btn-outline-danger"*/}
										{/*	onClick={handleCancel}*/}
										{/*	style={{margin:'1em'}}*/}
										{/*>*/}
										{/*	Voltar*/}
										{/*</Button>*/}
										<UserCanAll permission="users.edit">
										<Button
											loading={saving}
											disabled={!!formValidationResult?.first}
											colorIcon={true}
											className="btn btn-primary "
											// disabled={currentScreen==0}
											style={{margin:'1em'}}
											onClick={handleConfirm}
											>
											Salvar
										</Button>
										</UserCanAll>
									</Col>
								</Row>
							</Col>
						</Row>
						</CustomForm>
					</PageDatatable>
				</LoadingSection>
			</DefaultPage>
        </UserCanAll>
    )
}

export default MyAccountView;
