import React, {useEffect, useState} from "react"
import {ButtonDropdown, Spinner} from "reactstrap";
import {
	Dropdown,
	DropdownToggle, DropdownMenu, DropdownItem,
	Button
} from "reactstrap"

const DropDownIcon = ({option, ...props}) =>
	<>
		<i className={`${option?.icon} font-size-16 align-middle me-1`}></i>
		{" "}
	</>

function DropDownSplit({
		children,
		options = [],
		placeHolder = 'Nenhum',
		unselected = {id:-1, caption:placeHolder, color:'primary'},
		selected = unselected,
		defaultValue = null,
		onClick=() => {},
		color="primary",
		loading=false,
		...props
	}) {

	const [isOpen, setIsOpen] = useState(false)

	const handleOnClick = (evt) => {
		const id = evt?.target?.id

		const selectedOption = options.find(option => option.id == evt.target.id)
		//alert(evt.target.id)
		//if (selectedOption.id!=selected.id)
			onClick(selectedOption)
		// console.log('selectedId', evt.target.id)
		// console.log('selectedOption', selectedOption)
	}

    return (
		<ButtonDropdown
			isOpen={isOpen}
			toggle={() => setIsOpen(!isOpen)}
		 	direction="left"
			className="btn btn-primary p-0 m-0"
			disabled={loading}
		>
			  <Button
				  color={selected?.color || color}
				onClick={handleOnClick}
			disabled={loading}
			  >
				  {loading ? (
					<Spinner
					  style={{ width: "0.9em", height: "0.9em", marginRight:"0.5em", marginTop: '0px' }}
					  type="grow"
					  color="light"
					/>
				  ):null}
				  <div style={{display:'inline-flex' }}>{selected?.caption || placeHolder}</div>

			  </Button>
			<DropdownToggle tag="button" type="button" className={`btn btn-${selected?.color || color}`}>
				<i className="mdi mdi-chevron-down" />
			</DropdownToggle>
			<DropdownMenu data-popper-placement="left-start" >
				{options.map((option) => {
					if (!option.id)
						return <DropdownItem divider key={option} />

					return <DropdownItem
						key={option.id}
						id={option.id}
						className={`text-${option.color || color}`}
						onClick={handleOnClick}
					>
						<DropDownIcon option={option}/>
						{option.caption}
					</DropdownItem>
				})}
			</DropdownMenu>
		</ButtonDropdown>

    )
}

export default DropDownSplit;
