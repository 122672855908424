import React, {useMemo} from "react"
import ReactApexChart from "react-apexcharts"
import {MoneyFormat} from "../../../../renders/utils";
import {defaultGraphColors} from "../../../../helpers/gestora/configuration";


const GraphPie = ({
	seriesData=[],
	labelsData,
	colorsData = defaultGraphColors,
	labelFormatter = value => `${Math.round(value).toString().padStart(3, ' ')}%`,
	tooltipFormatter = value => value,
}) => {

	const graphConfig = useMemo(() => {

		return {
			series: seriesData,
			options: {
				states : {
					active : {
						filter : {
							type:'none'
						},
					}
				},
				plotOptions: {

					pie: {
						expandOnClick: false,
						customScale : 0.8,
						dataLabels : {
							offset : 30,
						},
					}
				},
				chart : {
					height:'100%',
					animations : {
						enabled : false,
						animateGradually: {
							enabled: true,
							delay: 60
						}
					},
				},
				dataLabels : {
					style : {
						colors : ['#000']
					},
					dropShadow : {
						color : '#fff',
						opacity:0,
					},
					formatter : labelFormatter,
				},
				stroke: {
					width: 0
				},
				tooltip : {
					fillSeriesColor:false,
					theme:'light',
					enabled:true,
					onDatasetHover: {
					  highlightDataSeries: false,
					},
					y : {
						formatter : tooltipFormatter,
					},
				},
				labels: labelsData,
				colors: colorsData,
				legend: {
					show: false,
					position: "bottom",
					horizontalAlign: "center",
					verticalAlign: "middle",
					floating: true,
					fontSize: 12,
					offsetX: 0,
					offsetY: 0,
				},
				// responsive: [
				// 	{
				// 		breakpoint: 600,
				// 		options: {
				// 			chart: {
				// 				height: 240,
				// 			},
				// 			legend: {
				// 				show: false,
				// 			},
				// 		},
				// 	},
				// ],
			},
		}
	}, []);

	return (
		<ReactApexChart
			options={graphConfig.options}
			series={graphConfig.series}
			type="pie"
			height={'100%'}
		/>
	)
}

export default GraphPie

