import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../../helpers/gestora/api"

import { useLocation, useHistory } from "react-router-dom"

import { useOvermind } from "../../../../overmind";
import {Datatable} from "../../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
moment.locale('pt-BR')

import CustomForm from "../../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../../components/Common/DefaultPage/DefaultPage";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import Button from "../../../../components/Common/Button/Button"

import UsersView from "../View";

import PageDatatable from "../../../../components/Common/Tables/Datatable/PageDatatable";
import DefaultPageHeader from "../../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../../common/url";
import {yesNoOptions} from "../../../../constants/formLists";
import {DataActionButton, DataDate} from "../../../../components/Common/Tables/Datatable/DataTypes";
import {formatPhone, parseDocument} from "../../../../helpers/parser_helper";
import {dateBrToISO} from "../../../../renders/utils";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";
import {UserCan} from "../../../../helpers/gestora/auth";
import {useLocalStorage} from "../../../../helpers/gestora/UseLocalStorage";

const mainTabs = [
	{id:'all', caption:"Busca"},
]

//console.log('statusTabs', statusTabs)

export const UsersSearch = () => {
	const formId = 'UsersSearch'
	const location = useLocation()
	const history = useHistory()

	// const [loading, setLoading] = useState(false);
	const [searchParams, setSearchParams] = useState(null);
	const [filterParams, setFilterParams] = useState({});
	// const [filterStatus, setFilterStatus] = useState(history.location?.state?.status || 'all');
	// const [selectedPeriod, setSelectedPeriod] = useState(null)
	const [selectedIds, setSelectedIds] = useState([])
	// const [compactMode, setCompactMode] = useState(true)
	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, true)

	const [exportingResult, setExportingResult] = useState(false)

	const [viewId, setViewId] = useState(null)


	// const app = useOvermind('app')

	const apiEndpoint = {
		path : `users/search`,
		method : `post`,
	}

	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			setModelValueByPath,
		}
	} = useOvermind('forms')

	const users = useOvermind('users')

	const handleExportResult = (evt, fileTitle=null) => {

		setExportingResult(true)
		api
			.post(`users/downloadCsv`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const refreshList = () => {
		setSearchParams({
			...filterParams,
			// periodStart : model?.period?.length && model?.period[0],
			// periodEnd : model?.period?.length && model?.period[1],
		})
	}

	useEffect(() => {
		refreshList()
	}, [filterParams])

	const handleFilterChange = () => {
		const newParams = {
			...filterParams,
			..._.cloneDeep(model),
			birthDate : model.birthDate ? dateBrToISO(model.birthDate) : null,
			//value : parseMoney(model.value) ,
		}
		console.log('handleFilterChange', newParams)
		setFilterParams(newParams)
	}

	const handleClick = row => {
		setViewId(row.id)
	};

	const handleNew = async () => {
		setViewId('')
	};

	const handleDelete = async (row) => {
		if (confirm(`Deletar usuário "${row.name}" ?`)) {
			users
				.actions
				.deleteById(row.id)
				.then(() => {
					refreshList()
				})
				.catch((e) => {
					const errCode = e.response?.data?.error?.message
					alert('Ocorreu um erro ao tentar deletar o usuário')
				})
		}
	};

	const handleResetPassword = async (row) => {

		if (row.active==0) {
			alert(`Usuário "${row.name}" está Inativo. Não é possível resetar a senha. `)
			return
		}

		if (confirm(`Resetar a senha do usuário "${row.name}" ?`)) {
			users
				.actions
				.resetPassword(row.id)
				.then(() => {
					alert('Nova Senha enviada para o email cadastrado')
					//refreshList()
				})
				.catch((e) => {
					const errCode = e.response?.data?.error?.message
					alert('Ocorreu um erro ao tentar resetar a senha')
				})
		}
	};

	const columns = useMemo(() => [
		// {
		// 	id : 'id',
		// 	name: <CustomHeader onComplete={handleFilterChange} id={'id'} formId={formId} fieldProps={{type:'number'}}>ID</CustomHeader>,
		// 	grow:0,
		// 	width:'64px',
		// 	selector: row => row?.id,
		// 	sortable: true,
		// 	compact : true,
		// 	wrap : true,
		//
		// },
		{
			id : 'name',
			name: <CustomHeader onComplete={handleFilterChange}  id={'name'} formId={formId} fieldProps={{type:'text'}}>Nome</CustomHeader>,
			grow:1,
			minWidth:'128px',
			selector: row => row?.name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'birthDate',
			name: "Nascimento",
			grow:0.1,
			width:'128px',
			selector: function A(row) {
				return <DataDate value={row?.birthDate} withTime={false}/>
			},
			sortable: false,
			compact : true,
			omit : compactMode,
			wrap : true,
		},
		{
			id : 'document',
			name: <CustomHeader onComplete={handleFilterChange} id={'document'} formId={formId} fieldProps={{type:'cpfcnpj'}}>CPF</CustomHeader>,
			width:'172px',
			grow:0.8,
			selector: row => parseDocument(row?.document),
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'email',
			name: <CustomHeader onComplete={handleFilterChange} id={'email'} formId={formId} fieldProps={{type:'text'}}>E-mail</CustomHeader>,
			width:'172px',
			grow:0.8,
			selector: row => row?.email,
			sortable: true,
			compact : true,
			omit:compactMode,
			wrap : true,
		},
		{
			id : 'phone',
			name: <CustomHeader onComplete={handleFilterChange} id={'phone'} formId={formId} fieldProps={{type:'phone'}}>Telefone</CustomHeader>,
			width:'156px',
			grow:0.8,
			selector: row => formatPhone(row?.phone),
			sortable: true,
			compact : true,
			omit:compactMode,
			wrap : true,
		},
		{
			id : 'profile_id',
			name: <CustomHeader onComplete={handleFilterChange} id={'profileId'} formId={formId} fieldProps={{type:'select2', listId:'UserProfile'}}>Perfil</CustomHeader>,
			width:'156px',
			grow:0.8,
			selector: row => row?.profile?.name,
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'active',
			name: <CustomHeader onComplete={handleFilterChange}  id={'active'} formId={formId} fieldProps={{type:'select2', options:yesNoOptions}}>Ativo</CustomHeader>,
			grow:0,
			minWidth:'96px',
			selector: row => row?.active ? "Sim" : "Não",
			sortable: true,
			compact : true,
			wrap : true,
		},
		{
			id : 'actions',
			name: "Ações",
			grow:0,
			center:true,
			minWidth:'128px',
			omit : !UserCan('users.edit'),
			selector: function A(row) {
				return <div className="d-flex flex-row">
					{/*<DataActionButton*/}
					{/*	title={"Editar"}*/}
					{/*	disabled={row.id==1}*/}
					{/*	buttonClass="btn btn-outline-success btn-sm m-1"*/}
					{/*	iconClass="fas fa-edit"*/}
					{/*	onClick={() => setViewId(row.id)}*/}
					{/*/>*/}
					<DataActionButton
						title={"Apagar usuário"}
						buttonClass="btn btn-outline-danger btn-sm m-1"
						iconClass="fas fa-trash "
						onClick={() => handleDelete(row)}
					/>
					<DataActionButton
						title={"Resetar a senha"}
						buttonClass="btn btn-outline-danger btn-sm m-1"
						iconClass="fas fa-unlock-alt "
						onClick={() => handleResetPassword(row)}
					/>
					{/*<DataActionButton*/}
					{/*	title={"Resetar Senha"}*/}
					{/*	buttonClass="btn btn-danger btn-sm m-1"*/}
					{/*	iconClass="fas fa-trash "*/}
					{/*	onClick={() => handleDelete(row)}*/}
					{/*/>*/}
				</div>
			},
			sortable: false,
			compact : true,
			wrap : true,
		},
	], [compactMode]);

	const searchFields = useMemo(() => [
	], []);

	//console.log(orderDirection)
	return (<UserCanAll permission={'users.consult'}>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Usuários"  />
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
				{viewId!==null ?
				<UsersView
					formId={formId}
					viewId={viewId}
					onConfirm={() => {
						setViewId(null)
						refreshList()
					}}
					onCancel={() => {
						setViewId(null)
					}}
				/> : null}

				<PageDatatable
					formId={formId}
					tabs={mainTabs}
					activeTab={'all'}
					onTabChange={null}
					TabActions={<>
						<UserCanAll permission="users.edit">
						<Button
							className="btn btn-primary m-1 btn-sm"
							onClick={handleNew}
						>
							<i className="fas fa-plus m-1" />
							Adicionar Usuário
						</Button>
						</UserCanAll>
						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						<Button
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={() => setCompactMode(!compactMode)}>
							<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>
						</Button>
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						heightSpace={'220px'}
						selectableRows={false}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						onRowClicked={(row, e) => handleClick(row)}
						keyField={formId}
						formId={formId}
						columns={columns}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
		</UserCanAll>
	);
};

export default {
	title: 'Pagination/Remote',
	component: UsersSearch,
};
