import React, {useState} from "react"

import { AvField, AvFeedback } from "availity-reactstrap-validation"
import {getValidDomProperties} from "../Utils/DomProp";
import FieldText from "./Text";

export const validate = (value) => {
    return value.toString().trim() ? true : false
}

function FieldPassword(props) {

	const [value, setValue] = useState('')
    const handleChange = (e) => {
		let inputValue = e.target.value

		//if (inputValue) alert(props.name)

		if (props.customFormatter) {
			inputValue = props.customFormatter(props, inputValue)
		}
		setValue(inputValue)

        if (props.onChange)
            props.onChange(props.name, inputValue)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
			onCopy={(e) => e.preventDefault()}
            type="text"
            {...rest}
            errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="off"
            onChange={handleChange}
			value={value ? value : ''}
			required={props.required}

        />
    )
}

export default FieldPassword;
