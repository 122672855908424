import api, {sleep} from "../../helpers/gestora/api";
import {reloadRemoteList} from "../../constants/remoteLists";


export const loadById = async ( { state, actions }, {formId, id} ) => {

	return api
		.get(`banks/${id}`)
		.then(async res => {
			return {
				...res.data,
				active : res.data.active===1 ? "1" : "0"
			}
		})
}

export const save = async ( { state, actions }, {formId, id, data} ) => {

	const request = id
		? api.put(`banks/${id}`, data)
		: api.post(`banks/`, data)

	return await request
		.then(async res => {
			await reloadRemoteList('Bank')
			return res.data
		})
}

export const deleteById = async ( { state, actions }, id ) => {

	return api.delete(`banks/${id}`)
		.then(async res => {
			await reloadRemoteList('Bank')
			return res.data
		})
}

