import React, {useState} from "react"

import {
	Row,
	Col,
	Card, CardBody,
	UncontrolledPopover, Popover, PopoverHeader, PopoverBody,
	Table,
} from "reactstrap"

import moment from "moment"
moment.locale('pt-BR')

// import { Link } from "react-router-dom"
//
// import avatar1 from "../../../../assets/images/users/avatar-1.jpg"
// import profileImg from "../../../../assets/images/profile-img.png"
import {useOvermind} from "../../../../overmind";
//import Field from "../../../../components/Common/CustomForm/Field";
// import FieldSelect from "../../../../components/Common/CustomForm/Inputs/Select";
import Field from "../../../../components/Common/CustomForm/Field";
// import {riskRrofileOptions} from "../../../../constants/formLists";
import Switch from "../../../../components/Common/Switch/Switch";
import {isCpfFormat} from "../../../../components/Common/CustomForm/Utils/Validations";
import {formatCnpj, formatCpf, onlyNumbers} from "../../../../renders/utils";
import api from "../../../../helpers/gestora/api";
//import { get, map } from "lodash";
import Button from "../../../../components/Common/Button/Button"

import DefaultAvatar from "../../../../assets/images/defaultAvatar.png"
import PrevPng from "../../../../assets/images/icons/prev.png"
import DropDownSplit from "../../../../components/Common/DropDownSplit/DropDownSplit";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";
import {RegFormApprovalRenewEnum} from "../../../../constants/formLists";
import {DataActionButton} from "../../../../components/Common/Tables/Datatable/DataTypes";
import CustomModal from "../../../../components/Common/Modals/CustomModal";
import ChangeAssociateHistory from "./ChangeAssociateHistory";

const BadgePrev = () => {
	return <>
		<div className="badge-prev badge badge-secondary p-2 pt-1 pb-1 me-1 align-items-center justify-content-center d-flex">
			<div className="p-0 "><img src={PrevPng} height={10} /></div>
			<div className="p-0">Previdência</div>
		</div>
	</>
}

const BadgeInvestmentBlocked = (
	{
		caption='Movimentações bloqueadas',
		className = '',
	}
) => {
	return <>
		<div className={`badge-renew-blocked badge badge-secondary p-2 pt-1 pb-1 me-1 align-items-center justify-content-center d-flex ${className}`}>
			<div className="p-0 text-uppercase">{caption}</div>
		</div>
	</>
}

const BadgeFatcaEUA = (props) => {
	return <>
		<div className="badge-fatca-eua badge badge-danger p-2 pt-1 pb-1 me-1 align-items-center justify-content-center d-flex">
			<div className="p-0" style={{height:'10px'}}>{props.caption}</div>
		</div>
	</>
}

const BadgeNoPhoneNumber = () => {
	return <>
		<div className="badge-fatca-eua badge badge-danger p-2 pt-1 pb-1 me-1 align-items-center justify-content-center d-flex">
			<div className="p-0" style={{height:'10px'}}>Sem número de telefone</div>
		</div>
	</>
}

const ScoreRisk = ({
	score = 0,
}) => {
	if (score<=160)
		return <div className="d-flex bg-fiduc-blue opacity-75 text-white justify-content-center p-2 rounded-2">Risco Baixo</div>

	if (score<=21)
		return <div className="d-flex bg-success opacity-75 text-white font-weight-semibold justify-content-center p-2 rounded-2">Risco Médio</div>

	return <div className="d-flex bg-danger text-white font-weight-semibold justify-content-center p-2 rounded-2">Risco Alto</div>
}

const ScoreDetails = ({
		score167 : {
			score = 0,
			details = {}
		},
		...props
	}) => {

	if (!score)
		return null

	return <div className="table-responsive mt-0" >
		<ScoreRisk score={score} /><br />
		<Table className="table mt-0 mb-0 table-sm"  style={{fontSize:'0.75em'}}>
			<thead>
			<tr>
				<th>Fator</th>
				<th>Cenário</th>
				<th>Pontos</th>
			</tr>
			</thead>
			<tbody>
			{Object.keys(details).map((factor, index)=> {
				return (
					<tr key={factor} >
						<th width={100} scope="row">{factor}</th>
						<td>{details[factor].id}</td>
						<td>{details[factor].points}</td>
					</tr>
				)
			})}
			<tr key={'total'} style={{backgroundColor:'#f4f4f4'}}>
				<th width={100} scope="row">Total</th>
				<td>{" "}</td>
				<td>{score}</td>
			</tr>
			</tbody>
		</Table>
	</div>

	// return map(Object.keys(details), (factor, detail) => (
	// 	<li>{factor} {details[factor].id} {details[factor].score}</li>
	// ))

}

const ClientInfo = (props) => {

	const { formId, riskProfileListId } = props

	const {
		state   : {
			[formId]:{currentGroup, currentScreen, model, uploadedFiles, readOnly}
		},
		actions : {
			setFormState, setFormModel, setCurrentGroup, setCurrentStep, setCurrentScreen, setUploadedFiles
		}
	} = useOvermind('forms')

	const client = useOvermind('clients')
	// const renew = useOvermind('renew')

	//const form = client.state.currentRegistrationForm?.form

	const [showScore167, setShowScore167] = useState(false)
	const [downloading, setDownloading] = useState(false)
	const [cancelingAssociateChange, setCancelingAssociateChange] = useState(false)
	const [showAssociateChangeHistory, setShowAssociateChangeHistory] = useState(false)
	//console.log('model', model.registrationForm)

	const downloadFile = async (documentId=0, documentName='', reGenerate=false) => {
		const url = documentId
			? `documentUploads/download/${documentId}`
			:
			(
				reGenerate ?
					`DocumentUploads/downloadkit/${model.client.id}/true`
					:
					`DocumentUploads/downloadkit/${model.client.id}`
			)

		//alert(url)
		setDownloading(true)
		api
			.get(url, {
				responseType: 'blob'
			})
			.then(res => {

				const a = document.createElement('a');
				//alert(res.data.length)
				//console.log(res)
				const url = window.URL.createObjectURL(res.data);
				////const fileName = `FICHA_CADASTRAL_${model.client.name.toUpperCase().replace(/\s/g, '_')}.zip`
				//console.log('res.headers', res)
				let extension = res.headers['content-type'].split('/')[1]
				if (extension.indexOf('.sheet')>-1) {
					extension = extension.split('.')
					extension = extension[extension.length-1]
				}

				if (extension=='sheet')
					extension = 'xls'
				const fileName = (documentName || `FICHA_CADASTRAL_${model.client.name.toUpperCase().replace(/\s/g, '_')}`);
				//const fileName = res.headers['content-disposition'].split('filename=')[1].split(';')[0];
				a.href = url;
				a.download = `${fileName}.${extension}`;
				document.body.append(a);
				a.click();
				a.remove();
				window.URL.revokeObjectURL(url);

				client.actions.loadDocumentUploads({clientId:model.client.id})
			})
			.catch(async e => {
				let json=e.toString()
				try {
					json = JSON.parse(await e?.response?.data.text()) || e.toString();
				} catch(e) {}
				console.log('DOWNLOAD ERROR', json)
				alert(json.error || json || 'Network error')
			})
			.finally(() => {
				setDownloading(false)
			})

	}

	const handleCancelChangeAssociate = () => {
		if (!confirm(`Deseja mesmo cancelar o agendamento de troca de planejador ?`))
			return

		const clientId = client?.state?.currentRegistrationForm?.form.client?.id
		setCancelingAssociateChange(true)
		client.actions
			.cancelAssociateChange(clientId)
			.then(res => client.actions.incSaveCount())
			.catch(e => {
				alert('Ocorreu um erro ao tentar cancelar o agendamento.')
			})
			.finally(() => setCancelingAssociateChange(false))


	}
	const handleChangeAssociateHistory = () => {
		setShowAssociateChangeHistory(true)
	}

	return (<>
		<CustomModal
			isVisible={showAssociateChangeHistory}
			onClose={() => setShowAssociateChangeHistory(false)}
			modalStyle={{width:'80%'}}
		>
			<ChangeAssociateHistory onClose={() => setShowAssociateChangeHistory(false)}/>

		</CustomModal>
		<div className="client-info-component">
			<Row style={{maxWidth:'100%'}} className="p-0 m-0 pe-3 pt-1 ">
				<Col md={10} sm={10} xs={12}  className="p-0" style={{display:'flex', marginBottom:"0.5rem"}}>

					<div className="avatar-md avatar-client profile-user-wid mb-2 " style={{ margin: '1rem', marginLeft:'0.2em'}}>
						<div
							style={{
								backgroundColor:'var(--bs-fiduc-bg)',
								backgroundImage:`url(${uploadedFiles['client.personPhoto']?.preview || DefaultAvatar})`,
							}}
							className="img-thumbnail rounded-circle client-info-thumb-nail"
						/>
					</div>

					<div className="text-primary p-3 ps-0 pb-0 mb-0" style={{textAlign:'left'}}>
						<div style={{
							display:'flex'
						}}>
							<h5 className="font-weight-semibold pe-3 text-primary mb-0 font-family-title font-weight-700 font-size-em2">
								{model.client?.name}
							</h5>
							{model.client?.welfare=='1' &&  <BadgePrev />}
							{(
								model.client?.needFormFatcaEUA!=='renew' &&
								!model.client?.haveRenews &&
								model.fatca?.requestType=='fatca' &&
								!uploadedFiles['fatca.fatcaFormEua']) &&
								<BadgeFatcaEUA caption={`Aguardando Formulário FATCA - EUA`}/>
							}

							{ client.state?.currentRegistrationForm?.form?.registrationForm?.isApprovedRenew==RegFormApprovalRenewEnum.NOT_APPROVED &&
								<BadgeInvestmentBlocked className="bg-fiduc-danger" caption="Renovação reprovada"/>
							}
							{ client.state?.currentRegistrationForm?.form?.registrationForm?.isApprovedRenew==RegFormApprovalRenewEnum.IN_REVIEW &&
								<BadgeInvestmentBlocked className="bg-fiduc-blue" caption="Renovação em análise"/>
							}
							{ client.state?.currentRegistrationForm?.form?.registrationForm?.isApprovedRenew==RegFormApprovalRenewEnum.PENDING &&
								<BadgeInvestmentBlocked className="bg-fiduc-light-gray text-black " caption="Renovação pendente"/>
							}
							{(
								model.client?.needFormFatcaEUA=='renew' &&
								model.fatca?.requestType=='fatca'
							) &&
								<BadgeFatcaEUA caption={`Aguardando Formulário FATCA - EUA (renovação)`}/>
							}
							{!model.client?.phoneNumber && <BadgeNoPhoneNumber />}

						</div>
						<p className="text-muted mb-0 text-truncate font-weight-600">
							{model.client?.document && (
								<>

									<span className="font-weight-100 mb-0 " >
										{isCpfFormat(model.client?.document) ? 'CPF:' : 'CNPJ:'}
									</span>
									{" "}
									<span >
										{isCpfFormat(model.client?.document)
										? formatCpf(model.client?.document)
										: formatCnpj(model.client?.document)
									}
									</span>
								</>
							)}
						</p>
						<UserCanAll permission={"clients.edit"}>
						{client.state.registrationFormId ? (
							<div>
								<Switch
								  width={68}
								  height={25}
								  onCaption={"Editar"}
								  offCaption={"Editar"}
								  className={"mt-1 mb-0"}

								  onChange={() => {
									  setFormState({formId, key:'readOnly', value:!readOnly})
								  }}
								  checked={!readOnly}
								/>
							</div>
						) : null}
						</UserCanAll>
					</div>
				</Col>
				<Col md={2} sm={2} xs={12} className="p-0 pt-1 pe-1 m-0"
					 style={{
						border:'solid 0px orange',
						display:'flex',
						 flexDirection:'column',
					}}>

					{model.registrationForm?.score167?.score && (
						<>
						<span
							style={{
								display:'flex',
								justifyContent:'center',
								alignItems:'center',
								border:'solid 0px green',
								alignSelf:'flex-end',
								marginBottom: '1em',
							}}>
							<a
								id="popScoreButton"
								style={{
									cursor:'pointer',
									//width:'64px',
									border:'solid 0px var(--bs-blue)',
									display: 'flex',
									flexDirection: 'column',
									alignItems: 'center',
								}}
								href="#"
								onClick={(e) => {
									setShowScore167(!showScore167)
									e.preventDefault()
								}}


							>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>Score{" "}</div>
								<div
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<span
										className=" badge-soft-danger badge badge-secondary badge-pill text-black font-weight-semibold"
										style={{fontSize:'1.6180em', backgroundColor:'#f1efe9'}}
									>
										{model.registrationForm?.score167?.score}
									</span>
								</div>

							</a>
						</span>
						<UncontrolledPopover
							className="popover-score"
							trigger="focus"
							target="popScoreButton"
							placement="right"
							onMouseDown={(e) => e.preventDefault()}
							style={{
								border:'solid 1px #6e768126',
								borderRadius:'4px',
							}}
						>
						<PopoverHeader >Score</PopoverHeader>
							<PopoverBody

							>
								<ScoreDetails score167={model.registrationForm?.score167}/>
							</PopoverBody>
						</UncontrolledPopover>

						</>
					)}
				</Col>
			</Row>
			<Row >

				<Col sm="12" className="p-3 pb-3 pt-0">
					<div className="pt-2">
						<Row>
							<Col md={6} className="pe-md-0 ">
								<div className="d-flex flex-row pe-0 flex-row debug1 ">
									<div className="flex-grow-1 flex-fill d-flex flex-column debug2 ">
										<Field
											formId={formId}
											id="client.associateId"
											type="select2"
											caption="Planejador"
											noEmptyOption={true}
											listParams={true}
											defaultValue={1}
											listId="Associate"
										/>
									</div>
									{!!model?.client?.associateChangeHistory?.length &&
										<div className="flex-grow-0 d-flex align-items-center debug2">
											<DataActionButton
												// buttonStyle={{minWidth:'3rem'}}
												title={"Ver histórico de alterações de planejador"}
												buttonClass="btn btn-outline-primary ms-1"
												iconClass="fas fa-history p-1"
												// caption="Teste"
												onClick={() => handleChangeAssociateHistory()}
											/>
										</div>
									}
								</div>
							</Col>
							<Col md={4} className="ps-1">
								<Field
									formId={formId}
									id="client.riskProfileCategoryId"
									caption="Perfil"
									type="select2"
									errorMessage="Defina um perfil"
									listId={riskProfileListId}
									readOnly={true}
									options={[]}
								/>
							</Col>
							<Col md={2}
								 style={{
									display:'flex',
									padding:'0em .9em 0em 1em',
								 }}
							>
								<div
									className="client-documents"
								 style={{
									display: 'flex',
									 flex:1,
									justifyContent: 'flex-end',
									border:'solid 0px red',
								 }}
								>
								{model.client.id && (
									<>
										<Button
											placeHolder="Download"
											color="primary"
											loading={downloading}
											caption={"Download completo"}
											style={{flex:1, whiteSpace: 'nowrap', }}

											options={[
												...client.state.documentUploadsAvailable,
												{},
												{id:'all', caption:'Download completo'},
												//{id:'reRegenerate', caption:'Download gerado'},
											]}

											// defaultValue={currentStatus?.row?.status}
											selected={null}

											onChange={async option => {

											}}

											onClick={async option => {
												 await downloadFile(0, '', option?.id=='reRegenerate')
												// if (!option || option?.id=='all' || option?.id=='reRegenerate') {
												// 	 await downloadFile(0, '', option?.id=='reRegenerate')
												// } else {
												// 	await downloadFile(option?.id, option?.caption)
												// }
												// console.log('option', option)
											}}
										><span>Download</span></Button>

										{/*<Button*/}
										{/*	loading={downloading}*/}
										{/*	className="btn btn-primary btn-sm m-0 "*/}
										{/*	// disabled={currentScreen==0}*/}
										{/*	onClick={() => { downloadFile()} }*/}
										{/*>*/}
										{/*	{!downloading && <i className="bx bxs-download align-middle me-1"></i>}*/}
										{/*	Download*/}
										{/*</Button>*/}

										</>
								)}

								</div>
							</Col>
						</Row>
						{model.client.associateChange &&
						<Row>
							<Col md={6} className="text-align-right debug pe-0 pt-1 ">

								<div className="d-flex flex-row pe-0 flex-row debug1 rounded-fiduc badge-soft-gray text-black p-1">
									<div className="flex-grow-1 flex-fill d-flex align-items-center justify-content-end debug2 pe-2 font-size-rem-4">
										<div>
											Alteração de planejador agendada
											<br/> Planejador <strong>{
												model.client.associateChange?.associate?.shortName
												||
												model.client.associateChange?.associate?.name
											}</strong>
											{" "}em{" "}
											<strong>
												{moment().add(1, 'M').startOf('month').format('DD/MM/YYYY')}
											</strong>
										</div>
									</div>
									<div className="flex-grow-0 d-flex align-items-center debug2">
										<DataActionButton
											// buttonStyle={{minWidth:'3rem'}}
											loading={cancelingAssociateChange}
											title={"Cancelar Agendamento de alteração"}
											caption="Cancelar"
											buttonClass="btn btn-outline-danger btn-sm m-1"
											iconClass="fas fa-trash pe-1"
											onClick={() => handleCancelChangeAssociate()}
										/>
									</div>
								</div>

							</Col>
						</Row>
						}

					</div>
				</Col>
			</Row>
		</div>
		</>
	)
}
export default ClientInfo
