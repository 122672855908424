import React, { useState} from "react"

import InputMask from "react-input-mask";
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
import {isValidPhoneNumber} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

function FieldPhone(props) {

    const [value, setValue] = useState('')
    const [mask, setMask] = useState('(99) 99999-9999')

    const handleChange = (e) => {
        const value = e.target.value
        const newMask = value?.length >= 6 && (value?.[5] === "9" || value?.[5] === "_")
            ? "(99) 99999-9999"
            : "(99) 9999-9999"

        setMask(newMask)
        setValue(value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }


    //const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            mask={mask}
            maskChar=" "
            tag={[Input, InputMask]}
            {...rest}

            onChange={handleChange}
            autoComplete="disabled"
            type="text"
			required={props.required}
            // validate={{val:() => isValidPhoneNumber(value, props) }}
            validate={props.validation}
        />
    )
}

export default FieldPhone;
