import React, { useState } from "react"

import {
	TabPane, NavLink, NavItem, TabContent, Nav, Row, Col
} from "reactstrap"

// import { useOvermind } from '../../../overmind'

import classnames from "classnames"

const navLinkStyle = {
	position:'relative',
    cursor : 'pointer',
    height: '52px',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'center',
    textTransform:'uppercase',
    fontSize:'0.8em',
}

function TabPills(props) {

    const { children, tabs, hasContent = false, activeTab='', innerProps } = props

    const [active, setActive] = useState(activeTab)

    const toggle = (tab, tabIndex) => {
        setActive(tab)
        if (props.onChange)
            props.onChange(active, tabIndex)
    }

    //const screen = screenList[currentScreen]
    return (
        <>
			<Row>
				<Col md={1} className="d-none d-sm-block tab-pills tab-pills-desktop">
					<Nav className="flex-column vertical-icon " >
						{tabs && tabs.map((tab, tabIndex) => {
							const invalidTab = (props.getInvalidTab && props.getInvalidTab(tab.id))
							const enabledTab = (props.getEnabledTab && props.getEnabledTab(tab.id))

							return (
								<NavItem key={tab.id} className="text-center">
									<NavLink
										disabled={!enabledTab}
										style={{
											...navLinkStyle,
											color:enabledTab ? undefined : 'lightgray',
										}}
										className={
											classnames({
											  "mb-2": true,
												active: activeTab === tab.id,
											})
											+
											' '
										}
										onClick={() => {
											toggle(tab.id, tabIndex)
										}}
									>
										{invalidTab && (
											<div className="position-absolute left-0 translate-middle badge border border-light rounded-circle bg-danger tab-pills-badge no-pulsate">
												<span className="visually-hidden">unread messages</span>
											</div>
										)}

										<span className="d-block " >
											<i className={`${tab.icon} pe-0`}></i>
											<br/>
											{tab.caption || ''}

										</span>
									</NavLink>
								</NavItem>
							)
						})}
					</Nav>
				</Col>
				<Col className="md-11 " >
					<TabContent
						activeTab={activeTab}
						className={`p-0 text-muted ${hasContent ? '' : 'd-none'}`}
					>
						{tabs && tabs.map((tab, tabIndex) => {
							if (activeTab != tab.id) {
								return null
							}


							return (
								<TabPane tabId={tab.id} key={tab.id}>
								  <Row >
									<Col sm="12">
									{tab.content
										? <tab.content {...innerProps} tabId={tab.id}/>
										: null
									}
									</Col>
								  </Row>
								</TabPane>
							)
						})}
					</TabContent>
				</Col>
			</Row>
        </>
    )
}

export default TabPills;
