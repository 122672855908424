import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";
import {
	investmentOperationMapped, investmentStatusCanceledReasonOptions,
	investmentStatusMapped, investmentStatusOptions, investmentStatusPendingReasonOptions,

} from "../../../constants/formLists";

import {dateIsoToBr, formatDocument, MoneyFormat} from "../../../renders/utils";
import LoadingSection from "../../../components/Common/LoadingSection/LoadingSection";

import KeyValueInfo from "../../../components/Common/Tables/KeyValueInfo";

import FormWizard from "../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../components/Common/CustomForm/Utils/Validations";
import {sleep} from "../../../helpers/gestora/api";
import Button from "../../../components/Common/Button/Button"
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import {UserCan} from "../../../helpers/gestora/auth";
import TabDefault from "../../../components/Common/Tabs/TabDefault";
import InvestmentHistory from "../History";


const tabs = [
	{id:'info', caption:"Informações"},
	{id:'history', caption:"Histórico"},
]

function InvestmentsView(props) {

    const {
    	formId,
		viewId,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const investments = useOvermind('investments')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	const [investmentData, setInvestmentData] = useState(null)
	const [activeTab, setActiveTab] = useState('info');

	const [investorData, setInvestorData] = useState(null)
	const [fundData, setFundData] = useState(null)
	const [accountData, setAccountData] = useState(null)
	const [logData, setLogData] = useState(null)

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'edit.status',
					caption:'Status',
					type:'select2',
					// emptyOptionText : <b>Todos</b>,
					noEmptyOption:true,
					options:investmentStatusOptions,
					onChange : (values, setFieldState) => {

						const status=values?.edit?.status
						setFieldState('edit.canceledReason', 'visible', status=='canceled')
						setFieldState('edit.pendingReason', 'visible', status=='pending')

						//alert(values?.edit.status)
					},
					required:true,
					row:true,
					col:12
				},
				{
					id:'edit.canceledReason',
					caption:'Motivo',
					type:'select2',
					noEmptyOption:true,
					options:investmentStatusCanceledReasonOptions,
					onChange : (values, setFieldState) => {
						//alert(values?.canceledReason)
					},
					required:true,
					row:true,
					col:12
				},
				{
					id:'edit.pendingReason',
					caption:'Motivo',
					type:'select2',
					noEmptyOption:true,
					options:investmentStatusPendingReasonOptions,
					onChange : (values, setFieldState) => {
						//alert(values?.pendingReason)
					},
					required:true,
					row:true,
					col:12
				},
			],
		},
	], [viewId]);

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(statusScreens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:statusScreens}]

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

    useEffect(() => {
    	setError(false)
    	setSaving(false)
    	setSaved(false)
		setLoading(true)
		investments.actions.loadInvestment(viewId)
			.then(investment => {

				const fund = investment?.fundTypes
				const bank = investment?.bank_data

				setInvestmentData(investment)
				setInvestorData([
					{caption:'CPF', value:formatDocument(investment?.user_data?.document)},
					{caption:'Nome', value:investment?.user_data?.name},
					{caption:'Perfil', value:investment?.user_data?.riskProfileCategory?.name},
					{caption:'Telefone', value:investment?.user_data?.phoneNumber},
					{caption:'E-mail', value:investment?.user_data?.email},
					{caption:'Status da movimentação', value:investmentStatusMapped[investment.status].caption},
					{caption:'Nome do Planejador', value:investment?.associate_data?.name},
					{caption:'E-mail do Planejador', value:investment?.associate_data?.email},
					{caption:'Tel/Whatsapp Planejador', value:investment?.associate_data?.phone},
				])

				const fData = [
					{caption:'Fundo', value:fund?.name},
					{caption:'Conta', value:`ag: ${fund?.agency} cc: ${fund?.account}-${fund?.digit}`},
					{caption:'Valor do investimento', value:MoneyFormat(investment?.value, 'R$ ', 2)},
					{caption:'Data da solicitação', value:dateIsoToBr(investment.created_at, true)},
				]

				if (['created', 'canceled'].includes(investment?.status)) {
					fData.push({caption:'Data da realização', value:dateIsoToBr(investment.processed_at, true)},)
				}

				if (['created'].includes(investment?.status) && ['rescue_parcial', 'rescue_total'].includes(investment?.operation) && investment.payment_at) {
					fData.push({caption:'Data de pagamento', value:dateIsoToBr(investment.payment_at, false)},)
				}

				setFundData(fData)

				setAccountData([
					{caption:'Banco', value:bank?.bank?.name},
					{caption:'Conta', value:`ag: ${bank?.agency} cc: ${bank?.account}-${bank?.digit}`},
					{caption:'Nome do titular', value:bank?.holderName},
					{caption:'Documento do titular', value:formatDocument(bank?.holderCpf)},
				])

				setLogData([
					{caption:'IP', value:investment?.ip},
					{caption:'Sistema Operacional', value:investment?.os},
				])

				///alert(investment.status)
				//setModelValueByPath({formId, path:'edit.status', value:investment.status})
				setModelValueByPath({formId, path:'edit.status', value:investment.status})
				setModelValueByPath({formId, path:'edit.pendingReason', value:investment.pendingReason || ''})
				setModelValueByPath({formId, path:'edit.canceledReason', value:investment.canceledReason || ''})
				incFormValidatedCount({formId})

			})
			.catch(e => {
				setError(e.response?.data || e.toString())
			})
			.finally(() => {
				setLoading(false)
			})

    }, [viewId])

	const handleConfirm = async (e) => {

		const values = _.cloneDeep(model)

    	setSaving(true)

		//console.log(values)

		//const createDate = `${dateBrToISO(values.changeStatus.date.trim())} ${values.changeStatus.time.trim()}`
		//alert(createDate)

    	investments.actions.saveInvestmentStatus({
				formId,
				id:viewId,
				...values.edit,
			})
			// .then(sleep(1000))
			.then(() => {
				setSaved(true)
				onConfirm()
				setTimeout(() => {
					setSaving(false)
				}, 2000)
			})
			.catch((e) => {
				setError(`Ocorreu um erro ao salvar: ${e.toString()}`)
				setSaving(false)
				setSaved(false)
			})

		//e.preventDefault()
    	//onConfirm()
	}


	const handleCancel = () => {
    	onCancel()
	}

	if (!viewId)
		return null

	const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}

    return (
        <>
			<SweetAlert
				// title={investmentData && !error ?  investmentOperationMapped[investmentData.operation].caption : null}
				// warning={!error && !saving}
				customClass={'fiduc-modal pt-1'}
				style={{
					overflow:'auto',
					// marginTop:'50%',
					width:'80%',
					maxHeight:'100%',
					minHeight:'50%',

				}}
				openAnim={{ name: 'opacityIn', duration: 500 }}
				//closeAnim={{ name: 'slideOut', duration: 500 }}

				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error}>
						<Row className={"mb-0 "}>
							<Col md={12} className=" p-0 m-0">
								<TabDefault
									tabs={tabs}
									activeTab={activeTab}
									onChange={tabId => setActiveTab(tabId)}
								/>
							</Col>
						</Row>
						{activeTab === 'history'
							? <><InvestmentHistory investmentId={investmentData?.id}/></>
							: <>
								<Row className="pr-4 ps-4 search-page-container pt-2">
									<Col md={UserCan('investments.edit') ? 6 : 12} className="">


										<KeyValueInfo
											className="pt-0 m-0"
											title="Investidor"
											data={investorData}
										/>
										<br />

										<KeyValueInfo
											className="pt-0 m-0"
											title={investmentData?.operation=='apply' ? "Fundo a ser investido" : "Fundo a ser resgatado"}
											data={fundData}
										/>
										<br />

										<KeyValueInfo
											className="pt-0 m-0"
											title={investmentData?.operation=='apply' ? "Conta origem do recurso" : "Conta destino do recurso"}
											data={accountData}
										/>
										<br />

										<KeyValueInfo
											className="pt-0 m-0"
											title={"Log"}
											data={logData}
										/>

										<br />
									</Col>

									<UserCanAll permission="investments.edit">
										<Col md={6} className="pt-2 ">
											<Row className="sticky-top">
												<Row >
													<Col md={12} className="d-flex pb-3">
														<h7 className="text-primary font-family-title font-weight-700 font-size-em1 text-align-left">
															{operation.caption}
														</h7>
													</Col>
												</Row>
												<Row >
													<Col md={12} className="w-100 d-block align-items-start">
														<FormWizard
															screenId={formId}
															screens={statusScreens}
															formId={formId}
															// OnInputValueChange={handleInputValueChange}
															tabId={formId}
														/>
													</Col>
												</Row>
												<Row >
													<Col md={12} className="d-flex align-items-center justify-content-center">
														<Button
															autoFocus
															className="btn btn-outline-danger"
															onClick={handleCancel}
															style={{margin:'1em'}}
														>
															Fechar
														</Button>
														<Button
															loading={saving}
															disabled={!!formValidationResult?.first}
															colorIcon={true}
															className="btn btn-primary "
															// disabled={currentScreen==0}
															style={{margin:'1em'}}
															onClick={handleConfirm}
														>
															Salvar status
														</Button>

													</Col>
												</Row>
											</Row>
										</Col>
									</UserCanAll>
								</Row>
							</>
						}
					</LoadingSection>
				)}

			</SweetAlert>

        </>
    )
}

export default InvestmentsView;
