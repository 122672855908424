import React, { useState } from "react"

import {
    TabPane, NavLink, NavItem, TabContent, Nav
} from "reactstrap"

// import { useOvermind } from '../../../overmind'

import classnames from "classnames"
import ButtonPrimary from "../../Button/ButtonPrimary";
import Button from "../../Button/Button";
// import ButtonPrimary from "../../Button/ButtonPrimary";

function TabButtons({ tabs, activeTab='', ...props }) {

    const [active, setActive] = useState(activeTab)

    const toggle = (tab, tabIndex) => {
        setActive(tab)
        if (props.onChange)
            props.onChange(tab, tabIndex)
    }

    //const screen = screenList[currentScreen]

{/*<div class="btn-group mt-4 mt-md-0" role="group" aria-label="Basic example">*/}


    return (
        <>
            <div className="d-flex flex-row justify-content-around btn-group-custom " role="group">
                {tabs && tabs.map((tab, tabIndex) => {

                    return (

						<Button
							key={tab.id}
							disabled={false}
							colorIcon={true}
							title={tab.title}

							className={`btn ${active==tab.id ? "btn-primary" : "btn-outline-primary"} m-0 btn-sm `}
							// disabled={currentScreen==0}
							style={{
								fontWeight: active==tab.id ? '500' : '500',
							}}
							height={'1rem'}
							onClick={() => {
								toggle(tab.id, tabIndex)
							}}
						>
							<i className={`m-0 fa ${tab.iconClass} align-middle font-size-rem0`}></i>
						</Button>

                    )
                })}
            </div>
        </>
    )
}

export default TabButtons;
