import React, {createRef, useEffect, useState} from "react"

import {
    Col,
    Row,
    Button, Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";

function SaveModal(props) {

    const {
    	formId,
		onConfirm = () => {},
		onCancel = () => {},
	} = props

    // const {
    //     state   : {
    //         [formId]:{currentScreen, model, readOnly}
    //     },
    //     actions : {
    //         setFormState, setCurrentScreen, setFormModel, setTotalScreens, setTotalGroups
    //     }
    // } = useOvermind('forms')

	const client = useOvermind('clients')
	const [saving, setSaving] = useState(false)
	const [saved, setSaved] = useState(false)
	const [error, setError] = useState(false)

    useEffect(() => {
		//if (cancelButtonRef) cancelButtonRef.focus()
    }, [])

	const handleConfirm = async (e) => {


    	setError(false)
    	setSaving(true)
    	client.actions.saveRegistrationForm({formId})
			.then(() => {
				setSaved(true)
				setTimeout(() => {
					onConfirm()
					setSaving(false)
					setSaved(false)
				}, 2000)
			})
			.catch(() => {
				setError(true)
				setSaving(false)
				setSaved(false)
			})
			.finally(() => {
				// setSaving(false)
				// setSaved(false)
			})

		//e.preventDefault()
    	//onConfirm()
	}


	const handleCancel = () => {
    	onCancel()
	}


    return (
        <>
			<SweetAlert
				title={
					(saving && !error)
						? (saved
							? "Salvo!"
							: "Salvando..."
						)
						: error
							? "Erro"
							: "Confirmação"
					}
				warning={!error && !saving}
				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText="Cancelar"
				confirmBtnText="Salvar"
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				onConfirm={handleConfirm}
				onCancel={handleCancel}
			>
				{error ? (
						<>
						{client.state.savingText && <>{client.state.savingText}<br/></>}
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="outline-danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					saving ? (
						<>
						{client.state.savingText && <><br/>{client.state.savingText}<br/></>}
						</>
					) : (
						<>
							Deseja realmente salvar a ficha cadastral?
							<br />
							<br />
							<Row>
								<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
									<Button
										color="outline-danger"
										onClick={handleCancel}
										style={{margin:'1em'}}
									>
										Cancelar
									</Button>
									<Button
										autoFocus
										color="primary"
										onClick={handleConfirm}
										style={{margin:'1em'}}
									>
										Salvar
									</Button>
								</Col>
							</Row>
						</>
					)
				)}

			</SweetAlert>

        </>
    )
}

export default SaveModal;
