import React from "react"
import RegistrationFormSection from "../RegistrationFormSection";

import {kycScreens} from "../Configuration/KycDataConfig";

function KYCData(props) {
    return <RegistrationFormSection screens={kycScreens} {...props} />
}

export default KYCData;
