import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useOvermind } from '../../../../overmind'
import SweetAlert from "react-bootstrap-sweetalert";

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../../components/Common/CustomForm/Utils/Validations";
import Button from "../../../../components/Common/Button/Button"
import {yesNoOptions} from "../../../../constants/formLists";
import CustomForm from "../../CustomForm/Form";


function ModalForm({
    	formId = 'ModalForm',
		data,
		isVisible,
		title='',
		cancelBtnCaption="Cancelar",
		confirmBtnCaption="Salvar",
		confirmMessage,
		topContent,
		haveCancelBtn=true,
		saving=false,
		haveConfirmBtn=true,
		modalStyle = {},
		fields,
		closeOnClickOutside = true,
		onConfirm = () => {},
		onCancel = () => {},
		...props
}) {

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setModelValueByPath, setFormModel, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	const [saved, setSaved] = useState(false)
	const [loading, setLoading] = useState(false)
	const [error, setError] = useState(false)
	//const [data, setData] = useState(null)

    useEffect(() => {
    	if (!data)
    		return

    	setError(false)
    	setSaved(false)

		setLoading(true)
		setFormModel({formId, model:{...data}})
		incFormValidatedCount({formId})
		setTimeout(() => setLoading(false), 1)
    }, [data])

	const handleConfirm = async (e) => {
    	if (confirmMessage && !confirm(confirmMessage))
    		return

		const values = _.cloneDeep(model)
		onConfirm(values)
	}

	const statusScreens = useMemo(() => [
		{
			id:formId,
			fields : fields,
		},
	], [fields]);

	const setInputValue = (fieldName, value) => {
    	setModelValueByPath({formId, path:fieldName, value:value})
	}

    const setFieldStateFunc = async (fieldName, key, value) => {
        await setFieldState({formId, fieldName, key, value})
    }

    //Set field state visibility at start
	useEffect(() => {
		const cloneModel = _.cloneDeep(model)
		validateFieldStates(statusScreens, cloneModel, setFieldStateFunc, setInputValue)
	}, [formValidatedCount])

	useEffect(() => {
		incFormValidatedCount({formId})
	}, [fields])

	//Validate form on change
	useEffect(() => {
		const tabsResult = {}
		let first = null

		const cloneModel = _.cloneDeep(model)

		//console.log('values', values)
		//console.log('values', values)
		const TabList = [{ id:formId, screens:statusScreens}]

		for (const tab of TabList) {
			if (!tab.screens)
				continue

			const screenResult = validateFormScreens(tab.screens, cloneModel, fieldState)

			if (!first)
				first = screenResult.first

			if (screenResult.first)
			tabsResult[tab.id] = screenResult.errors

		}
		const validationResult = {first, errors:tabsResult}
		setFormValidationResult({formId, formValidationResult:validationResult})
		// console.log('validationResult', validationResult)
		//console.log('formValidatedCount', formValidatedCount)
	}, [formValidatedCount])

	const handleCancel = () => {
    	onCancel()
	}

	const handleInputValueChange = () => {
    	console.log('changed')
	}

	//const operation = investmentOperationMapped[investmentData?.operation || -1] ? investmentOperationMapped[investmentData.operation] : {caption:'Operação Inválida'}
	if (!isVisible) return null

    return (
        <>
			<SweetAlert
				title={title ?
					<h7 className="text-primary font-weight-700 font-family-title font-size-rem3 text-align-left">
						{title}
					</h7> : undefined
				}

				// warning={!error && !saving}

				style={{
					overflow:'auto',
					// marginTop:'0px',
					width:'80%',
					maxHeight:'100%',
					// minHeight:'300px',
					justifyContent:'flex-start',
					...modalStyle
				}}
				openAnim={{ name: 'opacityIn', duration: 500 }}
				//closeAnim={{ name: 'slideOut', duration: 500 }}

				success={!error && saved}
				error={error}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				cancelBtnText={cancelBtnCaption}
				confirmBtnText={confirmBtnCaption}
				confirmBtnBsStyle="success"
				cancelBtnBsStyle="danger"
				// onConfirm={handleConfirm}
				onCancel={handleCancel}
				closeOnClickOutside={closeOnClickOutside}

			>
				{error ? (
						<>
						{error}
						<br/>
						<Row>
							<Col md={12} style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
								<Button
									autoFocus
									color="danger"
									onClick={handleCancel}
									style={{margin:'1em'}}
								>
									Fechar
								</Button>
							</Col>
						</Row>
					</>
				) : (
					<LoadingSection loading={loading} error={error}>
						{topContent}
						<Row className="pr-4 ps-4">
							<Col md={12} className="pt-2 ">
								<Row >
								<Col md={12} className="w-100 d-block align-items-start">
									<CustomForm
										className="needs-validation"
										formId={formId}
										model={model}
										validateOnLoad={false}
									>
										<FormWizard
											data={data}
											screenId={formId}
											screens={statusScreens}
											formId={formId}
											validationInRealTime={true}
											 OnInputValueChange={handleInputValueChange}
											tabId={formId}
										/>
									</CustomForm>
								</Col>
								</Row>
								<Row >
									<Col md={12} className="d-flex align-items-center justify-content-center">
										{haveCancelBtn &&
											<Button
												autoFocus
												className="btn btn-outline-danger"
												onClick={handleCancel}
												style={{margin:'1em'}}
											>
												{cancelBtnCaption}
											</Button>
										}
										{haveConfirmBtn &&
										<Button
											loading={saving}
											disabled={!!formValidationResult?.first || saving}
											colorIcon={true}
											className="btn btn-primary "
											// disabled={currentScreen==0}
											style={{margin:'1em'}}
											onClick={handleConfirm}>
											{confirmBtnCaption}
										</Button>
										}

									</Col>
								</Row>
							</Col>
						</Row>
					</LoadingSection>
				)}

			</SweetAlert>

        </>
    )
}

export default ModalForm;
