import React, {useEffect, useMemo, useState} from "react"

import {
	Col,
	Row,
	Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav,
	Collapse,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import {useOvermind, useOvermindForm} from "../../../../../overmind";

import LoadingSection from "../../../../../components/Common/LoadingSection/LoadingSection";
import FormWizard from "../../../../../components/Common/CustomForm/FormWizard";
import CustomForm from "../../../../../components/Common/CustomForm/Form";
import Button from "../../../../../components/Common/Button/Button";
import {validateFieldStates, validateFormScreens} from "../../../../../components/Common/CustomForm/Utils/Validations";
import {clientStatusMapped, clientStatusOptions, deviceTypesOptions} from "../../../../../constants/formLists";
import {UserCan} from "../../../../../helpers/gestora/auth";
import UserCanAll from "../../../../../components/Common/UserCan/UserCanAll";


function ClientStatus(props) {

	const formId = 'ClientStatusForm'

	const client = useOvermind('clients')
	// // const security = useOvermind('security')
	// const register = useOvermind('register')
	const form = useOvermindForm(formId)
	const clientForm = useOvermindForm('regForm')

	const [loading, setLoading] = useState(false)
	const [submitting, setSubmitting] = useState(false)

	const formScreens = useMemo(() => [
		{
			id:formId,
			fields : [
				{
					id:'title',
					type:'title',
					caption:"Ativar/Desativar Cliente"
				},
				{
					id:'status',
					caption:'Status',
					type:'select2',
					required:true,
					noEmptyOption:true,
					col:6,
					readOnly:!UserCan('clientStatus.edit'),
					options: clientStatusOptions
				},

			],
		},
	], [])

	useEffect(() => {
		setLoading(true)
		form.actions.setModelValueByPath({formId, path:'status', value:clientForm.state.model.client?.status})
		setTimeout(() => setLoading(false))
	}, [])

	const handleSubmit = () => {
		const {status} = _.cloneDeep(form.state.model)


		if (!confirm(`Tem certeza que deseja alterar o status do cliente para "${clientStatusMapped[status]?.caption}"? `))
			return

		setSubmitting(true)
		client.actions
			.updateClientStatus({
				clientId:client?.state?.currentRegistrationForm?.form?.client?.id,
				status
			})
			.then(data => {
				clientForm.actions.setModelValueByPath({formId:'regForm', path:'client.status', value:status.toString()})
				alert(`Status alterado!`)
			})
			.catch(e => {
				alert(`Ocorreu um erro ao salvar os dados: ` + e?.toString())
			})
			.finally(() => setSubmitting(false))
	}

	useEffect(() => {}, [])

	return (
		<LoadingSection loading={loading}>
			<CustomForm
				className="needs-validation "
				formId={formId}
				model={form.state.model}

				screens={formScreens}
				// onSubmit={handleSubmit}
			>
			<Row >
				<Col md={12} className="d-flex align-items-center justify-content-center">
					<FormWizard
						screenId={formId}
						screens={formScreens}
						formId={formId}
						tabId={formId}
					/>
				</Col>
			</Row>
			<UserCanAll permission={'clientStatus.edit'}>
			<Row >
				<Col md={12} className="d-flex align-items-start">
					<Button
						loading={submitting}
						className="btn btn-primary"
						onClick={handleSubmit}
						style={{margin:'1em'}}
					>
						Salvar status
					</Button>
				</Col>
			</Row>
			</UserCanAll>
			</CustomForm>
		</LoadingSection>
	)
}

export default ClientStatus;
