import { merge, namespaced } from 'overmind/config'
import { state } from './state'

import {
	createStateHook,
	createActionsHook,
	createEffectsHook,
	createReactionHook,
} from 'overmind-react'

import * as app from './app'
import * as forms from './forms'
import * as login from './login'
import * as lists from './lists'
import * as clients from './clients'
import * as investments from './investments'
import * as fundTypes from './fundTypes'
import * as renew from './renew'
import * as banks from './banks'
import * as riskProfiles from './riskProfiles'
import * as suitability from './suitability'
import * as users from './users'
import * as profiles from './profiles'
import * as mailsig from './mailsig'
import * as security from './security'

export const config = merge(
	{
		state
	},
	namespaced({
		app,
		forms,
		login,
		lists,
		clients,
		investments,
		fundTypes,
		banks,
		riskProfiles,
		suitability,
		renew,
		users,
		profiles,
		mailsig,
		security,
	})
)

export const useAppState = createStateHook()
export const useActions = createActionsHook()
export const useEffects = createEffectsHook()
export const useReaction = createReactionHook()


export function useOvermind (namespace) {
	return {
		state     : namespace ? useAppState()[namespace] : useAppState(),
		actions   : namespace ? useActions()[namespace] : useActions(),
		effects   : namespace ? useEffects()[namespace] : useEffects(),
		reactions : namespace ? useReaction()[namespace] : useReaction(),
	}
}


export function useOvermindForm (formId) {
	const overmindObj = useOvermind('forms')
	return {
		state : overmindObj?.state[formId],
		actions : overmindObj?.actions
	}
}


