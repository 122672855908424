const _ = require('lodash')
import api from "../../helpers/gestora/api"
import * as Auth  from "../../helpers/gestora/auth"

function sleeper(ms) {
  return function(x) {
    return new Promise(resolve => setTimeout(() => resolve(x), ms));
  };
}

export const login = ( { state }, {document, password} ) => {

    state.login.authenticating = true
    return api
        .post('auth/login/2', {
            document : document?.replace(/[^\d]+/g,'') || '',
            password
        })
        ///.then(sleeper(2000))
        .then( res => {
            //Auth.Login(res?.data)
            // console.log('Login successfully: ', res?.data)
            return res
        })
        .finally(() => {
            state.login.authenticating = false
        })
}

export const mfa = ( { state }, {document, mfa} ) => {

    return api
        .post('auth/login/2/mfa', {
            document : document?.replace(/[^\d]+/g,'') || '',
            mfa
        })
        ///.then(sleeper(2000))
        .then( res => {
            Auth.Login(res?.data)
            // console.log('Login successfully: ', res?.data)
            return res
        })
        .finally(() => {
        })
}

