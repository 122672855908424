import React from 'react'
import {dateBrToISO} from "../../../../renders/utils";
import moment from "moment"
import {isValidDate} from "../Utils/Validations";
moment.locale('pt-BR')

const BadgeIncapable = (props) => {
	const date   = props?.data ? dateBrToISO(props?.data) : null

	if (!date)
		return null

	const age = moment(moment().format('YYYY-MM-DD')).diff(date, 'years');

	if (age<18)
		return  <span className="badge bg-dark text-uppercase badge-renew-status float-end p-2 mt-2 ">Menor de idade</span>


	return null
}

export default BadgeIncapable
