import React, {useEffect, useMemo, useState} from "react"

import {
	Col,
	Row,
	Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav,
	Collapse,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import Button from "../../../../components/Common/Button/Button";

import { useOvermind } from "../../../../overmind";

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";
import api, {sleep} from "../../../../helpers/gestora/api";
import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {yesNoOptions} from "../../../../constants/formLists";


function ClientSuitability(props) {

	const formId = 'regForm'

	const client = useOvermind('clients')

	const [loading, setLoading] = useState(true)
	const [suitabilityFields, setSuitabilityFields] = useState([])

	const {
		state   : {
			[formId]:{model}
		},
		actions : {
		}
	} = useOvermind('forms')

	useEffect(() => {
		const questions = client.state.suitabilityQuestions
		if (!questions) {
			setLoading(false)
			return
		}

		const fields = [
			{id:'suitability.title', caption:questions.name, type:'title'},
		]

		for (const question of client.state.suitabilityQuestions.asks) {
			fields.push({
				id:`suitability.answers.${question.id}`,
				caption:question.title,
				infoTop    : question.ask,
				type    : 'radiogroup',
				options:question?.answers?.reduce((map, obj) => {
					map.push({ id: obj.id, caption: obj.answer })
					return map
				}, []),
				required:false,
		        vertical:true,
				row:true,
				col:12,
			})
		}

		//console.log('sss', client.state.suitabilityQuestions)

		setSuitabilityFields([
			{
				id:formId,
				fields : fields,
			},
		])
		setLoading(false)
	}, [client.state.suitabilityQuestions]);

	return (
		<LoadingSection loading={loading}>
			{client.state.suitabilityQuestions
				? <FormWizard
					screenId={formId}
					screens={suitabilityFields}
					formId={formId}
					tabId={formId}
				/>
				: <Row>
					<Col md={12}  className="pt-4">Nenhuma pesquisa respondida</Col>
				</Row>
			}
		</LoadingSection>
	)
}

export default ClientSuitability;
