import React from "react"
//import {Spinner} from "reactstrap";
import {default as ReactSwitch} from "react-switch"

const OffSymbol = (props) => {
	return (
		<div className="input-switch-off">
			{" "}
			<span style={{color:'black'}}>{props.caption || 'Não'}</span>
		</div>
	)
}

const OnSymbol = (props) => {
	return (
		<div className="input-switch-on">
			{" "}
			{props.caption || 'Sim'}
		</div>
	)
}


function Switch( {children, ...props} ) {

	return (
		<div className={props.className}>
			<ReactSwitch
			  uncheckedIcon={<OffSymbol caption={props.offCaption}/>}
			  checkedIcon={<OnSymbol caption={props.onCaption} />}
			  onColor="#FA5420"
			  offColor="#f1efe9"
			  activeBoxShadow={"rgb(250, 84, 32) 0px 0px 2px 3px"}
			  width={props.width}
			  height={props.height}
			  onChange={props.onChange}
			  checked={props.checked}
			/>
		</div>
	)
}

export default Switch;
