import React, {useEffect, useState} from "react"

import KYCInfo from "../components/KYCInfo";
import DefaultTable from "../../../../../components/Common/Tables/DefaultTable/DefaultTable";

function KYCEconomicRelation(
{
	KYCData,
	...props
}) {


	const [info, setInfo] = useState(null)
	const [relationData, setRelationData] = useState(null)

	useEffect(() => {
		// console.log('KYCData BASIC', KYCData)
		const info = KYCData[0]
		const bRelations = info?.businessRelationships

		setInfo([
			{caption:'Total de relacionamentos', value:bRelations?.totalRelationships},
			{caption:'Total de parceiros', value:bRelations?.totalPartners},
		])
		setRelationData(bRelations?.businessRelationshipList || [])

	}, [KYCData])

	return (
		<>
			<KYCInfo data={info} />
			<br />
			<DefaultTable
				columns={[
					{id:'relatedEntityName', caption:'Nome'},
					{id:'relatedEntityTaxIdNumber', caption:'Documento'},
					{id:'relatedEntityTaxIdType', caption:'Tipo'},
					{id:'relationshipType', caption:'Relacionamento'},
				]}
				data={relationData}
			/>
		</>
	)
}

export default KYCEconomicRelation;
