import api, {sleep} from "../../helpers/gestora/api";
import {fiducUrl} from "../../helpers/gestora/configuration";


export const loadById = async ( { state, actions }, {formId, id} ) => {

	//const stateFormModel = state.forms[formId].model
	//await actions.forms.resetForm({formId})
	//await actions.forms.resetForm({formId})

	return api
		.get(`clientHistory/${id}`)
		//.then(sleep(1000))
		.then(async res => {

			if (res?.data?.attachment) {
				await actions.forms.setUploadedFiles({formId, fieldName:'renewAttachment.attachment', fileResult:{
					remoteUrl : `${fiducUrl}/clientHistory/attachment/${id}`
				}})
			}

			return res.data
		})
}

export const loadNeedFormFatcaEUA = async ( { state, actions }, {clientId} ) => {


	return api
		.get(`renew/list/${clientId}`)
		//.then(sleep(2000))
		.then(res => {
			const result={data:res.data}
			// console.log('loadNeedFormFatcaEUA', res.data)
			const renews = res.data.filter((item) => item.type=='renew')

			result.haveRenews = renews.length>0

			if (result.haveRenews) {

				if (!renews[0].attachment && renews[0].needAttachment)
					result.needFormFatcaEUA = 'renew'
			}

			return result

			// console.log('renews', renews)

		})
}

export const save = async ( { state, actions }, {formId, ...params} ) => {

	const changedFiles = actions.forms.getChangedFiles({formId})
	const attachment = changedFiles['renewAttachment.attachment']

	if (attachment) {
		params.data.attachment = attachment.deleted
			? null
			: attachment.base64String
	}


	return api
		.post(`clientHistory/${params.id}`, params.data)
		//.then(sleep(1000))
		.then(async res => {
			return res.data
		})
}

export const redoRenew = async ( { state, actions }, {clientId, clientHistoryId} ) => {

	return api
		.get(`renew/redo/${clientId}/${clientHistoryId}`)
		.then(sleep(1000))
		.then(async res => {
			return res.data
		})
}

