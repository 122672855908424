import React from "react"
import RegistrationFormSection from "../RegistrationFormSection";
import {statusScreens} from "../Configuration/StatusConfig";


function StatusData(props) {
    return <RegistrationFormSection screens={statusScreens} {...props} />
}

export default StatusData;
