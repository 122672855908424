import React, {useState} from "react"

import InputMask from "react-input-mask";
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
import {isValidNumber} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";

function FieldNumber(props) {

    const [value, setValue] = useState('')
    const handleChange = (e) => {
        setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            mask={"99999999999999999999999999999999999"}
            maskChar=""
            tag={[Input, InputMask]}
            {...rest}
            autoComplete="disabled"
            type="text"
            onChange={handleChange}
			required={props.required}
            // validate={{val:() => isValidNumber(value, props) }}
            validate={props.validation}
			maxlength={props.maxLength || undefined}
        />
    )
}

export default FieldNumber;
