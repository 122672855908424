import React, {useEffect, useState} from "react"

import {
	Col, Container,
	Row,
	Table,
} from "reactstrap"

function DefaultBox(
{
	title = '',
	className = '',
	actions = null,
	alignActionRight = true,
	containerStyle={},
	...props
}) {

	const classAlignRight = alignActionRight && "text-align-right xborder border-dark justify-content-end flex-row d-flex"

	return (
		<Container className={`default-box d-flex flex-column border border-dark rounded bg-white ${className}`} fluid  style={containerStyle}>
			<Row >
				<Col >{typeof(title)=='string' ? <h5 className="pt-1 font-weight-700 font-family-title font-size-em2">{title}</h5> : <title />}</Col>
				<Col className={classAlignRight}>{actions}</Col>
			</Row>
			<Row className="p-2">
				<Col >{props.children}</Col>
			</Row>
		</Container>
	)
}

export default DefaultBox;
