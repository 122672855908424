import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import {
    Col,
    Row,
} from "reactstrap"

import { useLocation, useHistory, useParams } from "react-router-dom"

import { useOvermind } from '../../../../overmind'

import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";

import FormWizard from "../../../../components/Common/CustomForm/FormWizard";
import {validateFieldStates, validateFormScreens} from "../../../../components/Common/CustomForm/Utils/Validations";
import Button from "../../../../components/Common/Button/Button"

import DefaultPage from "../../../../components/Common/DefaultPage/DefaultPage";
import DefaultPageHeader from "../../../../components/Common/DefaultPage/DefaultPageHeader";
import CustomForm from "../../../../components/Common/CustomForm/Form";

import SuitabilityQuestions from "./SuitabilityQuestions";
import {parsePercent} from "../../../../helpers/parser_helper";
import ModalForm from "../../../../components/Common/Modals/ModalForm";
import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";
import {DataActionButton} from "../../../../components/Common/Tables/Datatable/DataTypes";


const answerFields = [
	{
		id:'title',
		caption:'Resposta',
		type:'title',
		required:true,
		col:12
	},
	{
		id:'answer',
		caption:'Descrição',
		type:'textarea',
		required:true,
		row:true,
		col:12
	},
	{
		id:'score',
		caption:'Score',
		type:'number',
		required:true,
		row:true,
		col:12
	},
]

function SuitabilityRenderAnswers(props) {

    const {
		answers,
		formId,
		questionIndex,
		hasRelation,
	} = props

	const suitability = useOvermind('suitability')
	const [viewId, setViewId] = useState(null)
	const [answerData, setAnswerData] = useState(null)

	const handleNew = async  () => {
		setViewId('')
		setAnswerData({})
	}

	const handleEdit = async  (row, index) => {
		setViewId(index)
		setAnswerData(suitability.state.questions[questionIndex].answers[index])
	}

	const handleDelete = async  (row, index) => {
		suitability.actions.deleteAnswerByIndex({questionIndex, index})
	}

	return <>

		<ModalForm
			formId={'ModalFormAnswers'}
			data={answerData}
			isVisible={!!answerData}
			fields={answerFields}
			modalStyle={{
				maxWidth : '50%',
			}}
			onCancel={() => {
				setViewId(null)
				setAnswerData(null)
			}}
			onConfirm={(values) => {
				console.log('values', values)
				if (viewId!=='') {
					suitability.actions.replaceAnswerByIndex({index:viewId, questionIndex, answer:values})
				} else if (!viewId) {
					suitability.actions.addAnswer({formId, questionIndex, answer:values})
				}
				setViewId(null)
				setAnswerData(null)
			}}
		/>
		{answers.length===0 ? null :
			<DefaultTable
				condensed={true}
				className="pt-0 m-0 bg-fiduc-subtable rounded-2 text-white table-bordered  "
				columns={[
					{id:'answer', caption:'Descrição', width:'50%', formatter:(row) => row.answer},
					{id:'score', caption:'Score', width:'30%', formatter:(row) => row.score},

					{id:'actions', width:'20%', caption:'',
						formatter : function Actions(item, index) {
							if (hasRelation)
								return null

							return <div className="d-flex flex-row">
								<DataActionButton
									disabled={hasRelation}
									title="Editar resposta"
									buttonClass="btn btn-outline-primary btn-sm me-1"
									iconClass="fas fa-pen m-0"
									type="button"
									onClick={() => handleEdit(item, index)}
								/>
								<DataActionButton
									title="Remover resposta"
									disabled={hasRelation}
									buttonClass="btn btn-outline-danger btn-sm"
									iconClass="fas fa-trash m-0"
									onClick={item => handleDelete(item, index)}
									type="button"
								/>
							</div>
						}
					},
				]}
				data={answers}
			/>
		}
		{hasRelation ? null : <>
			<Row >
				<Col md={12}>

					<>
						<div className="d-flex flex-row align-items-end justify-content-end">
							<Button
									className={`btn btn-outline-primary p-0 ${answers.length===0 ? "" : "mt-2"} btn-sm flex-grow-0`}
									style={{width:'200px'}}
									onClick={handleNew}
								>
									<i className="fas fa-plus m-1" />
									Adicionar Resposta
								</Button>
						</div>
					</>

				</Col>

			</Row>
		</>}

	</>

}

export default SuitabilityRenderAnswers;
