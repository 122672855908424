import {defaultRegistrationFormModel} from "../../pages/Clients/RegistrationForm/RegistrationForm";
import lStorage from "../../helpers/gestora/lStorage";
import moment from "moment"
moment.locale('pt-BR')

const defaultPeriod = [
	moment().startOf('year').format('YYYY-MM-DD'),
	//moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

export const forms = {
    pageTitle : 'Test',

    regForm : {

		formValidationResult : null,
		formValidatedCount : 0,
		incFormResetCount : 0,

        //Fprm default/initial values
        model : {
        	...defaultRegistrationFormModel
            // genre:'masculino',
            // f1 : 'nome ti',
            // f6 : '11960111618',
            // f8 : 'nome mae',
            // f9 : 'nome pai',
            // spouseName : 'nome esposa',
            // spouseCpf : '31530056802',
            // civilCapacity : '1',
			//
            // maritalStatus:'1',
            // fieldcep:'05009-000',
            // field3:'AAAAAAAAAAAAA',
            // //document:'315.300.568-02',
            // fieldcheckboxes : ['one'],
			//
            // bankInformation : [
            //     {correntista:'1', cotitular:'0'},
            //     {cotitular:'0'}
            // ],
			//
            // doccapture : {doctype:'rg'},
            // pep : {possuipep:'0', exerce:'0'},
			//
            // hasAditionalAccount : '0',
			//
            // mailingOptions : ['receiveQuotaholderExtract', 'receiveReportIncome', 'allowDistributorSendEmail'],
            // mailingAddressOptions : 'home',
        },

        readOnly : true,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },

    SearchBar : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			searchText : '',
			regFormStatus : '',
			renewStatus : '',
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    InvestmentSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			period : null,
			fundId:null,
			operation:null,
			edit : {
				status : '',
				canceledReason : '',
				pendingReason : '',
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    InvestmentFirstRelationSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			period : null,
			associateName : null,
			associateId : null,
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    IcatuCertificatesReportSearchForm : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			period : null,
			associateName : null,
			associateId : null,
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    FundTypesSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    BanksSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    MailSigSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    MailSigGuestForm : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ProfilesSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    UsersSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    RiskProfileSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    SuitabilitySearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    RiskProfileView : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {},
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    MyAccount : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {},
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    SuitabilityView : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {},
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    SuitabilityRenderAnswers : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			edit : {},
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ModalForm : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ModalFormAnswers : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ModalPasswordReset : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    ModalMFA : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

        uploadedFiles : {

        }
    },

    Dashboard : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			period : null,
			edit : {
				status : '',
				canceledReason : '',
				pendingReason : '',
			}
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    ClientLogDashboard : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
			period : defaultPeriod,
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    ClientSearch : {

		formValidationResult : null,
		formValidatedCount : 0,

        //Fprm default/initial values
        model : {
        },

        readOnly : false,

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,

    },

    loginForm : {

        //Fprm default/initial values
        model : {
            document : lStorage.get('last-login-document') || '',
            password : '',
            // document : '32006302809',
            // password : 'Atlantida@13',
        },

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },

    BlockedDevicesForm : {

        //Fprm default/initial values
        model : {
        },

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },

    UnBlockDevicesForm : {

        //Fprm default/initial values
        model : {
        },

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },

    ClientStatusForm : {

        //Fprm default/initial values
        model : {
        },

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },

    ClientDevicesForm : {

        //Fprm default/initial values
        model : {
        },

        Form : '',

        //Field properties, used by customForm (ie. visible or hidden)
        fieldState : {},

        //Group information when needed
        currentGroup : 0,
        totalGroups : 0,

        //FormWizard screens control
        currentScreen : 0,
        totalScreens : 0,


        //Uploaded files

        uploadedFiles : {

        }

    },


}
