import React, {useState, useEffect, useMemo} from 'react';

import api, { sleep } from "../../../helpers/gestora/api"
import {Col, Row} from "reactstrap";

import { useLocation, useHistory, useParams } from "react-router-dom"

import { useOvermind } from "../../../overmind";
import {Datatable} from "../../../components/Common/Tables/Datatable";
import CustomHeader from "../../../components/Common/Tables/Datatable/CustomHeader";
import moment from "moment"
import {dateBrToISO, onlyNumbers} from "../../../renders/utils";
import CustomForm from "../../../components/Common/CustomForm/Form";
import DefaultPage from "../../../components/Common/DefaultPage/DefaultPage";
import FormWizard from "../../../components/Common/CustomForm/FormWizard";
moment.locale('pt-BR')
import Button from "../../../components/Common/Button/Button"

import {
	RegFormApprovalEnum, RegFormApprovalRenewEnum, registrationFormRenewStatusOptions
} from "../../../constants/formLists";

import {formatPhone, parseDocument, parseMoney} from "../../../helpers/parser_helper";
import InvestmentsViewPending from "../ViewPending";
import PageDatatable from "../../../components/Common/Tables/Datatable/PageDatatable";
import {DataDate} from "../../../components/Common/Tables/Datatable/DataTypes";
import DefaultPageHeader from "../../../components/Common/DefaultPage/DefaultPageHeader";
import {downloadFileClient} from "../../../common/url";
import UserCanAll from "../../../components/Common/UserCan/UserCanAll";
import {useLocalStorage} from "../../../helpers/gestora/UseLocalStorage";
import {UserCan} from "../../../helpers/gestora/auth";
import ModalForm from "../../../components/Common/Modals/ModalForm";

const defaultDate = [
	moment().startOf('month').format('YYYY-MM-DD'),
	// moment().startOf('year').format('YYYY-MM-DD'),
	moment().format('YYYY-MM-DD')
]

// const NoDataComponent = <Row>
// 	<Col md={12}  className="pt-4">Nenhum registro encontrado</Col>
// </Row>

//console.log('statusTabs', statusTabs)

const apiEndpoint = {
	path : `admin/clients/search`,
	method : `post`,
}

export const ClientSearch = () => {
	const formId = 'ClientSearch'
	const location = useLocation()
	const history = useHistory()
	const clients = useOvermind('clients')

	const [filterParams, setFilterParams] = useState({});
	const [refreshCount, setRefreshCount] = useState(0);
	const [selectedPeriod, setSelectedPeriod] = useState(defaultDate)
	const [exportingResult, setExportingResult] = useState(false)
	const [filterClientStatus, setFilterClientStatus] = useState(history.location?.state?.clientStatus || '1');
	const [selectedIds, setSelectedIds] = useState([])

	const [changingAssociateMany, setChangingAssociateMany] = useState(false)
	const [changeAssociateMany, setChangeAssociateMany] = useState(null)

	const [compactMode, setCompactMode] = useLocalStorage(`compact-mode-${formId}`, false)
	const [viewPendingId, setViewPendingId] = useState(0)

	const {
		state   : {
			[formId] : {model}
		},
		actions : {setModelValueByPath, incFormResetCount, updateModelValueValues, setModelValueValues}
	} = useOvermind('forms')

	const statusTabs = useMemo(() => {

		return UserCan('inactiveClients.consult')
			? [
				{id:'1', caption:"Ativos"},
				{id:'0', caption:"Inativos"},
			]
			: [
				{id:'1', caption:"Ativos"}
			]

	}, [])


	useEffect(() => {

		if (location.state?.status)
			setModelValueByPath({formId, path:'status', value:location.state.status})
		if (location.state?.renewStatus)
			setModelValueByPath({formId, path:'renewStatus', value:location.state.renewStatus})
	}, [location])

	const handleFilterChange = () => {
		const filterModel = _.cloneDeep(model)
		const newFilters = {
			...filterParams,
			...filterModel,
			createdAt : model?.createdAt ? dateBrToISO(model.createdAt) : null,
			value : parseMoney(model.value) ,
		}
		// console.log({newFilters, filterModel, filterParams})
		setFilterParams(newFilters)
	}

	const searchParams = useMemo(() => {
		const extraFilters = filterClientStatus==='1'
			? {
				status : model?.status,
				renewStatus : model?.renewStatus,

			}
			: {

			}

		return {
			...filterParams,
			...extraFilters,
			periodStart : model?.period?.length && model?.period[0],
			periodEnd : model?.period?.length && model?.period[1],
			clientStatus : filterClientStatus,
			document : model?.document ? onlyNumbers(model?.document) : null,
			phoneNumber : model?.phoneNumber ? onlyNumbers(model?.phoneNumber) : null
		}

	}, [refreshCount, filterClientStatus, selectedPeriod, model?.status, model?.renewStatus, model?.period, filterParams])

	useEffect(() => {
		setModelValueByPath({formId, path:'period', value:history.location?.state?.period || null})
	}, [history.location])

	useEffect(() => {
		setModelValueValues({formId, values:{
			status:history.location?.state?.status || null,
			renewStatus:history.location?.state?.renewStatus ||null,
		}})
		// setModelValueByPath({formId, path:'period', value:history.location?.state?.period || null})
		// setModelValueByPath({formId, path:'status', value:history.location?.state?.status || null})
		// setFormModel({formId, model:{
		// 	..._.cloneDeep(model),
		// 	period : history.location?.state?.period || null,
		// 	status : history.location?.state?.status || null,
		// }})
	}, [location])

	const handleClick = row => {
		history.push({
			pathname:`/clients/registrationform/${row.registration_form_id}#${Math.random()}`,
			//state : {clientId:row.id}
		})
		//console.log(row)
	};

	const handleViewPending = row => {
		setViewPendingId(row.pendingId)
	}

	const changeAssociateFields = useMemo(() => [
		{
			id:'title',
			caption:'Alterar Planejador dos clientes selecionados',
			type:'title',
			required:true,
			col:12
		},
		{
			id:'associateId',
			caption:'Planejador',
			type:'select2',
			listId:'Associate',
			noEmptyOption:true,
			required:true,
			col:12
		},
	], [])


	const handleChangeAssociateMany = (values) => {
		setChangingAssociateMany(true)
		clients.actions
			.changeAssociateMany({ids:selectedIds, associateId:values.associateId})
			.then(res => {
				setSelectedIds([])
				setChangeAssociateMany(null)
				setRefreshCount(refreshCount+1)
			})
			.catch(e => {
				alert(`Ocorreu um erro ao enviar: ${e.toString()}`)
			})
			.finally(() => setChangingAssociateMany(false))
	}

	const handleDeleteClient = row => {
		if (confirm(`Deseja realmente excluir os dados do cliente "${row.name}" permanentemente? `)) {
			clients.actions
				.deleteClient(row.id)
				.then(() => {
					alert(`Dados do cadastro removido`)
					setRefreshCount(refreshCount+1)
				})
				.catch(e => {
					alert("Ocorreu um erro ao executar a operação: " + e?.toString())
				})

		}
	}

	const handleExportResult = (evt, fileTitle) => {
		setExportingResult(true)
		api
			.post(`admin/clients/downloadxls`, searchParams)
			.then(res => downloadFileClient(res, fileTitle, Buffer.from(res.data.content.data)))
			.catch(e => alert(`Ocorreu um erro ao fazer o download: ${e.toString()}`))
			.finally(() => setExportingResult(false))
	}

	const columns = useMemo(() => {
		const extraColumns = filterClientStatus==='1'
			? [
				{
					id : 'registration_form_status_history.id',
					name: "Pendências",
					//name: <CustomHeader onComplete={handleFilterChange} id={'pendingDate'} formId={formId} fieldProps={{type:'text'}}>Pendência</CustomHeader>,
					omit:compactMode,
					width:'128px',
					button: true,
					selector: function A(row) {
						return row?.pendingReason
							? <Button color='outline-danger' onClick={() => handleViewPending(row)}>Abrir</Button>
							: null
					},
					sortable: true,
					compact : true,
					wrap : true,
				}
			]
			: [
				{
					id : 'client.inactiveDate',
					name: "Data Desativação",
					grow:1,
					width:'110px',
					selector: function A(row) {
						return <DataDate value={row?.inactiveDate}/>
					},
					sortable: false,
					compact : true,
					wrap : true,
				},
				{
					id : 'actions',
					name: "Ações",
					//name: <CustomHeader onComplete={handleFilterChange} id={'pendingDate'} formId={formId} fieldProps={{type:'text'}}>Pendência</CustomHeader>,
					width:'128px',
					button: true,
					selector: function A(row) {

						const showButton = moment().diff(row.inactiveDate, 'years')>=5
						// const showButton = moment().diff(row.inactiveDate, 'hours')>=1

						return showButton
							? <Button color='outline-danger' onClick={() => handleDeleteClient(row)}>Excluir cadastro</Button>
							: null
					},
					sortable: false,
					compact : true,
					wrap : true,
				}
			]


		return [
			{
				id : 'client.document',
				name: <CustomHeader onComplete={handleFilterChange} id={'document'} formId={formId} fieldProps={{type:'cpfcnpj'}}>CPF/CNPJ</CustomHeader>,
				width:'156px',
				grow:0.8,
				selector: row => parseDocument(row?.document),
				sortable: true,
				compact : true,
				wrap : true,
			},
			{
				id : 'client.name',
				name: <CustomHeader onComplete={handleFilterChange} id={'name'} formId={formId} fieldProps={{type:'text'}}>Cliente</CustomHeader>,
				grow:1,
				selector: row => row?.name,
				sortable: true,
				compact : true,
				wrap : true,
			},
			{
				id : 'client.phone_number',
				name: <CustomHeader onComplete={handleFilterChange} id={'phoneNumber'} formId={formId} fieldProps={{type:'phone'}}>Telefone</CustomHeader>,
				grow:0.1,
				width:'136px',
				selector: row => formatPhone(row?.phoneNumber),
				sortable: true,
				compact : true,
				wrap : true,
			},
			{
				id : 'client.email',
				name: <CustomHeader onComplete={handleFilterChange} id={'email'} formId={formId} fieldProps={{type:'email'}}>E-mail</CustomHeader>,
				grow:1,
				width:'172px',
				selector: row => row?.email,
				sortable: true,
				compact : true,
				wrap : false,
			},
			{
				id : 'registration_form.create_dt',
				name: <CustomHeader onComplete={handleFilterChange} id={'createdAt'} formId={formId} fieldProps={{type:'date'}}>Cadastro</CustomHeader>,
				grow:1,
				width:'110px',
				selector: function A(row) {
					return <DataDate value={row?.createDt}/>
				},
				sortable: true,
				compact : true,
				wrap : true,
			},
			{
				id : 'associate.name',
				name: <CustomHeader onComplete={handleFilterChange} id={'associateId'} formId={formId} fieldProps={{type:'select2', listId:'Associate'}}>Planejador</CustomHeader>,
				width:'200px',
				grow:0.5,
				selector: function A(row) {
					const associateName = row?.associateName
					const changeAssociateName = row?.associateChange?.associate?.shortName || row?.associateChange?.associate?.name
					return <div data-tag="allowRowEvents">
						{associateName}
						{changeAssociateName && <>
							<br data-tag="allowRowEvents" />
							<span className="font-size-rem-4 text-danger" data-tag="allowRowEvents" >Troca agendada: {changeAssociateName}</span>
						</>}
						</div>
				},
				sortable: true,
				compact : true,
				wrap : true,
			},
			...extraColumns,
		]
	}, [compactMode, filterClientStatus]);


	const searchFields = useMemo(() => {

		const extraFilters = filterClientStatus==='1'
			? [
				{
					id:'status',
					caption:'Status',
					type:'select2',
					//noEmptyOption:true,
					emptyOptionText : <b>Todos</b>,
					options:[
						//{id : 'noregform', 'caption':'Sem ficha cadastral'},
						//{id : 'incomplete', 'caption':'Cadastro iniciado'},
						{id : 'welfare', 'caption':<div className={"text-danger"}>Somente previdência</div>},
						{id : 'overdue', 'caption':<div className={"text-danger"}>Em atraso</div>},
						{id : RegFormApprovalEnum.STARTED, 'caption':'Cadastro iniciado'},
						{id : RegFormApprovalEnum.PENDING, 'caption':'Análise Fiduc'},
						{id : RegFormApprovalEnum.PENDING_ADJUSTMENT, 'caption':'Com pendências'},
						{id : RegFormApprovalEnum.SENT_TO_BANK, 'caption':'Análise Bradesco'},
						{id : RegFormApprovalEnum.APPROVED, 'caption':'Aprovados'},
						{id : RegFormApprovalEnum.NOT_APPROVED, 'caption':'Reprovados'},
					],
					required:true,
					col:4
				},
				{
					id:'renewStatus',
					caption:'Renovação',
					type:'select2',
					//noEmptyOption:true,
					emptyOptionText : <b>Todos</b>,
					options : [
						...registrationFormRenewStatusOptions,
						{id : 'available', 'caption':'Disponível'},
						{id : 'expired', 'caption':'Vencidas'},
					],
					required:true,
					col:4
				}
			]
			: []

		return [
			{
				id:formId,
				fields : [
					{
						id:'period',
						caption:'Data do cadastro',
						type:'daterange',
						emptyOptionText:<b>Tudo</b>,
						required:true,
						col:4
					},
					...extraFilters,
				],
			},
		]
	}, [filterClientStatus]);

	const handleNewRegistrationForm = async (row, orderDir) => {
		await incFormResetCount({formId})
		history.push({
			pathname:`/clients/registrationform`,
			//state : {clientId:row.id}
		})
	};

	//console.log(orderDirection)
	return (
		<UserCanAll permission={"clients.consult"}>
		<ModalForm
			data={selectedIds}
			isVisible={!!changeAssociateMany}
			fields={changeAssociateFields}
			confirmMessage={`Deseja mesmo alterar o planejador dos clientes selecionados?`}
			modalStyle={{
				maxWidth : '50%',
			}}
			onCancel={() => {
				setChangeAssociateMany(null)
			}}
			onConfirm={handleChangeAssociateMany}
		/>
		<DefaultPage
			headerComponent={
				<DefaultPageHeader title="Fichas cadastrais"  >
				</DefaultPageHeader>
			}
		>
			<CustomForm
				className="needs-validation"
				formId={formId}
				model={model}
				validateOnLoad={false}
			>
				{viewPendingId ?
				<InvestmentsViewPending
					formId={formId}
					viewId={viewPendingId}
					onConfirm={() => {
						setViewPendingId(0)
					}}
					onCancel={() => {
						setViewPendingId(0)
					}}
				/> : null}

				<PageDatatable
					formId={formId}
					tabs={statusTabs}
					activeTab={filterClientStatus}
					onTabChange={tabId => setFilterClientStatus(tabId)}
					TabActions={<>
						<UserCanAll permission={"clients.edit"}>
						<Button
							loading={changingAssociateMany}
							disabled={!selectedIds.length}
							colorIcon={true}
							className="btn btn-outline-danger  m-1 btn-xs"
							// disabled={currentScreen==0}
							onClick={() => setChangeAssociateMany(true)}>
							<i className="m-1 fas fa-user-tie align-middle"></i>
							Alterar Planejador
						</Button>
						<Button
							className="btn btn-primary m-1 btn-sm"
							onClick={handleNewRegistrationForm}
						>
							<i className="fas fa-plus m-1" />
							Nova ficha
						</Button>
						</UserCanAll>
						<Button
							loading={exportingResult}
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={handleExportResult}>
							<i className="m-1 fa fa-file-excel align-middle"></i>
							Exportar
						</Button>
						<Button
							disabled={false}
							colorIcon={true}
							className="btn btn-outline-primary m-1 btn-sm"
							// disabled={currentScreen==0}
							onClick={() => setCompactMode(!compactMode)}>
							<i className={`m-1 fa ${compactMode ? "fa-eye-slash" : "fa-eye"} align-middle`}></i>
						</Button>
					</>}
					HeadContainer={
						<FormWizard
							screenId={formId}
							screens={searchFields}
							formId={formId}
							// OnInputValueChange={handleInputValueChange}
							tabId={formId}
						/>
					}
				>
					<Datatable
						selectableRows={true}
						onChangeSelectedRows={(selectedRows) => {
							setSelectedIds(_.map(Object.values(selectedRows), 'id'))
						}}
						defaultSortField={"client.name"}
						formId={formId}
						columns={columns}
						onRowClicked={row => handleClick(row)}
						searchParams={searchParams}
						apiEndpoint={apiEndpoint}
					/>
				</PageDatatable>

			</CustomForm>
		</DefaultPage>
	</UserCanAll>
	);
};

export default {
	title: 'Pagination/Remote',
	component: ClientSearch,
};
