import moment from "moment"
moment.locale('pt-BR')

export const downloadFileClient = (res, fileTitle=null, buffer=null) => {
	//text/csv; charset=UTF-
	const contentType = res.headers['content-type'].split(';')
	let [type, extension] = contentType[0].split('/')
	if (extension==="vnd.openxmlformats-officedocument.spreadsheetml.sheet")
		extension = "xlsx"

	fileTitle = fileTitle
		|| document.querySelector('.page-header-title')?.innerHTML
		|| 'Arquivo_Download_Sem_Nome'

	const a = document.createElement('a');
	const blob = new Blob([buffer || res.data], {type: type});
	const url = window.URL.createObjectURL(blob);

	a.href = url;
	a.download = `${fileTitle}_${moment().format('YYYY_MM_DD_HH_mm_ss')}.${extension}`;

	document.body.append(a);
	a.click();
	a.remove();
	window.URL.revokeObjectURL(url);
}

export const downloadFileText = (text, fileName) => {
	const a = document.createElement('a');
	const blob = new Blob([text], {type: "text/html"});
	const url = window.URL.createObjectURL(blob);
	a.href = url;
	a.download = fileName;

	document.body.append(a);
	a.click();
	a.remove();
	window.URL.revokeObjectURL(url);
}
