import React from "react"
import {UserCan} from "../../../helpers/gestora/auth";

function UserCanAll({permission, children, ...props} ) {

    return UserCan(permission, false)
		? children
		: null
}

export default UserCanAll
