import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"


//import { render } from 'react-dom'
import { createOvermind } from 'overmind'
import { config } from './overmind/index'
import { Provider as OvermindProvider } from 'overmind-react'

import store from "./store"

const overmind = createOvermind(config, { devtools: false })
//const overmind = createOvermind(config)

const app = (
  <OvermindProvider value={overmind}>
      <Provider store={store}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </Provider>
  </OvermindProvider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
