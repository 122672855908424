import {
	dateTimestampToISO,
	onlyNumbers,
	booleanValue,
	dateISOToBr,
	MoneyFormat,
	optionValue, dateBrToISO,
} from "../utils";
import {parseMoney} from "../../helpers/parser_helper";


export const clientLocalToApi = (form) => {

	//alert(professionalInformation?.carrerId)

	const {
		client,
		mailing,
		address,
		bankInformation,
		hasAdditionalBankAccount,
		pep,
		fatca,
		professionalInformation,
		funds,
		terms,
		investorDeclaration,
	} = form

	const apiAddresses = [
		{...address, typeAddress:1},
		{
			...mailing?.mailType==1 ? address : mailing?.address,
			typeAddress : 2
		}
	]

	const bankAccounts = []
	//console.log('fatca.renouncedNationalities', fatca.renouncedNationalities)

	const getBankAccountInfo = (account) => {

		const coHolders = []

		if (account.haveCoHolders=='1') {
			if (account.coHolders[0])
				coHolders.push(account.coHolders[0])
			if (account.coHolders[1])
				coHolders.push(account.coHolders[1])
		}

		if (!account.agency || !account.digit || !account.accountNumber)
			return null

		return {
			"agency": account.agency,
			"digit": account.digit,
			"debitAuthorization": account.debitAuthorization==1,
			"accountNumber": onlyNumbers(account.accountNumber),
			"holderName": client.name,
			"holderCpf": onlyNumbers(client.cpf),
			"isBradescoAccount": account.isBradescoAccount==1,
			"coHolders": coHolders,
			"bankId": account.bankId || null,
		}
	}

	if (bankInformation?.accounts?.length>0) {
		const mainBankAccount=getBankAccountInfo(bankInformation.accounts[0])
		if (mainBankAccount)
			bankAccounts.push(mainBankAccount)
	}

	if (hasAdditionalBankAccount==1 && bankInformation?.accounts?.length>1) {
		const additionalBankAccount = getBankAccountInfo(bankInformation.accounts[1])
		if (additionalBankAccount)
			bankAccounts.push(additionalBankAccount)
	}

	const fatcaAnswers = []
	if (fatca?.answers?.length>0) {
		const fatcaAnswer = fatca.answers[0]
		fatcaAnswers.push({
			"countryId": onlyNumbers(fatcaAnswer.countryId),
			"nif": fatcaAnswer.nif,
			"isWaitingNif": fatcaAnswer.options=='isWaitingNif',
			"hasNif": fatcaAnswer.options=='hasNif',
			"dontNeedNif": fatcaAnswer.options=='dontNeedNif',
			"type": fatcaAnswer.type,
		})
	}

	//const personPhoto = ""
	//console.log9

	let mailingData
		if (mailing) {
			mailingData = {
				"id": mailing.id,
				"receiveQuotaholderExtract": mailing.options && mailing.options.includes('receiveQuotaholderExtract'),
				"receiveReportIncome": mailing.options && mailing.options.includes('receiveReportIncome'),
				"allowDistributorSendEmail": mailing.options && mailing.options.includes('allowDistributorSendEmail'),
				"mailType": 1,
				"mailingType": Number(mailing.mailType),
				"registrationFormId": client.registrationFormId
			}

		}

	return {
		"client": {
			"id": client.id,
			"riskProfileCategoryId": Number(client.riskProfileCategoryId),
			"document": onlyNumbers(client.document),
			"documentType": client.ocr?.documentType,
			"identificationNumber": client.ocr?.identificationNumber,
			//"rgNumber": null,
			"name": client.name,
			"email": client.email,
			"birthDate": dateBrToISO(client.birthDate),
			//"documentPhotoFront": "Client/document_photo_front/Client_E7AD5407611C7944A57987F23EDC95BA_document_photo_front.png",
			//"documentPhotoBack": "Client/document_photo_back/Client_E7AD5407611C7944A57987F23EDC95BA_document_photo_back.png",
			"phoneNumber": onlyNumbers(client.phoneNumber),
			//"faceMatched": null,
			//"receiveNotify": true,
			"complement": address?.complement,
			"number": address?.number,
			"address": address,
			"addresses": apiAddresses,
			//"pjCompanyName": null,
			"motherName": client.motherName,
			"realEstatePatrimony": parseMoney(client.realEstatePatrimony),
			"movablePatrimony": parseMoney(client.movablePatrimony),
			"financialInvestmentsPatrimony": parseMoney(client.financialInvestmentsPatrimony),
			"salary": parseMoney(client.salary),
			"otherIncome": parseMoney(client.otherIncome),
			"otherIncomeDescription": client.otherIncomeDescription || '',
			"fatherName": client.fatherName,
			"orgemission": client.ocr?.orgemission,
			"expeditiondate": dateBrToISO(client.ocr?.expeditiondate),
			"placeofemission": client.ocr?.placeofemission,
			"responsible": client.responsible ? {
				"id": client.responsible.id,
				"name": client.responsible.name,
				"document": onlyNumbers(client.responsible.document),
				"relationType": client.responsible.relationType,
				//"documentPhoto": null,
				"cpf": false
			} : null,
			"civilCapacity": Number(client.civilCapacity),
			"maritalStatus": Number(client.maritalStatus),
			"spouseName": client.spouseName || '',
			"spouseCpf": client.spouseCpf || '',
			"additionalPhone": client.additionalPhone,
			"personColor": Number(client.personColor),
			//"documentPhotoIncapacity": null,
			"responsibleAuthorization": client.responsibleAuthorization==1,
			//"thirdPartAccount": false,
			//"intermediateLink": false,
			//"signature": null,
			"socialName": client.socialName,
			"patrimonyUpdateDate": dateBrToISO(client.patrimonyUpdateDate),
			"naturality": client.naturality,
			"nacionality": Number(client.nacionality),
			"country": Number(client.country),
			//"financialKnowledge": null,
			"isHolder": true,
			//"isCoholder": null,
			"gender": client.gender?.toUpperCase(),
			//"professionalInformations": null,
			"haveResponsible": client.civilCapacity!='1',
			"haveWorked": client.haveWorked=='1',
			"haveRelation": client.haveRelation=='1',
			"haveFatca": client.haveFatca=='1',
			"havePhotoFront": true,
			"havePhotoBack": true,
			"havePersonPhoto": true,
			"haveProofOfResidence": true,
			"active": client.status,
			//"emailDuplicated": null,
			"validDate": dateBrToISO(client.ocr?.validDate),
			"registrationFormId": client.registrationFormId,
			//"score": false,
			//"lastKycSearch": "2021-05-12T21:16:53.000Z",
			//"personPhoto": personPhoto,
			"haveAdditionalPhone": client.additionalPhone ? true : false,
			"associateId": client.associateId,
			"clientId": client.id,
			"welfare": client.welfare=='1',
			"personTypeId": 1
		},

		mailing : mailingData,

		bankInformation: bankInformation ? {
			//"id": 28365,
			"registrationFormId": client.registrationFormId,
			"accounts": bankAccounts,
			"toHolder": true
		} : null,

		pep : pep ? {
			"id": 43,
			"clientId": client.id,
			"registrationFormId": client.registrationFormId,
			"functionId": Number(pep.functionId),
			"functionRelationId": Number(pep.functionRelationId),
			"initialDate": dateBrToISO(pep.initialDate),
			"endDate": dateBrToISO(pep.endDate),

			"cnpj": pep.cnpj,
			"company": pep.company,

			"haveWorked": client.haveWorked==1,
			"haveRelation": client.haveRelation==1,
			"nameOfRelation": pep.nameOfRelation,
			"documentRelation": onlyNumbers(pep.documentRelation),
			"relationTypeId": Number(pep.relationTypeId),
			"parentId": pep.relationTypeId == 1 ? pep.parentId : null,
			"relationId": pep.relationTypeId == 2 ? pep.relationId : null,

			//"relation": null,
			//"document": "",
		} : null,

		fatca : fatca ? {
			"clientId": client.id,
			"registrationFormId": client.registrationFormId,

			"hasOtherNationality": fatca.options && fatca.options.includes('hasOtherNationality'),
			"hasOtherFiscalResidence": fatca.options && fatca.options.includes('hasOtherFiscalResidence'),
			"hasOtherVisa": fatca.options && fatca.options.includes('hasOtherVisa'),

			"hasRenouncedNationality": fatca.hasRenouncedNationality==1,
			//"renouncedNationalities": fatca.renouncedNationalities ? [Number(fatca.renouncedNationalities)] : [],
			"renouncedNationalities": fatca.renouncedNationalities || [],

//			"hasError": false,
			"answers": fatcaAnswers,

			requestType : fatca.requestType,
			hasOtherFiscalRes : fatca.hasOtherFiscalRes==1,
			fiscalCountry : fatca.fiscalCountry,
			otherCountry : fatca.otherCountry,
			nifNumber : fatca.nifNumber,


			bornEua : fatca.bornEua==1,
			isNorthAmerican : fatca.isNorthAmerican==1,
			isPermanentEua : fatca.isPermanentEua==1,
			isFiscalEua : fatca.isFiscalEua==1,
			refuseSsn : fatca.refuseSsn==1,
			ssn : fatca.ssn,

			isEuaLearner: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isEuaLearner'),
			isPolitical: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isPolitical'),
			isResponsible: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isResponsible'),
			isNotIrs: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isNotIrs'),
			isResignedCln: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isResignedCln'),
			isResignedCitizen: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isResignedCitizen'),
			isWithoutGcNotRequested: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isWithoutGcNotRequested'),
			isWithoutGc: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isWithoutGc'),
			isWithoutFiscalResidence: fatca.notFiscalResidentOptions && fatca.notFiscalResidentOptions.includes('isWithoutFiscalResidence'),

		} : null,

		professionalInformation : professionalInformation ? {
			"clientId": client.id,
			"registrationFormId": client.registrationFormId,
			"cnpj": onlyNumbers(professionalInformation.cnpj),
			"company": professionalInformation.company,
			"carrerId": Number(professionalInformation.carrerId),
			"professionalType": Number(professionalInformation.professionalType),

		} : null,

		investorDeclaration : {
			id : client.registrationFormId,
			date : dateBrToISO(investorDeclaration?.date),
			local : investorDeclaration?.local?.trim(),
		},

		funds : funds,
		terms : funds,
		//terms : terms, // removes accepted terms

	}
}
