
import ClientSuitability from "../Suitability";
import BlockedDevices from "../Security/BlockedDevices";
import UnblockDevices from "../Security/UnblockDevice";
import BlockHistory from "../Security/BlockedHistory";
import ClientStatus from "../Security/ClientStatus";
import {UserCan} from "../../../../helpers/gestora/auth";
import ClientDevices from "../Security/ClientDevices";
import ChangeClientPassword from "../Security/ChangeClientPassword";

const blockedDevicesComponent = [
    {
    	id:'security.blockedDevices',
		component:BlockedDevices,
	},
]

const unblockPasswordComponent = [
    {
    	id:'security.unblockPassword',
		component:UnblockDevices,
	},
]

const clientStatusComponent = [
    {
    	id:'security.clientStatus',
		component:ClientStatus,
	},
]

const changeClientPasswordComponent = [
    {
    	id:'security.changeClientPassword',
		component:ChangeClientPassword,
	},
]

const clientDeviceComponent = [
    {
    	id:'security.clientDevice',
		component:ClientDevices,
	},
]

const blockedHistoryComponent = [
    {
    	id:'security.blockHistory',
		component:BlockHistory,
	},
]

export const securityScreens = [
    {
    	id:'blockedDevices',
        caption:'Bloquear',
        fields : blockedDevicesComponent,
		available : () => UserCan('blockDevice.consult')
    },
    {
    	id:'unblockPassword',
        caption:'Desbloquear',
        fields : unblockPasswordComponent,
		available : () => UserCan(['unblockDevice.consult', 'unblockDeviceAdmin.consult'], true)
    },
    {
    	id:'clientStatus',
        caption:'Status cliente',
        fields : clientStatusComponent,
		available : () => UserCan('clientStatus.consult')
    },
    {
    	id:'clientDevice',
        caption:'Dispositivos conectados',
        fields : clientDeviceComponent,
		available : () => UserCan('clientDevice.consult')
    },
    {
    	id:'changeClientPassword',
        caption:'Senha',
        fields : changeClientPasswordComponent,
		available : () => UserCan('changeClientPassword.consult')
    },
    {
    	id:'blockHistory',
        caption:'Histórico',
        fields : blockedHistoryComponent,
    },
]
