import React, {useEffect, useState} from "react"

import {
	Row,
	Col,
} from "reactstrap"

import DefaultTable from "../../../../components/Common/Tables/DefaultTable/DefaultTable";

import {DataActionButton} from "../../../../components/Common/Tables/Datatable/DataTypes";
import Button from "../../../../components/Common/Button/Button";
import ModalForm from "../../../../components/Common/Modals/ModalForm";
import {yesNoOptions} from "../../../../constants/formLists";
import { useOvermind } from '../../../../overmind'
import {onlyNumbers} from "../../../../renders/utils";
import SuitabilityRenderAnswers from "./SuitabilityRenderAnswers";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";

const categoryFields = [
	{
		id:'titleQuestion',
		caption:'Pergunta',
		type:'title',
		required:true,
		col:12
	},
	{
		id:'title',
		caption:'Título',
		info:"Título apresentado no app",
		type:'text',
		required:true,
		row:true,
		col:12
	},
	{
		id:'ask',
		caption:'Descrição',
		type:'textarea',
		required:true,
		row:true,
		col:12
	},
	// {
	// 	id:'active',
	// 	caption : 'Ativo?',
	// 	type    : 'radiogroup',
	// 	options:yesNoOptions,
	// 	required:true,
	// 	col:12,
	// 	inline:true,
	// },
]

const SuitabilityQuestions = ({
	formId,
	field,
	...props
}) => {

	const suitability = useOvermind('suitability')

	const [viewId, setViewId] = useState(null)
	const [categoryData, setCategoryData] = useState(null)

	const handleNew = async  () => {
		setViewId('')
		setCategoryData({})
	}

	const handleEdit = async  (row, index) => {
		setViewId(index)
		setCategoryData(suitability.state.questions[index])
	}

	const handleDelete = async  (row, index) => {
		suitability.actions.deleteQuestionByIndex(index)
	}

	useEffect(() => {
		//suitability.actions.clearQuestions()
	}, [])


	// if (!formId)
	// 	return null

    const {
        state   : {
            [formId]:{model, formValidationResult, fieldState, formValidatedCount}
        },
        actions : {
			submitForm, setFormModel, setModelValueByPath, resetForm, setFormValidationResult, setFieldState, incFormValidatedCount
        }
    } = useOvermind('forms')

	return <>

		<ModalForm
			data={categoryData}
			isVisible={!!categoryData}
			fields={categoryFields}
			modalStyle={{
				maxWidth : '50%',
			}}
			onCancel={() => {
				setViewId(null)
				setCategoryData(null)
			}}
			onConfirm={(values) => {

				if (viewId!=='') {
					const question = {
						...values,
						answers:suitability.state.questions[parseInt(viewId)].answers
					}
					suitability.actions.replaceQuestionByIndex({index:viewId, question:question})
				} else if (!viewId) {
					const question = {
						...values,
						answers:[]
					}
					suitability.actions.addQuestion({formId, question:question})
				}
				setViewId(null)
				setCategoryData(null)
			}}
		/>
		<Row >
			<Col md={12}>

					<>
						<div className="d-flex flex-row align-items-center">
							{field.hasRelation
								? null
								: <Button
									className="btn btn-primary m-1 btn-sm flex-grow-0"
									style={{width:'200px'}}
									onClick={handleNew}
								>
									<i className="fas fa-plus m-1" />
									Adicionar Pergunta
								</Button>
							}
						</div>
					</>

				{field.hasRelation
					? <UserCanAll permission="suitabilities.edit">
						<div className="text-center text-danger font-weight-600 p-2">
							Não é possível fazer alterações nas perguntas deste formulário pois este já está relacionado à um cliente
						</div>
					</UserCanAll>
					: null
				}

			</Col>

		</Row>

		{!suitability.state.questions || suitability.state.questions.length===0 ? null :
			<DefaultTable
				className="pt-0 m-0 "
				columns={[
					{id:'question', caption:'Pergunta', width:'30%',
						formatter:function A(row)  {
							return <div className="d-flex flex-column">
								<div className="font-weight-600">{row.title}</div>
								<div>{row.ask}</div>

							</div>
						}
					},

					{id:'respostas', caption:'Respostas', width:field.hasRelation ? '0%' : '70%',
						formatter:function A(row, index) {
							const answers = suitability.state.questions[index].answers
							return <SuitabilityRenderAnswers
								questionIndex={index}
								answers={answers}
								hasRelation={field.hasRelation}
							/>
						}
					},
					// {id:'maxScore', caption:'      Máx.', width:'5%', formatter:(row) => row.maxScore},

					// {id:'active', caption:'Ativo', width:'10%', formatter:(row) => row.active==='1' ? "Sim" : "Não"},

					{id:'actions', width:field.hasRelation ? '0%' : '20%', caption:'',
						formatter : function Actions(item, index) {

							if (field.hasRelation)
								return null

							return <div className="d-flex flex-row">
								<DataActionButton
									disabled={field.hasRelation}
									title="Editar pergunta"
									buttonClass="btn btn-primary btn-sm me-1"
									iconClass="fas fa-pen m-0"
									type="button"
									onClick={() => handleEdit(item, index)}
								/>
								<DataActionButton
									title="Remover pergunta"
									disabled={field.hasRelation}
									buttonClass="btn btn-danger btn-sm"
									iconClass="fas fa-trash m-0"
									onClick={item => handleDelete(item, index)}
									type="button"
								/>
							</div>
						}
					},
				]}
				data={suitability.state.questions}
			/>
		}

	</>

}

export default SuitabilityQuestions
