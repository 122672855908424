import React, {useMemo} from "react"
import ReactApexChart from "react-apexcharts"
import {defaultGraphColors} from "../../../../helpers/gestora/configuration";


const GraphTimeline = ({
	series,
	labels,
	columnDate="createdAt",
	type='line',
}) => {

	// console.log({series})

	const graphConfig = useMemo(() => {

		if (!series)
			return null

		// console.log({series, labels})

		// console.log('graphConfig', data)

		// const categoryData = series?.map(value => value[columnDate] ?? 0)

		// let CData=[...series]
		// let CBData=[...series.data]
		//
		// if (CAData.length === 1) {
		// 	CAData.unshift({ [columnDate]: 'Inicio', [columnB]: 0, [columnA]: 0 });
		// }
		//
		// if (CBData.length === 1) {
		// 	CBData.unshift({ [columnDate]: 'Inicio', [columnB]: 0, [columnA]: 0 });
		// }

		// console.log('CAData', {CAData, CBData, categoryData})
		// console.log('MAX', {maxCA, maxCB, maxValue})
		// console.log('MIN', {minCA, minCB, minValue})
		// console.log('categoryData', categoryData)

		return {
			series: series,
			options: {

				chart: {
					height:'100%',
					zoom: { enabled: false },
					toolbar: { show: false},
					animations : {
						enabled : false,
						animateGradually: {
							enabled: true,
							delay: 60
						}
					}
				},
				colors: defaultGraphColors,
				dataLabels: { enabled: false },
				stroke: { width: [1, 1], curve: "straight" },
				title: { text: undefined, align: "left", enabled:false },
				grid: {
					show:false,
					row: { colors: ["transparent", "transparent"], opacity: 0 },
					borderColor: "#f1f1f1",
					xaxis : {
						lines : {
							show:false,
						},
					},
					yaxis : {
						lines : {
							show:false,
						},
					},
				},
				markers: { style: "inverted", size: 0, show:false },
				xaxis: {
					categories: labels,
					title: { text: undefined},
					labels:{show:false},
					axisBorder : {show:false,color : 'red'},
					axisTicks : {show:false}
				},
				yaxis: {
					title: { text: undefined},
					// min: minValue,
					// max: maxValue,
					labels:{show:false},
					axisBorder : {show:false, color : 'red'},
					axisTicks : {show:false},
				},
				annotations : {
					yaxis: [
						{
							x: 0,
							strokeDashArray: 4,
							borderColor: '#DADADA',
							borderWidth: 1,
							opacity: 1
						}
					],
				},
				legend: {
					show:false,
					position: "top",
					horizontalAlign: "right",
					floating: !0,
					offsetY: -25,
					offsetX: -5,
				},
				tooltip : {
					shared : true,
					intersect : false,
				}

				// responsive: [
				// 	{
				// 		breakpoint: 600,
				// 		options: { chart: { toolbar: { show: !1 } }, legend: { show: !1 } },
				// 	},
				// ],
			},
		}
	} , [series]);

	if (!series)
		return '[vazio]'

	// console.log('RENDEDED')

	return (
		<ReactApexChart
			options={graphConfig.options}
			series={graphConfig.series}
			type={type}
			height={'100%'}
		/>
	)
}

export default GraphTimeline

