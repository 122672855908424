import React, {useEffect, useMemo, useState} from "react"

import {
	Col,
	Row,
	Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav,
	Collapse,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import {useOvermind, useOvermindForm} from "../../../../../overmind";

import LoadingSection from "../../../../../components/Common/LoadingSection/LoadingSection";
import FormWizard from "../../../../../components/Common/CustomForm/FormWizard";
import CustomForm from "../../../../../components/Common/CustomForm/Form";
import Button from "../../../../../components/Common/Button/Button";
import {validateFieldStates, validateFormScreens} from "../../../../../components/Common/CustomForm/Utils/Validations";
import {deviceTypesOptions} from "../../../../../constants/formLists";
import {UserCan} from "../../../../../helpers/gestora/auth";
import UserCanAll from "../../../../../components/Common/UserCan/UserCanAll";


function BlockedDevices(props) {

	const formId = 'BlockedDevicesForm'

	const client = useOvermind('clients')
	const security = useOvermind('security')
	const form = useOvermindForm(formId)
	// const forms = useOvermind('forms')

	const [loading, setLoading] = useState(true)
	const [blockedDevices, setBlockedDevices] = useState(null)
	const [submitting, setSubmitting] = useState(false)
	const [canSave, setCanSave] = useState(false)

	const formScreens = useMemo(() => {
		const deviceOptions = deviceTypesOptions.reduce((map, obj) => {
			map.push({
				...obj,
				canRemove: !blockedDevices?.includes(obj.id),
			})
			return map
		}, [])

		return [
			{
				id:formId,
				fields : [
					{
						id:'title',
						type:'title',
						caption:"Dispositivos bloqueados",
						subtitle: UserCan('blockDevice.edit') ? "Adicione os dispositivos que deseja bloquear" : undefined,
					},
					{
						id:'deviceTypes',
						caption:'Tipos de dispositivos bloqueados',
						type:'select2multi',
						backspaceRemovesValue:false,
						options:deviceOptions,
						readOnly:!UserCan('blockDevice.edit'),
						col:6
					},
				],
			},
		]
	}, [blockedDevices])

	useEffect(() => {
		const {deviceTypes} = _.cloneDeep(form.state.model)
		const changedDevices = _.difference(deviceTypes, blockedDevices);
		setCanSave(!!changedDevices.length)
	}, [form.state.model?.deviceTypes])

	useEffect(() => {
		setLoading(true)
		security.actions
			.listBlockedDevices(client?.state?.currentRegistrationForm?.form?.client?.id)
			.then(({devices, unblockTries}) => {
				setBlockedDevices(devices)
				form.actions.setModelValueByPath({formId, path:'deviceTypes', value:devices})
			})
			.catch(e => {
				alert(`Ocorreu um erro ao carregar os dados`)
			})
			.finally(() => setLoading(false))

	}, [])

	const handleSubmit = () => {
		const values = _.cloneDeep(form.state.model)
		const deviceTypes = values.deviceTypes

		const removingAll = !deviceTypes?.length
		if (removingAll) {
			if (!confirm(`Tem certeza que deseja remover os bloqueios de acesso deste cliente? `))
				return
		} else {
			if (!confirm(`Tem certeza que deseja bloquear o acesso deste cliente? Todas as sessões atuais do cliente nos tipos de aparelhos selecionados serão canceladas.`))
				return
		}

		// const {deviceTypes} = values
		console.log(deviceTypes)
		setSubmitting(true)
		security.actions
			.saveBlockedDevices({
				clientId:client?.state?.currentRegistrationForm?.form?.client?.id,
				deviceTypes,
			})
			.then(data => {
				setLoading(true)
				setBlockedDevices(deviceTypes)
				client.actions.setTemporaryPassword('')
				setTimeout(() => setLoading(false))
				alert(removingAll ? `Bloqueios removidos!` : `Devices bloqueados!`)
			})
			.catch(e => {
				alert(`Ocorreu um erro ao salvar os dados`)
			})
			.finally(() => setSubmitting(false))
	}

	return (
		<LoadingSection loading={loading}>
			<CustomForm
				className="needs-validation "
				formId={formId}
				model={form.state.model}

				screens={formScreens}
				// onSubmit={handleSubmit}
			>
			<Row >
				<Col md={12} className="d-flex align-items-center justify-content-center">
					<FormWizard
						screenId={formId}
						screens={formScreens}
						formId={formId}
						tabId={formId}
					/>
				</Col>
			</Row>
			<UserCanAll permission={"blockDevice.edit"}>
				<Row >
					<Col md={12} className="d-flex align-items-start ">
						<Button
							disabled={!canSave}
							className="btn btn-primary"
							onClick={handleSubmit}
							style={{margin:'1em'}}
						>
							Salvar bloqueios
						</Button>
					</Col>
				</Row>
			</UserCanAll>
			</CustomForm>
		</LoadingSection>
	)
}

export default BlockedDevices;
