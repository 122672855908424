import React, {useEffect, useState} from "react"

import {
	Col,
	Row,
} from "reactstrap"

function KYCInfo(
{
	data,
	...props
}) {

	return (
		<Row>
			<Col md={12}>
                {data && data.map((item, index) => {

                	return <Row key={index}>
						<Col md={4}
							 className="d-flex justify-content-md-end align-items-md-end font-weight-semibold"
						>{item.caption}</Col>
						<Col md={6} >{
							(item.formatter ? item.formatter(item.value) : item.value) || '-'
						}</Col>
					</Row>
				})}

			</Col>
		</Row>
	)
}

export default KYCInfo;
