import React from 'react';

// import {Card, CardBody, Col, Container, Row, Spinner, Badge} from "reactstrap";

import moment from "moment"

moment.locale('pt-BR')

import Button from "../../../../components/Common/Button/Button"

export const DataDate = ({value, withTime=true}) => {
	if (!value)
		return null
	return <>
		<div data-tag="allowRowEvents" >
			{moment(value).format('DD/MM/YYYY')}
			{withTime && <>
				<br data-tag="allowRowEvents"  />
				<span data-tag="allowRowEvents"  style={{fontSize:'0.7em'}}>{moment(value).format('HH:mm:ss')}</span>
			</>}
		</div>
	</>
}

export const DataBank = ({value}) => {
	return <>
		<div data-tag="allowRowEvents" className="text-align-left">
			banco: {value?.bank?.code}
			<br data-tag="allowRowEvents" />
			ag: {value?.agency}
			<br data-tag="allowRowEvents" />
			cc: {value?.accountNumber}-{value?.digit}
		</div>
	</>
}

export const DataActionButton = ({caption='', onClick=null, buttonClass='primary', iconClass=null, disabled=false, title='', buttonStyle, loading}) => {
	return <Button
		title={title}
		loading={loading}
		className={buttonClass}
		onClick={onClick}
		disabled={disabled}
		buttonStyle={buttonStyle}
	>
		{iconClass && <i className={iconClass} />}
		{caption}
	</Button>
}

