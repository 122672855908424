
import ClientSuitability from "../Suitability";

const suitabilityComponent = [

    {
    	id:'suitability.component',
		caption:'Suitability',
		component:ClientSuitability,
	},
]

export const suitabilityScreens = [
    {id:'suitability',
        caption:'Suitability',
        fields : suitabilityComponent,
    },
]
