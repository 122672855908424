import React, {useEffect, useState} from "react"

import Field from "./Field";
import { useOvermind } from '../../../overmind'
import { Row } from "reactstrap";

function FormWizard({
    	formId,
		screens,
		selectedScreen=null,
		validationInRealTime=false,
		OnInputValueChange = () => {},
		...props
}) {

    const [screenList, setScreenList] = useState(screens)

	useEffect(() => {
		setScreenList(screens)
	}, [screens])

    const {
        state   : {
            [formId]:{currentTab, currentScreen, model}
        },
        actions : {

        }
    } = useOvermind('forms')



    const screen = selectedScreen!==null
		? screenList[selectedScreen]
		: screenList[currentScreen]

    if (!screen)
		return null
		//return <div>Form screen not found</div>

	// const getFieldRequired = (required) => {
	// 	return typeof required != 'function'
	// 		? () => required ? true : false
	// 		: () => required(model)
	// }

	if (screen.component) {
		return <screen.component formId={formId}/>
	}

    return (
        <Row className={`form-wizard form-wizard-${screen.id} w-100 bg-fiduc-background`} style={{}}>
            {screen.fields.map((field, fieldIndex) => {
				//field.required = getFieldRequired(field.required)

				if (field.component) {
					return typeof(field.component)=='object' ? field.component : <field.component
                        key={field.id}
                        formId={formId}
						field={field}
						{...props}
					/>
				}

                return (
                    <Field
						validationInRealTime={validationInRealTime}
                        formId={formId}
                        key={field.id}
                        screenId={screen.id}
                        screenIndex={currentScreen}
						customFormatter={props.customFormatter}
                        {...field}
						OnInputValueChange={OnInputValueChange}
						tabId={props.tabId}
                    />
                )
            })}
        </Row>
    )
}

export default FormWizard;
