import React, {useState, useEffect, useMemo, useRef} from 'react';

import {
	Col,
	Popover,
	PopoverBody,
	PopoverHeader, Row,
} from "reactstrap";

import { useOvermind } from "../../../../overmind";
import { customStyles } from "./styles"

//import DataTableExtensions from "react-data-table-component-extensions";
import "react-data-table-component-extensions/dist/index.css";
import Field from "../../CustomForm/Field";

export const CustomHeader = (
	{
		fieldProps = {},
		onComplete = () => {},
		...props
	}
) => {
	const {formId} = props

	//const app = useOvermind('app')
	const {
		state   : {
			[formId] : {Form, model}
		},
		actions : {
			getModelValueByPath, setModelValueByPath,
		}
	} = useOvermind('forms')

	const [isMouseOver, setIsMouseOver] = useState(false)
	const [iconTextColor, setIconTextColor] = useState('text-black')
	const [isOpen, setIsOpen] = useState(false)
	const [hasValue, setHasValue] = useState(false)

	const toggleFilter = () => {
		setIsOpen(!isOpen)
	}

	const hideFilters = () => {
		setIsOpen(false)
	}

	const id = props?.id?.toString()
	const fieldValue = model[id]

	const handleFilter = () => {
		//alert(fieldValue)
		setIsOpen(false)
		setIconTextColor(hasValue ? 'text-warning' : 'text-black')
		onComplete && onComplete()
	}

	const clearFilters = async () => {
		await setModelValueByPath({formId, path:props.id, value:null})
		setIsOpen(false)
		setIconTextColor('text-black')
		onComplete && onComplete()
	}


	const handleKeyDown = (e) => {
		if (e.keyCode===13) {
			e.preventDefault()
			return false
		}
	}


	const field = {
		id:id,
		caption:props.children,
		type:'text',
		row:true,
		col:12,
		...fieldProps
	}

	const setFieldFoduc = () => {
		setTimeout(() => {
			try {
				if (fieldProps.type=='select2' || fieldProps.type=='select2multi' ) {
					document.querySelector(`.custom-header-filter .select2-selection__input INPUT[type="text"]`).focus()
				} else {
					document.querySelector(`.custom-header-filter INPUT[name="${field.id}"]`).focus()
				}
			} catch(e) {
				//alert(e)
			}
		}, 200)
	}

	useEffect(() => isOpen && setFieldFoduc(), [isOpen])

	useEffect(() => {

		if (typeof(fieldValue)=='object' ) {
			setHasValue(fieldValue?.length>0)
		} else {
			setHasValue(
				fieldValue
				&& fieldValue !== '__/__/____'
				&& fieldValue !== 'R$ 0'
				&& fieldValue !== 'R$ 0,00'
			)

		}

	}, [fieldValue])


	useEffect(() => {
        document.body.addEventListener('click', hideFilters );

        return function cleanup() {
            window.removeEventListener('click', hideFilters );
        }
    },[]);

	//Clear filters each time the page loads
	useEffect(() => {
		setModelValueByPath({formId, path:props.id, value:null})
	},[]);

	const ref = useRef(null)

	return <>
		<div ref={ref} className={`d-inline-flex bg-white ${hasValue ? 'text-warning' : iconTextColor} flex-nowrap custom-header-filter`}
			 onClick={e => {
				 e.stopPropagation()
			 }}
			onMouseEnter={() => setIsMouseOver(true)}
			onMouseLeave={() => setIsMouseOver(false)}
		>
			<Popover
				placement="top"
				isOpen={isOpen}
				target={id}
				container={ref.current}
				toggle={toggleFilter}
				style={{minWidth:'250px', zIndex:9999}}
				innerClassName="border border-light rounded-2 w-100 datatable-filter-container"
			>
				<div>
					<div className="bg-fiduc-blue text-white rounded-top p-1">Filtrar</div>
				</div>
				<PopoverBody
					className="bg-white"
				>
                    <Field
                        formId={formId}
                        screenId={0}
                        screenIndex={0}
                        {...field}
						// OnInputValueChange={OnInputValueChange}
						tabId={'default'}
						onKeyDown={(e) => handleKeyDown(e)}
                    />
					<Row className="p-0 m-0 xborder border-dark ">
						<Col className="Xborder border-dark d-flex justify-content-start p-0">
							<button className="btn btn-sm btn-outline-danger  " type="button" onClick={clearFilters}>
								Limpar
							</button>
						</Col>
						<Col className="Xborder border-dark d-flex justify-content-end p-0">
							<button className="btn btn-sm btn-primary" type="submit" onClick={handleFilter}>
							  <i className="mdi mdi-magnify" />
								{" "} Filtrar
							</button>
						</Col>
					</Row>
				</PopoverBody>
			</Popover>
			<div
				id={id}
				className="custom-header-filter-icon"
				// style={{
				// 	// visibility:isMouseOver ? 'visible' : 'hidden'
				// }}
				onClick={() => document.body.click()}
				onMouseEnter={() => setIconTextColor('text-secondary')}
				onMouseLeave={() => setIconTextColor(hasValue ? 'text-warning' : 'text-black')}
			>
				<i className={`fas fa-search ${hasValue ? 'text-warning' : iconTextColor}`} />
			</div>
		</div>
		<div className={`d-inline-flex ${hasValue ? 'text-warning' : 'text-black'}`}
			style={{
				paddingRight:'0px',
				display:'inline-block'
			}}
		>{props.children}</div>
	</>
}

export default CustomHeader;
