import React from 'react';

export const customStyles = {
	table : {
		style : {
			height:'calc(100vh - 280px)',
			width:'100%',
			overflowX:'hidden',
			flex:0,
		}
	},

	rows: {
		style: {
			cursor:'pointer',
			paddingTop:'0.0em',
			paddingBottom:'0.0em',
			minHeight: '3.7em', // override the row height
			'&:not(:last-of-type)': {
				borderBottomStyle: 'solid',
				borderBottomWidth: '1px',
				borderBottomColor: '#eff2f7',
				backgroundColor:'var(--bs-table-bg)',
				boxShadow:'inset 0 0 0 9999px var(--bs-table-accent-bg)',
			},
		},
	},

	pagination: {
		style: {
			borderTopColor:'var(--bs-fiduc-bg)',
		},
	},

	headRow: {
		style: {
			borderBottomColor: 'var(--bs-fiduc-bg)',
		}
	},
	headCells: {
		style: {
			fontWeight:'bold',
			paddingLeft: '2px', // override the cell padding for head cells
			paddingRight: '2px',
		},
	},
	cells: {
		style: {
			//padding : '2em 1em 1em 1em'
			// paddingLeft: '2px', // override the cell padding for data cells
			// paddingRight: '2px',
			//
			// paddingTop: '0.6em',
			// paddingBottom: '0.6em',
			// paddingTop:'0px',
		},
	},
};


