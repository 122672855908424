import React, {useEffect, useMemo, useState} from "react"

import {
	Col,
	Row,
	Card, CardBody, CardTitle, CardText, TabPane, NavLink, NavItem, TabContent, Nav,
	Collapse,
} from "reactstrap"

//import { registrationFormScreens } from "./Configuration"

import {FRONT_END_SOURCE_KEY} from "../../../../helpers/gestora/configuration"
import Button from "../../../../components/Common/Button/Button";

import { useOvermind } from "../../../../overmind";

import lStorage from "../../../../helpers/gestora/lStorage";

import classnames from "classnames"
import AccordionDefault from "../../../../components/Common/Accordion/AccordionDefault";
import Field from "../../../../components/Common/CustomForm/Field";
import KYCBasicData from "./Sections/KYCBasicData";
import LoadingSection from "../../../../components/Common/LoadingSection/LoadingSection";
import api, {sleep} from "../../../../helpers/gestora/api";
import {dateIsoToBr, onlyNumbers} from "../../../../renders/utils";
import KYCCompliance from "./Sections/KYCCompliance";
import KYCEconomicRelation from "./Sections/KYCEconomicRelation";
import KYCPersonalRelation from "./Sections/KYCPersonalRelation";
import KYCLawsuits from "./Sections/KYCLawsuits";
import KYCProfessionalData from "./Sections/KYCProfessionalData";
import {UserCan} from "../../../../helpers/gestora/auth";
import UserCanAll from "../../../../components/Common/UserCan/UserCanAll";


function KYC(props) {

	const {formId} = props

	const clients  = useOvermind('clients')
	const {
		state   : {
			[formId]:{model}
		},
		actions : {

		}
	} = useOvermind('forms')

	const [KYCData, setKYCData] = useState({})
	const [loading, setLoading] = useState(true)

	const sections = useMemo(() => [
		{
			id:'basicData',
			caption:'Dados básicos',
			content:<KYCBasicData KYCData={KYCData}/>
		},
		{
			id:'KYC-Compliance',
			caption:'KYC e Compliance',
			content:<KYCCompliance KYCData={KYCData} />
		},
		{
			id:'economicRelations',
			caption:'Relacionamentos Econômicos',
			content:<KYCEconomicRelation KYCData={KYCData} />
		},
		{
			id:'personalRelation',
			caption:'Relacionamentos Pessoais',
			content:<KYCPersonalRelation KYCData={KYCData} />
		},
		{
			id:'lawsuits',
			caption:'Ações Judiciais',
			content:<KYCLawsuits KYCData={KYCData} />
		},
		{
			id:'professionalData',
			caption:'Dados profissionais',
			content:<KYCProfessionalData KYCData={KYCData} />
		},
	], [KYCData])

	const handleSearch = (newSearch=false) => {
		setLoading(true)
		api
			.get(`/kyc/${model?.client.id}/${newSearch ? 'true' : 'false'}`, {params:{frontendSource:FRONT_END_SOURCE_KEY}})
			//.then(sleep(2000))
			.then(res => {
				console.log('KYC RESPONSE', res.data)
				clients.actions.setScoreKYC(res.data.backgroundCheck?.Score || 0)
				setKYCData(res.data.result)
			})
			.catch(e => {
				setKYCData(null)
				//alert('Não foi possível carregar as informações de status')
				//alert(e)
			})
			.finally(() => {
				setLoading(false)
			})
	}

	useEffect(() => {
		handleSearch()
	}, [])

	if (loading)
		return <LoadingSection loading={true} />

	const lastKycSearch = KYCData && KYCData[0] && KYCData[0]?.lastKycSearch;

	return (
		<UserCanAll permission={"clients.consult"}>
					<Row className={'mb-2 mt-0 '}>

					{KYCData ? <>
						<Col md={6} className="text-left d-flex justify-content-center flex-column  ">
							Consulta realizada em {dateIsoToBr(lastKycSearch, null, 'DD/MM/YYYY [às] HH:mm:ss')}
						</Col>

						<Col md={6} className="d-flex text-align-right justify-content-start flex-column flex-fill">
							<div className="d-flex flex-row flex-column ">
								<div className="flex-grow-1  ">
									<div className="text-danger font-size-em1">
										Score: <span className="text-black">{clients.state.scoreKYC}</span>
									</div>
								</div>
								<div className="d-flex flex-grow-0 justify-content-end align-items-end text-align-right p-0">
									<UserCanAll permission={"clients.edit"}>
									<Button
										loading={loading}
										className={'btn btn-outline-danger p-2 btn-sm'}
										onClick={() => handleSearch(true)}
									>
										Refazer a consulta
									</Button>
									</UserCanAll>
								</div>
							</div>
						</Col>

					</> : <>
						<Col md={12} className={"text-center p-4 "}>
							Nenhuma consulta realizada
						</Col>

						<Col md={12} className="d-flex justify-content-center">
							<UserCanAll permission={"clients.edit"}>
							<Button
								loading={loading}
								className={'btn btn-danger p-2'}
								onClick={() => handleSearch(true)}
							>
								Iniciar consulta KYC
							</Button>
							</UserCanAll>
						</Col>
					</>}

					</Row>
					{KYCData &&
					<Row>
						<Col md={12}>
							<AccordionDefault
								id={'test'}
								options={sections}
								activeOption={'one'}
							/>
						</Col>
					</Row>
					}

		</UserCanAll>
	)
}

export default KYC;
