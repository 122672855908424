import React, {useState} from "react"

//import InputMask from "react-input-mask";
import CurrencyInput from '../SubComponents/CurrencyInput'
import { AvField } from "availity-reactstrap-validation"
import { Input } from 'reactstrap';
// import { parseMoney } from '../../../../helpers/parser_helper'
// import {isValidMoney} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";


function FieldMoney(props) {

    const [value, setValue] = useState('')
    const handleChange = (e) => {
    	setValue(e.target.value)
		//console.log(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }

    // const {caption, type, inputRef, formId, screenId, validation, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            tag={[Input, CurrencyInput]}
            {...rest}
            placeHolder={''}
            maskOptions={{...props.maskOptions, allowNegative:props.allowNegative || false}}
            autoComplete="disabled"
            type="text"
            onChange={handleChange}
			required={props.required}
            // validate={{val:() => isValidMoney(value, props) }}
            validate={props.validation}
        />
    )
}

export default FieldMoney;
