import React, {useEffect, useState} from "react"

import { AvField } from "availity-reactstrap-validation"
// import { useOvermind } from "../../../../overmind";

import { loadOptions } from "../../../../constants/remoteLists";
import {isValidValue} from "../Utils/Validations";
import {getValidDomProperties} from "../Utils/DomProp";
import {useOvermind} from "../../../../overmind";

function FieldSelect(props) {

	const {listId = '', formId} = props
	const [options, setOptions] = useState(props.options)
	const [loading, setLoading] = useState(listId ? true : false)
    const [value, setValue] = useState('')

    const {
        state   : {
            [formId]:{}
        },
        actions : {
            getModelValueByPath
        }
    } = useOvermind('forms')

	useEffect(() => {
		if (listId) {
			loadOptions(listId)
				.then((res) => {
					//console.log("OPTIONS", res)
					//const value=getModelValueByPath({formId, path:props.name})
					//alert(value)
					setOptions(res)
				})
				.finally(() => {
					setLoading(false)
				})
		}
	}, []);

    const handleChange = (e) => {
        setValue(e.target.value)
        if (props.onChange)
            props.onChange(props.name, e.target.value)
    }

    // const {caption, type, inputRef, formId, screenId, validation, noEmptyOption, ...rest} = props
	const rest = getValidDomProperties(props)
    return (
        <AvField
            ref={props.inputRef || undefined}
            className="form-control"
            type="select"
            {...rest}
            errorMessage={props.errorMessage || `${props.caption} é obrigatório`}
            autoComplete="disabled"
            onChange={handleChange}
			required={props.required}
            // validate={{val:() => isValidValue(value, props) }}
            validate={props.validation}
			value={getModelValueByPath({formId, path:props.name})}
        >
			{loading ? (
				<option value=''>(loading)</option>
			) : (
				<>
				{!props.noEmptyOption &&
					<option value=''>...</option>
				}

				{options && options.map((option) => {
					//const optionsId = `cki-${props.name}-${option.id}`
					return (
						<option
							key={option.id}
							value={option.id}
						>
							{option.caption}
						</option>
					)
				})}
				</>
			)}

        </AvField>
    )
}

export default FieldSelect;
