import React, { useState } from "react"

import {
    TabPane, NavLink, NavItem, TabContent, Nav
} from "reactstrap"

// import { useOvermind } from '../../../overmind'

import classnames from "classnames"

function TabDefault({activeTab, ...props}) {

    const { tabs, hasContent = false, TabActions=null } = props

    // const [active, setActive] = useState(activeTab)

    const toggle = (tab, tabIndex) => {
        // setActive(tab)
        if (props.onChange)
            props.onChange(tab, tabIndex)
    }

    //const screen = screenList[currentScreen]
	// alert(activeTab)


    return (
        <>
			<div className="d-flex flex-row tab-default " >
				<div className="flex-fill">
					<Nav tabs >
						{tabs && tabs.map((tab, tabIndex) => {
							const invalidTab = (props.getInvalidTab && props.getInvalidTab(tab.id))
							if (tab.available && !tab.available())
								return null

							return (
								<NavItem key={tab.id} className="nav-item-default">
									<NavLink
										style={{ cursor: "pointer", position:'relative', paddingLeft:invalidTab ? '1.5em' : undefined }}
										className={classnames({
											active: activeTab === tab.id,
										})}
										onClick={() => {
											toggle(tab.id, tabIndex)
										}}
									>
										{tab.caption || ''}
										{invalidTab && (
											<div className="position-absolute left-0 translate-middle badge border border-light rounded-circle bg-danger tab-default-badge no-pulsate">
												<span className="visually-hidden">unread messages</span>
											</div>
										)}
									</NavLink>
								</NavItem>
							)
						})}
					</Nav>
				</div>
				{!TabActions ? null :
					<div className="debug3 d-flex flex-row pe-em2 nav-tabs tab-default-actions-container">
						{TabActions}
					</div>
				}
			</div>
            <TabContent activeTab={activeTab} className={`p-3 ${hasContent ? '' : 'd-none'}`} >
                {tabs && tabs.map((tab, tabIndex) => {
                    return (
                        <TabPane tabId={tab.id} key={tab.id}>
                            {tab.content
                                ? <tab.content />
                                : null
                            }
                        </TabPane>
                    )
                })}
            </TabContent>
        </>
    )
}

export default TabDefault;
