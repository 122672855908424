import { app } from './app/state';
import { forms } from './forms/state';
import { login } from './login/state';
import { lists } from './lists/state';
import { clients } from './clients/state';
import { investments } from './investments/state';
import { fundTypes } from './fundTypes/state';
import { banks } from './banks/state';
import { riskProfiles } from './riskProfiles/state';
import { suitability } from './suitability/state';
import { renew } from './renew/state';
import { users } from './users/state';
import { profiles } from './profiles/state';
import { mailsig } from './mailsig/state';
import { security } from './security/state';

export const state = {
    app,
    forms,
    login,
    lists,
    clients,
	investments,
	fundTypes,
	banks,
	riskProfiles,
	suitability,
	renew,
	users,
	profiles,
	mailsig,
	security,
};
