const labelBs = 3

export const customStyles = {

	control: (provided, state) => ({
		...provided,
		borderWidth:0,
		boxShadow: 'none',
		padding:'0px',
		margin:'0px',
		minHeight:1,
		outline:'0px',
		backgroundColor:'transparent !important'

	}),

	container: (provided, state) => {
		return {
			...provided,
			margin:'0px',
			padding:'0px',
			//border:state.selectProps.fieldError ? 'solid 2px var(--bs-orange)' : 'solid 2px green' ,
			border:'0px',
			minHeight:'2em',


		};
	},

	menu: (provided, state) => {
		return {
			...provided,
			zIndex:2,
			borderRadius:'4px',
			padding:'0px 0px 0px 0px',
			border:'solid 1px #eff2f7',

		};
	},

	menuPortal: base => ({
		...base,
		zIndex: 9999,
		// left:0,
		// top:0,
		// minWidth:'100%',
	}),

	menuList: (provided, state) => {
		return {
			...provided,
			padding:'6px 6px 4px 6px',
		};
	},

	option: (provided, state) => {
		//console.log('option', {state, provided})
		return {
			...provided,
			margin:'0px 0px 2px 0px',
			borderRadius:'4px',
			backgroundColor:state.isSelected ? 'var(--bs-blue)' : null,
			color:state.isSelected ? 'white' : 'var(--bs-black)',

			//outline : state.isFocused ? 'solid 1px var(--bs-orange)' : false,
			border:state.isFocused ?
					(state.isSelected ? 'solid 1px white' : 'solid 1px var(--bs-danger)')
				: 'solid 1px transparent',
			":hover" : {
				border:(state.isSelected&&state.isFocused) ? 'solid 1px white' : 'solid 1px transparent',
				backgroundColor:state.isSelected ? false : '#f7d8c4',
				color:state.isSelected ? false : 'black',
				//border:'solid 1px #f7d8c4',
			},
			":active" : {
				//border:'solid 4px white' : 'solid 2px blue',
				backgroundColor:'#38879D',
				//border:'solid 1px red',
				color:'white',
				//border:'solid 1px #f7d8c4',
			},
		};
	},

	input: (provided, state) => {
		return {
			...provided,
			margin:'0px',
			// border:'solid 1px red',
			//color:"red !important",
			height:null,
			marginTop: "0em",
			paddingTop:'0px',
			padding:"0px 0px 0px 0px",
			marginLeft: "0px",
			paddingLeft:'0px',
			border:"solid 0px green",

		};
	},

	indicatorsContainer: (provided, state) => {
		return {
			...provided,
			margin:"0px 0em 0px 0px",
			padding:"0px 0em 0px 0px",
			minHeight:'2em',
			// border:'solid 1px purple',
		};
	},

	dropdownIndicator: (provided, state) => {
		return {
			...provided,
			padding:"0px",
			margin:"0px",
			//fill:state.selectProps.fieldError ? 'var(--bs-orange) !important' : 'var(--bs-gray) !important' ,
			// border:'solid 1px red',
			visibility:state.selectProps.isDisabled ? 'hidden' : 'unset'
		};
	},

	indicatorSeparator: (provided, state) => {
		return {
			...provided,
			padding:'0px 0px 0px 0px',
			margin:'0px 0.8em 0px 0.8em',
			backgroundColor:state.selectProps.fieldError ? '#f7d8c4 !important' : '#f1efe9 !important' ,
			//opacity:state.selectProps.fieldError ? '0.5' : false ,
			display:state.selectProps.fieldError ? 'none' : false ,
			//borderColor:'red',
			//border:'0px !important	',
		};
	},

	clearIndicator: (provided, state) => {
		return {
			...provided,
			display:'none',
		};
	},

	valueContainer: (provided, state) => {
		return {
			...provided,
			height:'100%',
			border:'solid 0px red',
			padding:'0px',
			margin:'0px',
			minWidth:1,
			overflow:'unset',

		};
	},

	singleValue: (provided, state) => {
		const opacity = state.isDisabled ? 0.6180 : 1;
		const transition = 'opacity 300ms';
		//console.log(provided)
		return {
			...provided,
			// opacity,
			transition,

			width:'100%',
			color:'#000000',
		};
	},

	placeholder: (provided, state) => {
		return {
			...provided,
			marginLeft:'0px',
			marginRight:'0px',

			padding:'0.47rem 0.25rem',

			//color:'purple',
			width:'100%',
		};
	},

	multiValue: (provided, state) => {
		return {
			...provided,
			// color:'purple',
			backgroundColor:'transparent',

			// borderRadius:'0px 8px 8px 0px ',
			borderRadius:`0px ${labelBs}px ${labelBs}px 0px`,
			//marginTop:'0.25em',
			":hover" : {
				//border:'solid 1px #f7d8c4',
			},
		};
	},

	multiValueLabel: (provided, state) => {
		return {
			...provided,
			padding:'2px 2px 2px 2px',
			margin:'0px 0px 0px 0px',
			color:false,
			borderRadius:`${labelBs}px 0px 0px ${labelBs}px`,
			backgroundColor:'#f1efe9',
		};
	},

	multiValueRemove: (provided, state) => {
		// console.log('state', {state, provided})
		return {
			...provided,

			":hover" : {
				color:'var(--bs-black)',
				//border:'solid 1px white',
				//borderColor:'blue !important',
				//opacity:'0.6180',
				backgroundColor:'#f7d8c4',
			},

			//color: 'var(--bs-white)',
			//opacity:'0.6180',
			backgroundColor:'#f1efe9',
			borderRadius:`0px ${labelBs}px ${labelBs}px 0px`,
			borderColor:'blue',
			...(state.data?.canRemove===false
				? {
					backgroundColor:'#f1efe9',
					pointerEvents : 'none',
					width: '1px',
				}
				: {}),

		};
	},

}
