import React, {useEffect, useMemo, useState} from "react"
import moment from "moment"
moment.locale('pt-BR')

import SweetAlert from "react-bootstrap-sweetalert";
import {hideActiveModal} from "../../CustomForm/Utils/DomProp";


function CustomModal({
	children,
	title='', //Window title, empty to remove title node
	subtitle='',
	titleStyle="debug5 font-family-title font-size-rem3 font-weight-800 px-5",
	className='',
	isVisible=false,
	modalStyle = {},
	contentBottom = null,
	closeOnClickOutside = true,
	onConfirm = () => {},
	onClose = () => {},
	modalData=null,
	dependencies=[],
	...props
}) {

	const [display, setDisplay] = useState('flex')

	const hide = () => {
		if (!hideActiveModal()) {
			onClose && onClose()
		}
	}

	const handleAfterUpdate = (res, res2) => {
		if (res2.closingAction) {
			setTimeout(() => {
				try {
					const element = document.querySelector(`#root>div>div[tabindex="0"]`)
					// element.remove()
					// element.style.visibility = 'hidden'
					// element.style.zIndex = 0
					// alert(element)
				} catch(e) {}
			}, 200)
		}
	}

	const handleCancel = () => {
		onClose && onClose()
    	 // setTimeout(() => onCancel(), 100)
	}

    return (
        <>
			{isVisible &&
			<SweetAlert
				customClass={className}
				// warning={!error && !saving}
				afterUpdate={handleAfterUpdate}
				beforeMount={() => setDisplay('flex')}
				dependencies={dependencies}
				closeOnClickOutside={closeOnClickOutside}

				style={{
					display:display,
					overflow:'hidden',
					// marginTop:'0px',
					// width:'80%',
					maxHeight:'100%',
					justifyContent:undefined,
					borderRadius:undefined,
					padding:undefined,

					opacity:'initial',
					// minHeight:'300px',
					...modalStyle
				}}
				// openAnim={{ name: 'slideUp', duration: 500 }}
				// closeAnim={{ name: 'slideDown', duration: 300 }}


				success={false}
				error={false}
				showCancel={false}
				showConfirm={false}
				allowEscape={true}
				onCancel={handleCancel}
				title={title}

			>
				{children}
			</SweetAlert>
			}
        </>
    )
}

export default CustomModal;
