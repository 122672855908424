import {fiducUrl} from "../../../helpers/gestora/configuration";


const templatePath = `${fiducUrl}/mailsig/template`
const generatedPath = `${fiducUrl}/mailsig/generated`

export const getHtmlTemplate = (email) => {
	return `
    <!-- ASSINATURA + RODAP� -->
    <table cellpadding="0" cellspacing="0" border="0">
        <tr><td colspan="2"><img src="${generatedPath}/${email}?rnd=${Math.random()}"/></td></tr>
        <tr>
            <td><img  src="${templatePath}/signature2middle.jpg" border="0"/></td>
            <td>
                <table cellpadding="0" cellspacing="0" border="0">
                    <TR>
                        <TD>
                            <A href="https://apps.apple.com/br/app/fiduc-gest%C3%A3o/id1541472022" target="_blank" title="Apple Store">
                            <img src="${templatePath}/signature2apple.jpg" border="0"/>
                            </A>
                        </TD>
                        <TD>
                            <A href="https://play.google.com/store/apps/details?id=br.com.fiduc.gestora" target="_blank" title="Google Play">
                            <img src="${templatePath}/signature2google.jpg" border="0"/>
                            </A>
                        </TD>
                        <TD>
                            <img src="${templatePath}/signature2rest.jpg" border="0"/>
                        </TD>
                    </TR>
                </table>
            </td>
        </tr>
        <tr><td colspan="2"><img src="${templatePath}/signature2bottom.jpg" border="0"/></td></tr>
    </table>
    <!-- ASSINATURA + RODAP� -->
`.trim()
}
export const getFullHtmlTemplate = (email) => {
	return `
<!DOCTYPE html>
<html>
<head>
<style>
body {
  font-family: font-family: "HelveticaNeue-Light", "Helvetica Neue Light", "Helvetica Neue", Helvetica, Arial, "Lucida Grande", sans-serif; 
  font-weight: 300;
}
</style>
</head>
<body>
	${getHtmlTemplate(email)}
</body>
</html>
`.trim()

}
